import TableCellInput from 'components/OrderCreatePageComponents/TableCellInput';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { usePriceColor } from 'pages/Order/hooks/usePriceColor';
import { getPriceFormDeviation, normalizeManualDeviationInput } from 'pages/Order/lib/shared';
import { ProductInternalModelState } from 'pages/Order/OrderController';
import React from 'react';
import { Controller } from 'react-hook-form';
import { formatInputValue, padEndZero, replaceNegativeComaWithDot } from 'utils/inputs';
import { roundNumber, toFixed } from 'utils/shared';

interface OrderProductDeviationInputProps {
	suborderIndex: number;
	product: ProductInternalModelState;
	disabled?: boolean;
}

const OrderProductDeviationInput: React.FC<OrderProductDeviationInputProps> = ({ suborderIndex, product, disabled }) => {
	const entityId = product.id;
	const { precision } = product.unit || {};

	const { getDeviationColor } = usePriceColor(product);
	const { control, setDirtyValue, getValues } = useOrderOperationMethods();
	const deviationFieldName = `suborders.${suborderIndex}.data.products.${entityId}.deviation` as const;

	const updateValues = (deviation: string) => {
		const allSuborders = getValues('suborders');
		const subordersProducts = allSuborders.slice(1).map((suborder) => {
			const candidate = suborder.data.products[entityId];

			if (!candidate) return suborder.data.products;

			const amount = Number(candidate?.amount || 0);
			const priceWithDeviation = getPriceFormDeviation(deviation, product);
			const newPrice = Number(priceWithDeviation);
			const sum = toFixed(roundNumber(newPrice * amount, 2));

			return {
				...suborder.data.products,
				[entityId]: {
					...candidate,
					price: toFixed(newPrice, { precise: 2 }),
					sum,
					amount: toFixed(amount, { precise: precision }),
					deviation,
				},
			};
		});

		const source = allSuborders.length > 1 ? subordersProducts : [allSuborders[0].data.products];
		const sumInAllEntityOccurrences = source.reduce((acc, products) => {
			const total = Object.values(products).reduce((subtotal, productModel) => {
				if (productModel.id !== entityId) {
					return acc;
				}
				return (subtotal += Number(productModel.price) * Number(productModel.amount));
			}, 0);

			acc += total;
			return acc;
		}, 0);

		const rootProducts = {
			...allSuborders[0].data.products,
			[entityId]: {
				...allSuborders[0].data.products[entityId],
				sum: toFixed(sumInAllEntityOccurrences, { precise: 2 }),
				deviation: toFixed(deviation, { precise: 2 }),
				price: getPriceFormDeviation(deviation, product),
			},
		};

		subordersProducts.forEach((products, index) => {
			setDirtyValue(`suborders.${index + 1}.data.products`, products);
		});

		setDirtyValue(`suborders.${0}.data.products`, rootProducts);
	};

	const formatValue = formatInputValue({ isInt: false });

	return (
		<Controller
			name={deviationFieldName}
			control={control}
			render={({ field }) => {
				return (
					<TableCellInput
						type="tel"
						value={field.value}
						disabled={disabled}
						readOnly={disabled}
						onKeyDown={replaceNegativeComaWithDot}
						style={{ color: getDeviationColor(field.value) }}
						onChange={(e) => {
							const input = e.currentTarget.value;
							field.onChange(input);
						}}
						onFocus={(e) => {
							e.target.select();
						}}
						onBlur={() => {
							const numberedValue = Number(formatValue(field.value));

							if (!numberedValue) {
								field.onChange(padEndZero(0, precision));
								return;
							}

							updateValues(String(normalizeManualDeviationInput(String(parseFloat(field.value)))));
						}}
					/>
				);
			}}
		/>
	);
};

export default OrderProductDeviationInput;
