import { checkAppVersion } from 'app-version';
import MediumPlusSizeDevicesOnly from 'components/MediumPlusSizeDevicesOnly';
import NetworkConnection from 'components/NetworkConnection';
import { breakPoints } from 'const';
import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import ToasterProvider from 'contexts/ToasterProvider';
import { useServiceWorker } from 'hooks/useServiceWorker';
import PageLayoutSkeleton from 'layouts/PageLayout/PageLayoutSkeleton';
import React, { Suspense, useLayoutEffect, useRef } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';

const MQ_MOBILE = `${breakPoints.MOBILE - 1}px`;

checkAppVersion();

const App: React.FC = () => {
	useServiceWorker();

	const isMobile = useMediaQuery({ maxWidth: MQ_MOBILE });
	const containerRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (!containerRef.current) return;

		if (isMobile) {
			containerRef.current.style.display = 'none';
			return;
		}

		if (containerRef.current.style.display === 'none') {
			containerRef.current.style.display = 'block';
		}
	}, [isMobile]);

	return (
		<>
			<div ref={containerRef}>
				<Suspense fallback={<PageLayoutSkeleton />}>
					<OrderAlertDialoguesProvider>
						<ToasterProvider>
							<RouterProvider router={router} />

							<NetworkConnection />
						</ToasterProvider>
					</OrderAlertDialoguesProvider>
				</Suspense>
			</div>
			<MediaQuery maxWidth={MQ_MOBILE}>
				<MediumPlusSizeDevicesOnly />
			</MediaQuery>
		</>
	);
};

export default App;
