import clsx from 'clsx';
import SpinnerV2 from 'components/Spinner-v2';
import { useClickOutside } from 'hooks/useClickOutside';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReactComponent as CheckIcon } from 'static/images/check.svg';
import { ReactComponent as ChevronDownIcon } from 'static/images/chevron-down.svg';

import styles from './styles.module.css';
import type { IProps, UseOptionsQuery } from './types';

const AutocompleteServerInput = <TUseQuery extends UseOptionsQuery>(
	{ label, name, useLoadAsyncDataQuery, onSelect, initialValue, placeholder, className, disabled }: IProps<TUseQuery>,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	const [search, setSearch] = useState(initialValue?.label);
	const [query, setQuery] = useState(search);
	const { data, isLoading, isFetching } = useLoadAsyncDataQuery(query);
	const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
	const previousSearch = useRef(initialValue?.label);
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom', strategy: 'absolute' });

	const closeDropdown = useCallback(() => setIsDropdownVisible(false), []);
	const openDropdown = useCallback(() => setIsDropdownVisible(true), []);

	const containerRef = useClickOutside<HTMLDivElement>(closeDropdown);

	const onQueryChange = useCallback((value: string) => {
		setQuery(value);
	}, []);
	const onChangeDebounced = useDebouncedCallback(onQueryChange, 500);

	const handleOptionSelect = (option: AnyArg) => {
		onSelect(option);
		closeDropdown();
	};

	const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		if (previousSearch.current !== value) {
			onSelect(null);
		}

		setSearch(value);
		onChangeDebounced(value);

		previousSearch.current = value;
	}, []);

	useEffect(() => {
		if (initialValue) {
			setSearch(initialValue.label);
			previousSearch.current = initialValue.label;
		}
	}, [initialValue]);

	const canShowSpinner = isLoading || isFetching;
	const canRenderClearButton = search;
	const canShowEmptyState = !canShowSpinner && data && data.length === 0;

	useEffect(() => {
		if (referenceElement) {
			const portal = document.querySelector('#autocomplete-root') as HTMLDivElement;
			portal.style.setProperty('--parent-width', `${referenceElement.getBoundingClientRect().width}px`);

			return () => {
				portal.style.removeProperty('--parent-width');
			};
		}
	}, [referenceElement]);

	const cbRef = useCallback((el: HTMLDivElement) => {
		setReferenceElement(el);
		containerRef.current = el;
	}, []);

	return (
		<div ref={cbRef} className={clsx(styles.wrapper, className)}>
			{!!label && (
				<label className={styles.label} htmlFor={name}>
					{label}
				</label>
			)}

			<input
				ref={ref}
				name={name}
				id={name}
				className={clsx(styles.input, 'no-user-agent-autofill-bg')}
				type="text"
				value={search}
				placeholder={placeholder}
				onChange={onChange}
				onClick={(e) => {
					e.stopPropagation();
					openDropdown();
				}}
				onFocus={openDropdown}
				autoComplete="off"
				disabled={disabled}
			/>
			{isDropdownVisible && (
				<>
					{createPortal(
						<ul ref={setPopperElement} className={clsx(styles.dropdown)} style={popperStyles.popper} {...attributes.popper}>
							{!canShowSpinner &&
								data.map((option, index) => {
									// const matchesIndex = index === activeSuggestion;

									const matchesSuggestion = search === option?.label;

									return (
										<li key={index}>
											<button
												type="button"
												onClick={() => handleOptionSelect(option)}
												className={clsx(styles.option, 'color-primary-900', {
													[styles.active]: matchesSuggestion,
												})}
											>
												<span>{option?.label}</span>
												{matchesSuggestion && <CheckIcon className={styles.checkIcon} />}
											</button>
										</li>
									);
								})}

							{canShowSpinner && (
								<li className={styles.spinner}>
									<SpinnerV2 />
								</li>
							)}

							{canShowEmptyState && <li className={styles.empty}>Нічого не вдалося знайти...</li>}
						</ul>,
						document.querySelector('#autocomplete-root'),
					)}
				</>
			)}

			{canRenderClearButton && (
				<button
					type="button"
					className={styles.button}
					disabled={disabled}
					onClick={() => {
						setSearch('');
						setQuery('');
						onSelect(null);
					}}
				/>
			)}
			{!canRenderClearButton && <ChevronDownIcon className={styles.chevron} />}
		</div>
	);
};

export default forwardRef(AutocompleteServerInput);
