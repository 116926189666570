import type { ColumnDefinition, ColumnOrderModel } from 'components/Table/lib/table/types/table';
import { useEffect, useState } from 'react';
import { localStorageService } from 'services/localStorageService';

type UseReorderColumnsConfig = {
	saveConfigKey: string;
};

export const useReorderColumns = (columns: ColumnDefinition<AnyArg, unknown>, config?: UseReorderColumnsConfig) => {
	const key = 'column-order::' + config?.saveConfigKey;

	const [columnOrderModel, onColumnOrderChange] = useState<string[]>(hydrateState(key, columns));

	useEffect(() => {
		if (!config?.saveConfigKey) return;

		localStorageService.save(key, columnOrderModel);
	}, [columnOrderModel, key, config?.saveConfigKey]);

	return {
		columnOrderModel,
		onColumnOrderChange,
		columnOrderModelSaveConfigKey: key,
	};
};

function hydrateState(key: UseReorderColumnsConfig['saveConfigKey'], initial: ColumnDefinition<AnyArg>) {
	return function hydrate(): ColumnOrderModel {
		const columnOrderModel = localStorageService.load<ColumnOrderModel>(key);

		if (Array.isArray(columnOrderModel) && columnOrderModel.length > 0) {
			return columnOrderModel;
		}
		const fallbackModel = initial.map((column) => column.id!);

		return fallbackModel;
	};
}
