import clsx from 'clsx';
import OptionTag from 'components/AutocompleteInput/components/OptionTag';
import { useBoolean } from 'hooks/useBoolean';
import { useClickOutside } from 'hooks/useClickOutside';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import type { Option } from 'models/common/options';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReactComponent as ChevronDown } from 'static/images/chevron-down.svg';

import styles from './styles.module.css';

const MAX_VISIBLE_TAGS = 3;

interface SelectedFilterListProps {
	value: Option[];
	onChange: (v: string) => void;
	disabled?: boolean;
}

const SelectedFilterList: React.FC<SelectedFilterListProps> = ({ value, onChange, disabled = false }) => {
	const popupInterface = useBoolean();
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-end', strategy: 'absolute' });

	const popupInterfaceToggle = useStopPropagationCallback(popupInterface.toggle);
	const popupInterfaceClose = useStopPropagationCallback(popupInterface.close);

	const ref = useClickOutside<HTMLDivElement>(popupInterfaceClose);
	const needShrink = value.length > MAX_VISIBLE_TAGS;

	if (!needShrink) {
		return (
			<div className={clsx(styles.thumb)}>
				{value.map((v) => {
					return (
						<OptionTag
							key={v.value + v.label}
							disabled={disabled}
							title={v.label}
							onUnselect={() => onChange(v.value)}
							className={clsx(styles.optionTag, { [styles.disabled]: disabled })}
						/>
					);
				})}
			</div>
		);
	}

	const visibleTags = value.slice(0, MAX_VISIBLE_TAGS);
	const shrinkTags = value.slice(MAX_VISIBLE_TAGS);

	return (
		<div className={clsx(styles.thumb)}>
			{visibleTags.map((v) => {
				return (
					<OptionTag
						key={v.value + v.label}
						title={v.label}
						disabled={disabled}
						onUnselect={() => onChange(v.value)}
						className={clsx(styles.optionTag, { [styles.disabled]: disabled })}
					/>
				);
			})}
			<button ref={setReferenceElement} type="button" className={clsx(styles.shrinkButton)} onClick={popupInterfaceToggle}>
				<span>+{shrinkTags.length}</span>
				<ChevronDown />
			</button>

			{popupInterface.isOn && (
				<>
					{createPortal(
						<div ref={setPopperElement} style={{ ...popperStyles.popper, zIndex: 1000000 }} {...attributes.popper}>
							<div ref={ref} className={clsx(styles.shrinkList, 'dropdown')}>
								{shrinkTags.map((v) => {
									return (
										<OptionTag
											key={v.value + v.label}
											title={v.label}
											disabled={disabled}
											onUnselect={(e) => {
												e.stopPropagation();
												onChange(v.value);
											}}
											className={clsx(styles.optionTag, { [styles.disabled]: disabled })}
										/>
									);
								})}
							</div>
						</div>,
						document.querySelector('#portal'),
					)}
				</>
			)}
		</div>
	);
};

export default SelectedFilterList;
