import { combineReducers, configureStore, isAnyOf } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer } from 'redux-persist';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import { loginListenerMiddleware } from './config/middleware';
import { persistConfig } from './config/persistor.config';
import app, { setAppMode, setNetworkConnection } from './reducers/app';
import auth, { authActions } from './reducers/auth';
import { authSliceApi } from './reducers/auth/authSliceApi';
import { businessOfferSliceApi } from './reducers/businessOffer';
import { clientsSliceApi } from './reducers/clients/clientsSliceApi';
import { filesSliceApi } from './reducers/files/filesSliceApi';
import { filterTemplatesSliceApi } from './reducers/filterTempates/filterTemplatesSliceApi';
import notifications, { notificationsActions } from './reducers/notifications';
import order from './reducers/order';
import createOrder from './reducers/orders';
import { ordersSliceApi } from './reducers/orders/ordersSliceApi';
import orderWorkingMode from './reducers/orderWorkingMode';
import { paintToningSliceApi } from './reducers/paintToning/paintToningApiSlice';
import { postponedRequestsSliceApi } from './reducers/postponedRequests';
import { usersSliceApi } from './reducers/users/usersSliceApi';

const rootReducer = combineReducers({
	app,
	auth,
	order,
	createOrder,
	notifications,
	orderWorkingMode,
	[usersSliceApi.reducerPath]: usersSliceApi.reducer,
	[paintToningSliceApi.reducerPath]: paintToningSliceApi.reducer,
	[clientsSliceApi.reducerPath]: clientsSliceApi.reducer,
	[ordersSliceApi.reducerPath]: ordersSliceApi.reducer,
	[filterTemplatesSliceApi.reducerPath]: filterTemplatesSliceApi.reducer,
	[businessOfferSliceApi.reducerPath]: businessOfferSliceApi.reducer,
	[authSliceApi.reducerPath]: authSliceApi.reducer,
	[filesSliceApi.reducerPath]: filesSliceApi.reducer,
	[postponedRequestsSliceApi.reducerPath]: postponedRequestsSliceApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () => {
	return configureStore({
		reducer: persistedReducer,
		middleware: (getDefaultMiddleware) => {
			const defaultMiddleware = getDefaultMiddleware({
				serializableCheck: false,
			}).concat(
				createStateSyncMiddleware({
					// we sync only mode change and authentication actions
					predicate: isAnyOf(
						setAppMode,
						setNetworkConnection,
						notificationsActions.setVersionRelease,
						authActions.setLoggedInUser,
						authActions.clearLoggedInUser,
					),
				}),
				authSliceApi.middleware,
				loginListenerMiddleware.middleware,

				ordersSliceApi.middleware,
				clientsSliceApi.middleware,
				usersSliceApi.middleware,
				filterTemplatesSliceApi.middleware,
				paintToningSliceApi.middleware,
				businessOfferSliceApi.middleware,
				filesSliceApi.middleware,
				postponedRequestsSliceApi.middleware,
			);

			return defaultMiddleware;
		},
	});
};

export const store = setupStore();

setupListeners(store.dispatch);
initMessageListener(store);

export type RootState = ReturnType<typeof rootReducer>;
type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
