import clsx from 'clsx';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import TopBarContainer from 'components/OrderCreatePageComponents/TopBarContainer';
import Payable from 'components/OrderPageComponents/Payable';
import { breakPoints } from 'const';
import { useOrderStats } from 'pages/Order/hooks/useOrderStats';
import { Can } from 'pages/Order/OrderAbility/provider';
import { useTypedOrderControllerFromContext } from 'pages/Order/OrderController';
import OrderInternalDataControlModule from 'pages/Order/OrderInternalDataControlModule';
import { STACK_ELEMENTS } from 'pages/Order/utils';
import React, { lazy } from 'react';
import { Controller } from 'react-hook-form';
import MediaQuery from 'react-responsive';

import OrderTopBar from '../OrderTopBar';
import styles from './styles.module.css';

const RefreshActions = lazy(() => import('components/OrderCreatePageComponents/ProductSelectTopBar/RefreshActions'));
const Chip = lazy(() => import('components/Chip'));

// ===== Move to context
const requestLogistics = () => {
	alert('Заявка на логістику надіслана');
};

interface OrderTopSideProps {
	isFullScreen: boolean;
	hasEntities: boolean;
	suborderIndex: number;
	className?: string;
}

const OrderTopSide: React.FC<OrderTopSideProps> = ({ isFullScreen, hasEntities, className, suborderIndex }) => {
	const shouldRenderTopElements = !isFullScreen;
	const hasProductsInOrder = hasEntities;
	const { control, setValue, getValues } = useTypedOrderControllerFromContext();
	const { formattedWeight, formattedPrice, formattedVolume } = useOrderStats({ suborderIndex: 0 });

	const checkboxes = (
		<>
			<Can passThrough I="change" an={`order.${0}.isPaid`}>
				{(can) => (
					<Controller
						name={`suborders.${0}.data.isPaid`}
						control={control}
						render={({ field }) => {
							const handleChange = () => {
								const isWithoutPayment = getValues(`suborders.${0}.data.isWithoutPayment`);
								if (!field.value && isWithoutPayment) {
									setValue(`suborders.${0}.data.isWithoutPayment`, false);
								}
								field.onChange(!field.value);
							};

							return (
								<Checkbox
									label="З оплатою"
									checked={field.value}
									onChange={handleChange}
									disabled={!can}
									className={clsx(styles.checkboxField, { disabled: !can }, className)}
								/>
							);
						}}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${0}.isWithoutPayment`}>
				{(can) => (
					<Controller
						name={`suborders.${0}.data.isWithoutPayment`}
						control={control}
						render={({ field }) => {
							const handleChange = () => {
								const isPaid = getValues(`suborders.${0}.data.isPaid`);
								if (!field.value && isPaid) {
									setValue(`suborders.${0}.data.isPaid`, false);
								}
								field.onChange(!field.value);
							};

							return (
								<Checkbox
									label="Без оплати"
									checked={field.value}
									onChange={handleChange}
									disabled={!can}
									className={clsx(styles.checkboxField, { disabled: !can }, className)}
								/>
							);
						}}
					/>
				)}
			</Can>
		</>
	);

	return (
		<>
			{shouldRenderTopElements && (
				<>
					{hasProductsInOrder && (
						<>
							<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
								<div className={styles.refreshActionsWrapper}>
									{checkboxes}

									<RefreshActions className={className} />
								</div>
							</MediaQuery>

							<MediaQuery minWidth={breakPoints.MOBILE}>
								<OrderTopBar
									data-sticky={STACK_ELEMENTS.TopBar}
									data-background="keep-original"
									className={className}
									onRequestLogistics={requestLogistics}
								/>
							</MediaQuery>

							<div data-sticky={STACK_ELEMENTS.StatsBlock} className={clsx(styles.totalPriceWrapper, className)}>
								<Payable price={formattedPrice} />

								<div className={styles.chipsWrapper}>
									<Chip title={formattedWeight} />
									<Chip title={formattedVolume} />
								</div>

								<MediaQuery minWidth={breakPoints.MOBILE}>{checkboxes}</MediaQuery>
							</div>
						</>
					)}

					<TopBarContainer
						data-sticky={STACK_ELEMENTS.InputsBlock}
						className={clsx(styles.topBarContainer, { [styles.emptyOrder]: !hasProductsInOrder }, className)}
					>
						<OrderInternalDataControlModule suborderIndex={suborderIndex} />
					</TopBarContainer>
				</>
			)}
		</>
	);
};

export default OrderTopSide;
