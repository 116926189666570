import { zodResolver } from '@hookform/resolvers/zod';
import { useAppMode } from 'hooks/useAppMode';
import { Whoami } from 'models/auth';
import { ClientOptionSchema, ContractOptionSchema } from 'models/client';
import { OrganizationOptionSchema } from 'models/employee';
import { CatalogueService } from 'models/service';
import { StockOptionSchema } from 'models/stock';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { logger } from 'utils/logger';

import { OrderAbilityContext } from '../OrderAbility/provider';
import { useDefineOrderAbility } from '../OrderAbility/useDefineOrderAbility';
import { createOrderLikeEntity } from './lib/entity-creators';
import { OrderControllerState, OrderControllerStateSchema } from './lib/schema';

type OrderLikeEntity = ReturnType<typeof createOrderLikeEntity>;
interface NewOrderController {
	data: [OrderLikeEntity, Record<string, CatalogueService>, Whoami];
}

const NewOrderController: React.FC<NewOrderController> = ({ data }) => {
	const [order] = data;
	const { isOffline } = useAppMode();

	const client = ClientOptionSchema.safeParse(order.client);
	const contract = ContractOptionSchema.safeParse(order.contract);
	const organization = OrganizationOptionSchema.safeParse(order.organization);
	const stock = StockOptionSchema.safeParse(order.stock);

	const form = useForm<OrderControllerState>({
		resolver: zodResolver(OrderControllerStateSchema),
		defaultValues: {
			suborders: [
				{
					tabName: 'Основна заявка',
					data: {
						id: '',
						client: client.data,
						contract: contract.data,
						organization: organization.data,
						stock: stock.data,
						responsible: {
							label: order.responsible?.name,
							value: order.responsible?.['1c_uuid'],
						},
						index: 0,
						isPaid: order.isPaid ?? false,
						isReserved: order.isReserved ?? false,
						isWithoutPayment: order.isWithoutPayment ?? false,
						products: {},
						services: {},
						sum: order.sum ?? '0',
						volume: order.volume ?? 0,
						weight: order.weight ?? 0,
						status: 'calculation',
						isSaved: false,
						note: '',
						createdAt: new Date(),
						number: '',
						parentId: null,
						realizations: [],
						payments: [],
						returns: [],
					},
				},
			],
		},
	});

	const suborders = useWatch({ control: form.control, name: 'suborders' });
	const ability = useDefineOrderAbility({ suborders, isOfflineMode: isOffline });

	// !! REMOVE LATER
	logger.debugOrder(form.formState.errors);

	return (
		<OrderAbilityContext.Provider value={ability}>
			<FormProvider {...form}>
				<Outlet />
			</FormProvider>
		</OrderAbilityContext.Provider>
	);
};

export default NewOrderController;
