import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderAddMode } from 'pages/Order/hooks/useOrderAddMode';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { formatInputValue } from 'utils/inputs';
import { toFixed } from 'utils/shared';

const productSuccessfullyAdded = () => toast.success('Товар додано до заявки');

export const withOneStepOrderCart = <TProps extends object>(Component: FunctionComponent<TProps>, { mode }: { mode: 'creation' | 'edit' }) => {
	const WrappedComponent = (props: Omit<TProps, 'mode' | 'onSave' | 'onRowClick' | 'isLoading'>) => {
		const cart = useOrderCart();
		const addMode = useOrderAddMode();
		const { addEntityToOrder, replaceProductInOrder } = useOrderOperationMethods();

		const onRowClick = async (rowIndex: number, quantity: number | string) => {
			const product = cart.getPreviewProduct();
			const { precision } = product?.unit || {};
			const isInt = precision === 0;
			const valueFormatter = formatInputValue({ isInt });
			const amount = toFixed(valueFormatter(quantity), { precise: precision });

			if (rowIndex === 0) {
				cart.updateProductQuantityInCart(amount);
			} else {
				const searchParams = new URLSearchParams(window.location.search);
				const to = Number(searchParams.get('from') || 0);
				const candidates = [
					{ ...product, amount, deviation: String(product.deviation ?? '0'), profitability: String(product.profitability ?? '0') },
				];

				if (addMode.isReplace) {
					replaceProductInOrder({ candidates, to, replaceId: addMode.replaceItemId });
				} else {
					addEntityToOrder({ candidates, to, entityName: 'products' });
				}

				productSuccessfullyAdded();
				cart.closeModal();
				cart.clearMaybeSelectOne();
			}
		};

		return (
			<Component
				{...(props as TProps)}
				onRowClick={onRowClick}
				replaceProductInOrder={replaceProductInOrder}
				onSave={null}
				isLoading={cart.isLoading}
				mode={mode}
				onAddEntitySuccess={productSuccessfullyAdded}
			/>
		);
	};

	return WrappedComponent;
};
