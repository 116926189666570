import clsx from 'clsx';
import Counter from 'components/Counter';
import React, { MouseEvent } from 'react';
import { padEndZero, replaceComaWithDot } from 'utils/inputs';
import { formatNumberToUAH, toFixed } from 'utils/shared';

import styles from './styles.module.css';
import { ItemsProps } from './types';

const Item: React.FC<ItemsProps> = ({
	onClick,
	onDelete,
	onQuantityChange,
	initialValue: counter,
	brand,
	title,
	price,
	unit,
	category,
	withDeleteButton = false,
	className,
}) => {
	const totalPrice = formatNumberToUAH(Number(price) * Number(counter));
	const { precision } = unit || {};
	const resolvedCounter = counter !== undefined ? counter : !precision ? 1 : padEndZero('1', precision);

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		onClick?.();
	};
	const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		onDelete?.();
	};

	const onBlur = () => {
		if (!counter) {
			return onQuantityChange(!precision ? 0 : padEndZero('0', precision));
		}

		onQuantityChange(toFixed(counter, { precise: precision, strictPrecision: true }));
	};

	return (
		<div className={clsx(styles.productItem, className)}>
			<div className={styles.textContentWrapper} onClick={handleClick}>
				<p className={clsx(styles.brand, 'line-clamp text-md-medium')}>{brand.title}</p>
				<p className={clsx(styles.product, 'line-clamp')}>{title}</p>
				{!!category && <p className={clsx(styles.product, 'line-clamp')}>{category}</p>}
				<strong className={styles.price}>{totalPrice}</strong>
				<button type="button" className={clsx(styles.unitButton, 'line-clamp', styles.active)}>
					{unit?.title}
				</button>
			</div>

			<Counter
				withDeleteButton={withDeleteButton}
				onDelete={handleDelete}
				onQuantityChange={onQuantityChange}
				value={resolvedCounter}
				onBlur={onBlur}
				{...(!!precision && { onKeyDown: replaceComaWithDot })}
			/>
		</div>
	);
};

export default Item;
