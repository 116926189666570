import clsx from 'clsx';
import React, { ComponentProps, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import styles from './style.module.css';

interface TableCheckboxProps extends Omit<ComponentProps<'input'>, 'type'> {
	variant?: 'square' | 'rounded';
	indeterminate?: boolean;
}

const TableCheckbox = (
	{ variant = 'square', onClick, indeterminate, className, ...restProps }: TableCheckboxProps,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	const internalRef = useRef<HTMLInputElement>(null);

	useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef.current) {
			internalRef.current.indeterminate = indeterminate;
		}
	}, [indeterminate]);

	if (internalRef.current) {
		internalRef.current.indeterminate = indeterminate;
	}

	return (
		<input
			ref={internalRef}
			className={clsx(
				styles.checkbox,
				{
					[styles.checkboxDefault]: variant === 'square',
					[styles.checkboxRounded]: variant === 'rounded',
				},
				className,
			)}
			type="checkbox"
			onClick={(e) => {
				e.stopPropagation();
				onClick?.(e);
			}}
			{...restProps}
		/>
	);
};

export default forwardRef(TableCheckbox);
