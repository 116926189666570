import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ChangeAddModePayload, OrderWorkingModeState } from './types';

const initialState = {
	addMode: 'regular',
} as OrderWorkingModeState;

const orderWorkingModeSlice = createSlice({
	name: 'orderWorkingMode',
	initialState,
	reducers: {
		changeAddMode: (state, action: PayloadAction<ChangeAddModePayload>) => {
			const { mode } = action.payload;
			state.addMode = mode;

			if (mode === 'regular') {
				state.replaceItemId = null;
			} else {
				state.replaceItemId = action.payload.replaceItemId;
			}
		},
	},
});

export const orderWorkingModeActions = orderWorkingModeSlice.actions;
export default orderWorkingModeSlice.reducer;
