/* eslint-disable no-console */
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import React from 'react';
import { ReactComponent as SettingsIcon } from 'static/images/settings.svg';

import styles from './style.module.css';
import { SettingButtonProps } from './types';

const SettingButton: React.FC<SettingButtonProps> = ({ onClick, children, tooltipText }) => {
	return (
		<div className={styles.box}>
			{!!tooltipText && (
				<Tooltip text={tooltipText}>
					<Button onClick={(e) => onClick(e)} icon={<SettingsIcon />} variant="bordered" />
				</Tooltip>
			)}
			{!tooltipText && <Button onClick={(e) => onClick(e)} icon={<SettingsIcon />} variant="bordered" />}
			{children}
		</div>
	);
};

export default SettingButton;
