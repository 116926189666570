import clsx from 'clsx';
import { useBoolean } from 'hooks/useBoolean';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { hasTouchScreen } from 'utils/devices';

import type { TooltipProps } from './types';

const Tooltip: React.FC<TooltipProps> = ({
	text,
	children,
	withArrowPointer = false,
	position = 'bottom',
	className,
	color = '#fff',
	bgColor = 'rgba(0, 0, 0, 0.5)',
}) => {
	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
	const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { placement: position, strategy: 'fixed' });
	const visible = useBoolean(false);

	const eventHandlers = hasTouchScreen()
		? {
				onTouchStart: (e: React.TouchEvent) => {
					e.stopPropagation();
					visible.open();
				},
				onTouchEnd: visible.close,
				onTouchCancel: visible.close,
				onContextMenu: (e: React.MouseEvent) => {
					e.preventDefault();
				},
		  }
		: {
				onMouseEnter: visible.open,
				onMouseLeave: visible.close,
		  };

	return (
		<span className={clsx(className)} ref={setReferenceElement}>
			<span {...eventHandlers}>{children}</span>
			{visible.isOn && (
				<>
					{createPortal(
						<div
							ref={setPopperElement}
							{...attributes.popper}
							style={
								{
									...popperStyles.popper,
									zIndex: 1000000,
									'--tooltip-bg-color': bgColor,
									'--tooltip-color': color,
								} as React.CSSProperties
							}
							data-arrow={withArrowPointer ? (position === 'top' ? 'botton' : 'top') : 'none'}
							className={clsx('tooltip')}
						>
							{text}
						</div>,
						document.querySelector('#portal') || document.body,
					)}
				</>
			)}
		</span>
	);
};

export default Tooltip;
