import React from 'react';

export const countWhiteSpaces = (str: string) => {
	const whitespaceMatches = str.match(/\s/g);

	if (!whitespaceMatches) {
		return 0;
	}

	return whitespaceMatches.length;
};

export function createTextPostfixer(postfix: string, config?: { onNextLine?: boolean }) {
	return function postfixer(text: string, condition?: boolean) {
		if (condition)
			return (
				<>
					{text} {!!config?.onNextLine && <br />}
					{postfix}
				</>
			);

		return text;
	};
}
