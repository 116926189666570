/* eslint-disable no-console */
const VERSION_KEY = 'UNIT_B_APP';
const VERSION_FILE_URL = '/version.json';

export async function checkAppVersion() {
	try {
		const response = await fetch(VERSION_FILE_URL);
		if (!response.ok) {
			console.error('Failed to fetch version file');
			return;
		}

		const { version: currentVersion } = await response.json();
		const storedVersion = localStorage.getItem(VERSION_KEY);

		const requiresClear = currentVersion.includes('-clear');

		if (storedVersion !== currentVersion) {
			if (requiresClear) {
				localStorage.clear();
				console.log(`localStorage cleared due to version ${currentVersion}`);
			} else {
				console.log(`Version updated to ${currentVersion} without clearing localStorage`);
			}

			localStorage.setItem(VERSION_KEY, currentVersion);
		} else {
			console.log(`App version is already up-to-date: ${currentVersion}`);
		}
	} catch (error) {
		console.error('Error checking version:', error);
	}
}
