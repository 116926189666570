import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type Cell, flexRender, Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { getCommonPinningStyles } from 'components/Table/lib/table/styles/getCommonPinningStyles';
import React from 'react';

import styles from '../..//lib/table/styles/style.module.css';

type DragAlongCellProps = {
	selectable?: boolean;
	filledFromSecondCell?: boolean;
	cell: Cell<AnyArg, unknown>;
	row: Row<AnyArg>;
	isDraggable?: boolean;
};

const DragAlongCell: React.FC<DragAlongCellProps> = ({ cell, selectable, row, filledFromSecondCell, isDraggable }) => {
	const { isDragging, setNodeRef, transform } = useSortable({
		id: cell.column.id,
	});

	const style: React.CSSProperties = {
		opacity: isDragging ? 0.65 : 1,
		position: 'relative',
		transform: CSS.Translate.toString(transform),
		transition: 'width transform 0.2s ease-in-out',
		width: cell.column.getSize(),
		zIndex: isDragging ? 1 : 0,
	};

	return (
		<td
			key={cell.column.id}
			className={clsx(
				{
					[styles.tableCell]: selectable || filledFromSecondCell,
					[styles.tableCellAlternative]: !selectable && !filledFromSecondCell,
					[styles.isPinned]: cell.column.getIsPinned(),
				},
				cell.column.columnDef?.meta?.getTdCssClassName?.(row.original),
			)}
			style={{
				width: cell.column.getSize(),
				...(cell.column.getIsPinned() ? getCommonPinningStyles(cell.column) : isDraggable ? style : {}),
			}}
			ref={setNodeRef}
		>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</td>
	);
};

export default DragAlongCell;
