import { ROUTES_URLS } from 'const';

export const getIsOnSplitViewPage = () =>
	location.pathname.endsWith(ROUTES_URLS.ORDER_NEW_SPLIT_ORDER_SEGMENT) ||
	location.pathname.endsWith(ROUTES_URLS.ORDER_PREVIEW_SPLIT_ORDER_SEGMENT);

type StickyLayoutConfig = {
	stackElements: string[];
	ref: React.RefObject<HTMLElement>;
	offset?: number;
	commonStyles?: Partial<CSSStyleDeclaration>;
};

export const STACK_ELEMENTS = {
	TopBar: 'top-bar',
	InputsBlock: 'inputs-block',
	TabsBlock: 'tabs-block',
	StatsBlock: 'stats-block',
	Table: 'table',
	ServiceSelectors: 'service-selectors',
	ChangePriceBlock: 'change-price-block',
};

export const buildStickyLayout = ({
	stackElements,
	ref,
	offset = 16,
	commonStyles = {
		zIndex: '1',
		position: 'sticky',
		backgroundColor: '#ffffff',
	},
}: StickyLayoutConfig) => {
	const applyStyles = (element: HTMLElement, styles: Partial<CSSStyleDeclaration>) => {
		Object.assign(element.style, styles);
	};

	const getElement = (key: string) => document.querySelector(`[data-sticky="${key}"]`) as HTMLElement;

	const items = stackElements.map(getElement).filter(Boolean) as HTMLElement[];

	let totalHeight = 0;

	items.forEach((item, index) => {
		const height = item.getBoundingClientRect().height;
		const backgroundColor = item.dataset?.background === 'keep-original' ? item.style.backgroundColor : commonStyles.backgroundColor;

		applyStyles(item, {
			...commonStyles,
			backgroundColor,
			top: `${totalHeight}px`,
		});

		if (index !== items.length - 1) {
			totalHeight += height;
		}
	});

	ref.current?.style.setProperty('--table-height', `${window.innerHeight - totalHeight - offset}px`);

	const serviceElement = getElement(STACK_ELEMENTS.ServiceSelectors);

	if (serviceElement) {
		const serviceHeight = serviceElement.getBoundingClientRect().height;
		const lastElement = items[items.length - 1];

		applyStyles(serviceElement, {
			...commonStyles,
			top: `${totalHeight}px`,
		});

		const serviceMarginTop = 8;
		lastElement.style.top = `${totalHeight + serviceHeight + serviceMarginTop}px`;
		ref.current?.style.setProperty(
			'--table-services-height',
			`${window.innerHeight - totalHeight - offset - serviceMarginTop - serviceHeight}px`,
		);
	}
};
