import clsx from 'clsx';
import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import Button from 'components/Button';
import type { RowSelectionModel } from 'components/Table/lib/table/types/table';
import { SERVER_ENTITY_ID } from 'const';
import { useBoolean } from 'hooks/useBoolean';
import { useClickOutside } from 'hooks/useClickOutside';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { toast } from 'react-toastify';
import { ReactComponent as CoinsIcon } from 'static/images/coins-2.svg';
import { toFixed } from 'utils/shared';

import styles from './styles.module.css';
import type { ManagerBonusAction } from './types';
import { getDialogueText } from './utils';

let dialogTextMain = '';
let dialogTextSub = '';
let onDialogSubmit = () => {};
const precise = 2;
interface ManagerBonusButtonProps {
	selectionModel: RowSelectionModel;
	suborderIndex: number;
	disabled?: boolean;
	className?: string;
}

const ManagerBonusButton: React.FC<ManagerBonusButtonProps> = ({ selectionModel, suborderIndex, disabled, className }) => {
	const dialog = useBoolean();
	const actionSelector = useBoolean();
	const { setDirtyValue, getValues } = useOrderOperationMethods();

	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, { strategy: 'absolute', placement: 'bottom-start' });

	const onBonusApply = useStopPropagationCallback(() => {
		const entities = getValues(`suborders.${suborderIndex}.data.products`);
		let minimalPriceProductsCount = 0;

		Object.keys(selectionModel ?? {}).forEach((entity) => {
			const candidate = entities[entity as string];

			const minimalPrice =
				candidate?.prices?.find((priceEntity) => priceEntity?.typePrice?.id === SERVER_ENTITY_ID.ProductMinimalODPriceType)?.price ?? '0';

			const isLowerThanMinimal = Number(candidate.price) < Number(minimalPrice);

			if (minimalPrice && isLowerThanMinimal) {
				minimalPriceProductsCount++;
				return;
			}

			if (candidate.bonusActive) return;

			const bonusPrice = Number(candidate.price) + Number(candidate?.bonus);
			const sum = Number(candidate.amount) * bonusPrice;

			const newCandidate = {
				...candidate,
				bonusActive: true,
				price: toFixed(bonusPrice, { precise }),
				sum: toFixed(sum, { precise }),
				rollbackPrice: candidate.price,
			};

			entities[entity as string] = newCandidate;
		});

		setDirtyValue(`suborders.${suborderIndex}.data.products`, entities);
		actionSelector.close();

		if (minimalPriceProductsCount > 0) {
			toast.warn('До деяких товарів не можливо застосувати "Бонус категорії": вони мають ціну нижчу, ніж мінімальна.');
			return;
		}
		toast.success('Ціну "Бонус категорії" встановлено!');
	});
	const onDiscardApply = useStopPropagationCallback(() => {
		const entities = getValues(`suborders.${suborderIndex}.data.products`);

		Object.keys(selectionModel ?? {}).forEach((entity) => {
			const candidate = entities[entity as string];

			if (!candidate?.bonusActive) return;

			const sum = Number(candidate.amount) * Number(candidate?.rollbackPrice || 0);
			const newCandidate = {
				...candidate,
				bonusActive: false,
				price: candidate?.rollbackPrice,
				sum: toFixed(sum, { precise }),
			};

			entities[entity as string] = newCandidate;
		});

		setDirtyValue(`suborders.${suborderIndex}.data.products`, entities);
		actionSelector.close();
		toast.success('Ціну "Бонус категорії" скасовано!');
	});

	const actionSelectorToggle = useStopPropagationCallback(actionSelector.toggle);
	const dialogClose = useStopPropagationCallback<HTMLButtonElement>(dialog.close);
	const actionSelectorClose = useStopPropagationCallback<HTMLButtonElement>(actionSelector.close);
	const ref = useClickOutside<HTMLUListElement>(actionSelectorClose);

	const handleOptionSelect = (action: ManagerBonusAction) => (e: React.MouseEvent<HTMLLIElement>) => {
		e.stopPropagation();
		const { title, subtitle } = getDialogueText(action);

		if (action === 'apply-bonus') {
			onDialogSubmit = onBonusApply;
		}

		if (action === 'discard-bonus') {
			onDialogSubmit = onDiscardApply;
		}

		dialogTextMain = title;
		dialogTextSub = subtitle;

		dialog.open();
	};

	return (
		<>
			<div ref={setReferenceElement}>
				<Button
					onClick={actionSelectorToggle}
					icon={<CoinsIcon className={styles.icon} />}
					variant="bordered"
					disabled={disabled}
					className={className}
				/>
			</div>

			{actionSelector.isOn &&
				createPortal(
					<div ref={setPopperElement} style={popperStyles.popper} {...attributes.popper}>
						<ul ref={ref} className={clsx('dropdown', styles.dropdown)}>
							<li onClick={handleOptionSelect('apply-bonus')} className={styles.action}>
								<p>{'Застосувати "Бонус категорії"'}</p>
							</li>
							<li onClick={handleOptionSelect('discard-bonus')} className={styles.action}>
								<p>{'Скасувати "Бонус категорії"'}</p>
							</li>
						</ul>
					</div>,
					document.querySelector('#portal'),
				)}
			{dialog.isOn && (
				<AlertDialogue onOutsideClick={dialogClose}>
					<AlertDialogueHeader onClose={dialogClose}>Підтвердження дії</AlertDialogueHeader>
					<AlertDialogueContent>
						<AlertDialogueMessage>{dialogTextMain}</AlertDialogueMessage>
						<AlertDialogueMessage>{dialogTextSub}</AlertDialogueMessage>
					</AlertDialogueContent>
					<AlertDialogueFooter>
						<AlertDialogueControlButton variant="cancel" onClick={dialogClose}>
							Скасувати
						</AlertDialogueControlButton>
						<AlertDialogueControlButton
							variant="submit"
							onClick={() => {
								onDialogSubmit?.();
								dialog.close();
							}}
						>
							Так, застосувати
						</AlertDialogueControlButton>
					</AlertDialogueFooter>
				</AlertDialogue>
			)}
		</>
	);
};
export default ManagerBonusButton;
