import type { Whoami } from 'models/auth';
import type { Client } from 'models/client';
import type { OrganizationOption } from 'models/employee';
import type { CatalogueProductWithAmount } from 'models/product/catalogue-product';
import type { CatalogueService } from 'models/service';
import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';
import { roundNumber, toFixed } from 'utils/shared';
import { isObject, isString } from 'utils/type-guards';

import { getDeviationFromPrice } from '../lib/shared';
import type { DetailedTransferCandidate, ProductInternalModelState, ServiceInternalModelState, SuborderSnapshot } from '../OrderController';
import { SuborderTab, useTypedOrderControllerFromContext } from '../OrderController';
import { hasSalt, transformCatalogueServiceToInternalServiceStateModel } from '../OrderController/lib/transform';
import { calculateTotals, calculateTotalServicesCost, getBlankProduct, getEntityDefaultPrice, getEntityDIYPrice } from '../OrderController/lib/utils';

export type EntityName = 'products' | 'services';
export interface OnEntityDeletePayload {
	entityName: EntityName;
	suborderIndex: number;
	candidates: string[];
}
export type OnEntityTransferPayload =
	| {
			entityName: EntityName;
			to: number;
			from: number;
			candidates: string[];
	  }
	| {
			entityName: EntityName;
			to: number;
			from: number;
			candidates: ProductInternalModelState[];
	  }
	| {
			entityName: EntityName;
			to: number;
			from: number;
			candidates: ServiceInternalModelState[];
	  };
export interface GetNewSuborderTabDataPayload {
	entityName: EntityName;
	data?: string[];
	from: number;
}
export interface AddClientToNewOrderPayload {
	client: Client;
	fallbackManager: Whoami;
	organization: OrganizationOption;
}
export interface AddEntityToOrderPayload {
	entityName: EntityName;
	to: number;
	candidates: CatalogueProductWithAmount[] | ServiceInternalModelState[];
	triggerRerender?: boolean;
}
export interface ReplaceProductInOrderPayload {
	to: number;
	candidates: CatalogueProductWithAmount[];
	replaceId: string;
}
export interface AddServiceToOrderPayload {
	to: number;
	candidates: ServiceInternalModelState[];
	triggerRerender?: boolean;
}

export interface HydrateBlankProductWithDataPayload {
	targetId: string;
	candidate: CatalogueProductWithAmount;
	suborderIndex: number;
}
export interface AddBlankProductPayload {
	suborderIndex: number;
}
export interface RemoveBlankProductPayload {
	targetId: string;
	suborderIndex: number;
}
export interface GetBlankProductFromStorePayload {
	suborderIndex: number;
}
export interface OnSuborderDeletePayload {
	suborderIndex: number;
}

export const useOrderOperationMethods = () => {
	const servicesQuery = useGetServicesQuery<Record<string, CatalogueService>>();
	const catalogueServices = (servicesQuery?.data ?? {}) as Record<string, CatalogueService>;

	const { getValues, setValue, control, trigger, watch, handleSubmit, formState } = useTypedOrderControllerFromContext();

	const setDirtyValue: typeof setValue = (name, value) => {
		setValue('isDirty', true);
		// @ts-ignore
		setValue(name, value);
	};

	const onEntityDelete = ({ entityName, suborderIndex, candidates }: OnEntityDeletePayload) => {
		const suborders = getValues('suborders');
		const target = suborders[suborderIndex];
		const isDeletingFromRoot = suborderIndex === 0;
		const entitiesRepository = target.data[entityName];
		const root = suborders[0];
		const rootEntitiesRepository = root.data[entityName];

		if (isDeletingFromRoot) {
			const subordersWithoutCandidates = suborders.map((suborder) => {
				const { stateModel } = deleteEntitiesFromStateModel(suborder.data[entityName], candidates);

				const isServices = entityName === 'services';
				const services = isServices ? stateModel : suborder.data.services;
				const products = isServices ? suborder.data.products : stateModel;

				if (!isServices) {
					const servicesArray = Object.values(services ?? {});
					const serviceIdSet = new Set(servicesArray.map((service) => service.id));

					candidates.forEach((candidate) => {
						const product = suborder.data.products[candidate];

						if (!product?.serviceCode) return;

						servicesArray.forEach((service) => {
							if (serviceIdSet.has(service.id) && hasSalt(service.id, [product.serviceCode])) {
								delete services[service.id];
								serviceIdSet.delete(service.id);
							}
						});
					});
				}

				const totals = calculateTotals(Object.values(products ?? {}));
				const totalServicesCost = calculateTotalServicesCost(Object.values(services ?? {}));

				return {
					...suborder,
					data: {
						...suborder.data,
						sum: totals.sum + totalServicesCost,
						weight: totals.weight,
						volume: totals.volume,
						products,
						services,
					},
				};
			});

			setDirtyValue('suborders', subordersWithoutCandidates);
			return;
		}

		if (entityName === 'services') {
			const { stateModel, amount } = deleteEntitiesFromStateModel(target.data.services, candidates);
			const rootStateModel = deleteEntitiesFromRoot(root.data.services, amount);

			const targetTotals = calculateTotals(Object.values(target.data.products ?? {}));
			const targetTotalServicesCost = calculateTotalServicesCost(Object.values(stateModel ?? {}));

			const rootTotals = calculateTotals(Object.values(root.data.products ?? {}));
			const rootTotalServicesCost = calculateTotalServicesCost(Object.values(rootStateModel ?? {}));

			const payload = {
				...target.data,
				sum: targetTotals.sum + targetTotalServicesCost,
				weight: targetTotals.weight,
				volume: targetTotals.volume,
				[entityName]: stateModel,
			};

			const rootPayload = {
				...root.data,
				sum: rootTotals.sum + rootTotalServicesCost,
				weight: rootTotals.weight,
				volume: rootTotals.volume,
				[entityName]: rootStateModel,
			};

			setDirtyValue(`suborders.${suborderIndex}.data`, payload);
			setDirtyValue(`suborders.${0}.data`, rootPayload);
			return;
		}

		const { stateModel, amount } = deleteEntitiesFromStateModel(entitiesRepository, candidates);
		const servicesModel = deleteProductServicesFromSuborder(target, candidates);
		const rootStateServiceModel = deleteProductServicesFromSuborder(suborders[0], candidates);
		const rootStateModel = deleteEntitiesFromRoot(rootEntitiesRepository, amount);

		const targetTotals = calculateTotals(Object.values(stateModel));
		const targetTotalServicesCost = calculateTotalServicesCost(Object.values(servicesModel ?? {}));

		const rootTotals = calculateTotals(Object.values(rootStateModel));
		const rootTotalServicesCost = calculateTotalServicesCost(Object.values(rootStateServiceModel ?? {}));

		const payload = {
			...target.data,
			sum: targetTotals.sum + targetTotalServicesCost,
			weight: targetTotals.weight,
			volume: targetTotals.volume,
			products: stateModel,
			services: servicesModel,
		};

		const rootPayload = {
			...root.data,
			sum: rootTotals.sum + rootTotalServicesCost,
			weight: rootTotals.weight,
			volume: rootTotals.volume,
			products: rootStateModel,
			services: rootStateServiceModel,
		};

		setDirtyValue(`suborders.${suborderIndex}.data`, payload);
		setDirtyValue(`suborders.${0}.data`, rootPayload);
	};

	const getNewTabDataPayload = (payload: GetNewSuborderTabDataPayload): SuborderTab => {
		const { entityName, data, from = 0 } = payload ?? {};
		const repository = getValues('suborders');
		const source = repository[from];

		const entitiesRepository = Object.values(source.data[entityName] ?? {});
		const entityCandidates = entitiesRepository?.filter((entity) => data?.includes(entity.id)) ?? [];
		const services = entityName === 'services' ? Object.fromEntries(entityCandidates.map((entity) => [entity.id, entity])) : {};
		const products = entityName === 'products' ? Object.fromEntries(entityCandidates.map((entity) => [entity.id, entity])) : {};

		const index = repository.length;
		const tabName = `Заявка ${index}`;
		const rootOrderId = repository?.[0].data?.id ?? '';

		let stats = { weight: 0, sum: 0, volume: 0 };

		if (entityName === 'products') {
			stats = calculateTotals(entityCandidates);
		} else {
			stats.sum = calculateTotalServicesCost(entityCandidates as ServiceInternalModelState[]);
		}

		const tabData = {
			...source.data,
			sum: stats.sum,
			weight: stats.weight,
			volume: stats.volume,
			products,
			services,
			isSaved: false,
			isReserved: false,
			isPaid: false,
			isWithoutPayment: false,
			id: '',
			parentId: rootOrderId || null,
			[entityName]: Object.fromEntries(entityCandidates.map((entity) => [entity.id, entity])),
		} as SuborderSnapshot;

		return {
			tabName,
			index,
			data: tabData,
		} as SuborderTab;
	};

	const onEntityTransfer = ({ entityName, from, to, candidates }: OnEntityTransferPayload) => {
		const suborders = getValues('suborders');
		const source = suborders[from];
		const sourceRepository = source.data[entityName];
		const isTransferFromRoot = from === 0;

		let target = suborders?.[to];

		if (!target) {
			target = getNewTabDataPayload({ entityName, from });
		}

		const targetRepository = target.data[entityName];

		if (isTransferFromRoot) {
			const transferEntities = candidates
				.map((candidate) => sourceRepository[typeof candidate === 'string' ? candidate : candidate.id])
				.filter(Boolean);
			const allTransferEntities = [...Object.values(targetRepository), ...transferEntities];
			const payload = Object.fromEntries(allTransferEntities.map((entity) => [entity.id, entity]));

			setDirtyValue(`suborders.${to}.data.${entityName}`, payload);

			return;
		}

		const entityCandidates = candidates as DetailedTransferCandidate[];
		const candidatesMap = new Map<string, DetailedTransferCandidate>(entityCandidates.map((candidate) => [candidate.id, candidate]));

		const sourceSuborderEntities = sourceRepository;
		const targetSuborderEntities = targetRepository ?? {};

		const patchedSourceRepository = Object.fromEntries(
			Object.entries<DetailedTransferCandidate>(sourceSuborderEntities)
				.map(([id, entity]) => {
					const candidate = candidatesMap.get(id);

					if (!candidate) return [id, entity];

					const delta = Number(entity.amount) - Number(candidate.amount);
					const sum = String(Number(delta) * Number(entity.price));
					// @ts-ignore
					const amount = toFixed(delta, { precise: entity?.unit?.precision });
					const newEntity = { ...entity, amount, sum };

					return [id, newEntity];
				})
				.filter(([, entity]) => Number(isString(entity) ? entity : entity.amount) > 0),
		);

		const patchedTargetRepository = Object.fromEntries(
			Object.entries<DetailedTransferCandidate>(targetSuborderEntities)
				.map(([id, entity]) => {
					const candidate = candidatesMap.get(id);
					// @ts-ignore
					const precise = entityName === 'services' ? 0 : entity?.unit?.precision;

					if (candidate) {
						const amount = toFixed(Number(candidate.amount) + (entity.amount ? Number(entity.amount) : 0), { precise });
						const sum = toFixed(Number(amount) * Number(entity.price), { precise: 2 });
						const newEntity = { ...entity, amount, sum };

						return [id, newEntity];
					}

					return [id, entity];
				})
				.concat(
					entityCandidates
						.filter((candidate) => !targetSuborderEntities[candidate.id])
						.map((candidate) => {
							const sum = toFixed(Number(candidate.amount) * Number(candidate.price));
							const newEntity = { ...candidate, sum };

							return [candidate.id, newEntity];
						}),
				),
		);

		const updatedEntities = Object.entries<DetailedTransferCandidate>({
			...patchedSourceRepository,
			...patchedTargetRepository,
		}).reduce((acc, [id, entity]) => {
			const sourceEntity = patchedSourceRepository[id];
			const targetEntity = patchedTargetRepository[id];

			// @ts-ignore
			const precise = entityName === 'services' ? 0 : entity?.unit?.precision;

			if (sourceEntity && targetEntity) {
				const amount = toFixed(Number(sourceEntity.amount) + Number(targetEntity.amount), { precise });
				const sum = toFixed(Number(amount) * Number(entity.price));

				acc[id] = { ...entity, amount, sum };
			} else if (targetEntity) {
				const amount = toFixed(Number(targetEntity.amount), { precise });
				const sum = toFixed(Number(amount) * Number(entity.price));

				acc[id] = { ...entity, amount, sum };
			} else if (sourceEntity) {
				const amount = toFixed(Number(sourceEntity.amount), { precise });
				const sum = toFixed(Number(amount) * Number(entity.price));

				acc[id] = { ...entity, amount, sum };
			}

			return acc;
		}, {} as Record<string, DetailedTransferCandidate>);

		const updatedEntitiesArray = Object.values(updatedEntities);
		const otherRepositories = suborders
			.filter((_, index) => index !== to && index !== from && index !== 0)
			.map((suborder) => Object.values(suborder.data[entityName] ?? {}))
			.flat();

		const root = suborders[0];

		if (entityName === 'services') {
			const rootServices = replaceServicesInRepository(updatedEntitiesArray, suborders[0].data.services, otherRepositories);

			const sourcePayload = createPayload(source, Object.values(patchedSourceRepository), 'services');
			const targetPayload = createPayload(target, Object.values(patchedTargetRepository), 'services');
			const rootPayload = createPayload(root, rootServices, 'services');

			// console.log('sourcePayload -->', sourcePayload);
			// console.log('targetPayload -->', targetPayload);
			// console.log('rootPayload -->', rootPayload);
			// return;
			setDirtyValue(`suborders.${from}.data`, sourcePayload);
			setDirtyValue(`suborders.${to}.data`, targetPayload);
			setDirtyValue(`suborders.${0}.data`, rootPayload);
			return;
		}

		const rootProducts = replaceCandidatesInRepository(updatedEntitiesArray, suborders[0].data.products, otherRepositories);

		const sourcePayload = createPayload(source, Object.values(patchedSourceRepository));
		const targetPayload = createPayload(target, Object.values(patchedTargetRepository));
		const rootPayload = createPayload(root, rootProducts);

		setDirtyValue(`suborders.${from}.data`, sourcePayload);
		setDirtyValue(`suborders.${to}.data`, targetPayload);
		setDirtyValue(`suborders.${0}.data`, rootPayload);
	};

	const addClientToNewOrder = ({ client, fallbackManager, organization }: AddClientToNewOrderPayload) => {
		const repository = getValues(`suborders.${0}.data`);

		const responsible = fallbackManager
			? {
					label: fallbackManager?.name ?? '',
					value: fallbackManager?.['1c_uuid'] ?? '',
			  }
			: null;

		const stock = fallbackManager?.stock
			? {
					label: fallbackManager.stock?.title ?? '',
					value: fallbackManager.stock?.id ?? '',
			  }
			: null;
		const contract = client.contracts?.[0]
			? {
					label: client.contracts?.[0]?.title,
					value: client.contracts?.[0]?.id,
			  }
			: null;

		const clientData = client
			? {
					// @ts-ignore
					label: client?.organizationName || client?.name || '',
					value: client.id,
			  }
			: null;

		const newRepository = {
			...repository,
			responsible,
			organization: organization || null,
			client: clientData,
			stock,
			contract,
		};

		setDirtyValue(`suborders.${0}.data`, newRepository);
	};

	const addServicesToOrder = ({ candidates, to }: AddServiceToOrderPayload) => {
		const suborders = getValues('suborders');
		const [rootSuborder] = suborders;
		const isAddingToRoot = to === 0;

		const servicesCandidates = candidates as ServiceInternalModelState[];
		const target = suborders[to].data.services;

		if (isAddingToRoot) {
			const services = mergeServicesWithRepository(servicesCandidates, target);
			const payload = createPayload(suborders[0], services, 'services');

			setDirtyValue(`suborders.${0}.data`, payload);
			return;
		}

		const services = mergeServicesWithRepository(servicesCandidates, target);
		const payload = createPayload(suborders[to], services, 'services');

		const rootServices = mergeServicesWithRepository(servicesCandidates, rootSuborder.data.services);
		const rootPayload = createPayload(rootSuborder, rootServices, 'services');

		setDirtyValue(`suborders.${to}.data`, payload);
		setDirtyValue(`suborders.${0}.data`, rootPayload);
	};

	const addEntityToOrder = ({ candidates: rawCandidates, entityName, to }: AddEntityToOrderPayload) => {
		const suborders = getValues('suborders');
		const [rootSuborder] = suborders;
		const isAddingToRoot = to === 0;

		if (entityName === 'services') {
			return addServicesToOrder({ candidates: rawCandidates, to });
		}

		const target = suborders[to].data.products;
		const candidates = rawCandidates as CatalogueProductWithAmount[];

		const productsWithServices = candidates.filter((product) => !!product?.serviceCode);

		if (isAddingToRoot) {
			const products = mergeCandidatesWithRepository(candidates, target);
			const payload = createPayload(rootSuborder, products);

			setDirtyValue(`suborders.${0}.data`, payload);

			if (productsWithServices.length > 0) {
				const services = productsWithServices.map((product) => getMetalCuttingServiceForProduct({ product, services: catalogueServices }));
				addServicesToOrder({ candidates: services, to: 0 });
			}

			return;
		}

		const products = mergeCandidatesWithRepository(candidates, target);
		const payload = createPayload(suborders[to], products);

		const rootProducts = mergeCandidatesWithRepository(candidates, rootSuborder.data.products);
		const rootPayload = createPayload(rootSuborder, rootProducts);

		setDirtyValue(`suborders.${to}.data`, payload);
		setDirtyValue(`suborders.${0}.data`, rootPayload);

		if (productsWithServices.length > 0) {
			const services = productsWithServices.map((product) => getMetalCuttingServiceForProduct({ product, services: catalogueServices }));
			addServicesToOrder({ candidates: services, to });
		}
	};

	const takeRollbackSnapshot = () => {
		const doesRollbackExist = getValues('rollbackSnapshot');

		if (doesRollbackExist) return;

		const suborders = getValues('suborders');
		setDirtyValue('rollbackSnapshot', structuredClone(suborders));
	};
	const rollbackState = () => {
		const rollbackSnapshot = getValues('rollbackSnapshot');

		if (!rollbackSnapshot) return;

		setDirtyValue('suborders', rollbackSnapshot);
	};
	const resetRollbackSnapshot = () => {
		setDirtyValue('rollbackSnapshot', null);
	};
	const getRollbackSnapshot = () => getValues('rollbackSnapshot');

	const hydrateBlankProductWithData = ({ candidate, targetId, suborderIndex }: HydrateBlankProductWithDataPayload) => {
		const suborders = getValues('suborders');
		const rootSuborder = suborders[0];

		const productsInTargetSuborder = suborders[suborderIndex].data.products;
		// Helper function to map candidates to entities with their IDs as keys
		const mapCandidatesToEntityIds = (entities: AnyArg[]) => entities.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {});

		const productsCandidates = [candidate].map((entity) => {
			const duplicate = productsInTargetSuborder[entity.id];

			if (duplicate) {
				const newAmount = Number(duplicate.amount) + Number(entity?.amount || 1);
				const newSum = roundNumber(Number(duplicate.price) * newAmount, 2);

				delete productsInTargetSuborder[targetId];

				return {
					...duplicate,
					sum: toFixed(newSum),
					amount: toFixed(newAmount),
				};
			}

			const entityWithDefaultPrice = getEntityDefaultPrice(entity, 'products');
			const priceValue = toFixed(entityWithDefaultPrice?.price);
			const sum = toFixed(Number(entity.amount) * Number(priceValue));

			delete productsInTargetSuborder[targetId];

			return {
				...entity,
				sum,
				price: priceValue,
			};
		}) as CatalogueProductWithAmount[];

		const mappedProductsCandidates = mapCandidatesToEntityIds(productsCandidates);

		const rootProducts = { ...rootSuborder.data.products, ...mappedProductsCandidates };
		const rootTotals = calculateTotals(Object.values(rootProducts));
		const totalServicesCost = calculateTotalServicesCost(Object.values(rootSuborder.data.services ?? {}));

		setDirtyValue(`suborders.${0}.data.products`, rootProducts);
		setDirtyValue(`suborders.${0}.data.sum`, rootTotals.sum + totalServicesCost);
		setDirtyValue(`suborders.${0}.data.weight`, rootTotals.weight);
		setDirtyValue(`suborders.${0}.data.volume`, rootTotals.volume);

		if (suborderIndex !== 0) {
			const updatedSuborderProducts = { ...productsInTargetSuborder, ...mappedProductsCandidates };
			const suborderTotals = calculateTotals(Object.values(updatedSuborderProducts));
			const servicesInTargetSuborder = suborders[suborderIndex].data.services;
			const totalSuborderServicesCost = calculateTotalServicesCost(Object.values(servicesInTargetSuborder ?? {}));

			setDirtyValue(`suborders.${suborderIndex}.data.products`, updatedSuborderProducts);
			setDirtyValue(`suborders.${suborderIndex}.data.sum`, suborderTotals.sum + totalSuborderServicesCost);
			setDirtyValue(`suborders.${suborderIndex}.data.weight`, suborderTotals.weight);
			setDirtyValue(`suborders.${suborderIndex}.data.volume`, suborderTotals.volume);
		}
	};

	const addBlankProduct = ({ suborderIndex }: AddBlankProductPayload) => {
		const suborders = getValues('suborders');
		const targetSuborder = suborders[suborderIndex];
		const newSuborders = [...suborders];

		const newSuborder = structuredClone(targetSuborder);
		const blankProduct = getBlankProduct();

		newSuborder.data.products[blankProduct.id] = blankProduct;
		newSuborders[suborderIndex] = newSuborder;

		setValue('suborders', newSuborders);
	};
	const removeBlankProduct = ({ suborderIndex, targetId }: RemoveBlankProductPayload) => {
		const suborders = getValues('suborders');
		const targetSuborder = suborders[suborderIndex];
		const newSuborders = [...suborders];

		const newSuborder = structuredClone(targetSuborder);

		delete newSuborder.data.products[targetId];
		newSuborders[suborderIndex] = newSuborder;

		setValue('suborders', newSuborders);
	};

	const getBlankProductFromStore = ({ suborderIndex }: GetBlankProductFromStorePayload) => {
		const suborders = getValues('suborders');
		const blankProduct = Object.values(suborders?.[suborderIndex]?.data?.products ?? {}).find((product) => product?.isBlank);

		return {
			id: blankProduct?.id || null,
		};
	};

	const getIsOrderDirty = () => getValues().isDirty;
	const resetState = (suborders?: AnyArg[]) => {
		setValue('suborders', suborders);
		setValue('isDirty', false);
	};

	const setIsDirty = (isDirty: boolean) => {
		setValue('isDirty', isDirty);
	};

	const onSuborderDelete = ({ suborderIndex }: OnSuborderDeletePayload) => {
		const suborders = getValues('suborders');
		const deleteCandidate = suborders[suborderIndex];
		const deleteProductCandidates = Object.values(deleteCandidate.data.products ?? {}).map((product) => [product.id, product.amount]) as [
			string,
			string,
		][];
		const deleteServiceCandidates = Object.values(deleteCandidate.data.services ?? {}).map((service) => [service.id, service.amount]) as [
			string,
			string,
		][];

		const filtered = suborders.filter((_, index) => index !== suborderIndex);

		const newSuborders = filtered.map((suborder, index) => {
			return {
				...suborder,
				...(index !== 0 && { tabName: `Заявка ${index}` }),
			};
		});

		const [root, ...restSuborders] = newSuborders;
		const products = deleteEntitiesFromRoot(root.data.products, deleteProductCandidates);
		const services = deleteEntitiesFromRoot(root.data.services, deleteServiceCandidates);

		const totals = calculateTotals(Object.values(products ?? {}));
		const totalServicesCost = calculateTotalServicesCost(Object.values(services ?? {}));

		const newRoot = {
			...root,
			data: {
				...root.data,
				services,
				sum: totals.sum ?? 0 + totalServicesCost ?? 0,
				weight: totals.weight,
				volume: totals.volume,
			},
		};
		const newServices = deleteProductServicesFromSuborder(
			newRoot,
			Object.values(deleteCandidate.data.products ?? {}).map((p) => p.id),
		);

		newRoot.data.services = newServices;
		newRoot.data.products = products;

		const payload = [newRoot, ...restSuborders];

		setValue('suborders', payload);
	};

	const getMetalRelatedProducts = () => {
		const productsRepository = getValues(`suborders.${0}.data.products`);
		const products = Object.values(productsRepository ?? {}).filter((product) => !!product?.serviceCode);

		return {
			all: products,
			cuttable: products.filter((product) => product?.isCuttable),
		};
	};

	const getAllowedMetalCuttingOptions = () => {
		const { all } = getMetalRelatedProducts();

		const options = all.reduce(
			(acc, product) => {
				const serviceModel = transformCatalogueServiceToInternalServiceStateModel({
					service: catalogueServices['Послуги порізка металу'],
					serviceName: 'Послуги порізка металу',
					allowedServices: [product.serviceCode],
				});

				if (!acc.services.some((s) => s.value === serviceModel.service.id)) {
					acc.services.push({ label: serviceModel.service.title, value: serviceModel.service.id, data: serviceModel });
				}

				const category = serviceModel.service.services[0];
				if (!acc.categories[serviceModel.service.id]) {
					acc.categories[serviceModel.service.id] = [];
				}
				if (!acc.categories[serviceModel.service.id].some((c) => c.value === category.id)) {
					acc.categories[serviceModel.service.id].push({ label: category.title, value: category.id, data: category });
				}

				return acc;
			},
			{ services: [], categories: {} },
		);

		return options;
	};

	const replaceProductInOrder = ({ to, candidates, replaceId }: ReplaceProductInOrderPayload) => {
		const suborders = getValues('suborders');
		const target = suborders[to];

		if (!target) return;

		const [root] = suborders;
		const isAddingToRoot = to === 0;

		if (isAddingToRoot) {
			const products = replaceProducts({ candidates, repository: root.data.products, replaceId });
			const payload = createPayload(root, products, 'products');

			setDirtyValue(`suborders.${0}.data`, payload);
			return;
		}

		const targetProducts = replaceProducts({ candidates, repository: target.data.products, replaceId });
		const rootProducts = replaceProducts({ candidates, repository: root.data.products, replaceId, targetRepository: target.data.products });
		const targetPayload = createPayload(root, targetProducts, 'products');
		const rootPayload = createPayload(root, rootProducts, 'products');

		setDirtyValue(`suborders.${to}.data`, targetPayload);
		setDirtyValue(`suborders.${0}.data`, rootPayload);
	};

	return {
		onEntityDelete,
		onEntityTransfer,
		onSuborderDelete,
		getNewTabDataPayload,
		addClientToNewOrder,
		addEntityToOrder,
		replaceProductInOrder,
		control,
		getValues,
		setValue,
		setDirtyValue,
		trigger,
		watch,
		handleSubmit,
		formState,
		takeRollbackSnapshot,
		resetRollbackSnapshot,
		getRollbackSnapshot,
		rollbackState,
		hydrateBlankProductWithData,
		addBlankProduct,
		removeBlankProduct,
		getBlankProductFromStore,
		getIsOrderDirty,
		resetState,
		setIsDirty,
		getMetalRelatedProducts,
		getAllowedMetalCuttingOptions,
	};
};

function createStateModel(entities: AnyArg[]) {
	return entities.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {});
}

function deleteEntitiesFromStateModel(source: Record<string, AnyArg>, entities: string[]) {
	const newSource = { ...source };
	const amount = [];

	entities.forEach((id) => {
		const candidate = newSource[id];

		if (!candidate) return;

		amount.push([candidate.id, candidate.amount]);
		delete newSource[id];
	});

	return { stateModel: newSource, amount };
}
function deleteEntitiesFromRoot(source: Record<string, AnyArg>, entities: [string, string][]) {
	const newSource = { ...source };

	entities.forEach(([id, amountStr]) => {
		const candidate = newSource[id];

		if (!candidate) return;

		const amount = Number(amountStr);
		const candidateAmount = Number(candidate.amount);

		const precise =
			isObject(candidate) && 'unit' in candidate && isObject(candidate.unit) && 'precision' in candidate.unit
				? (candidate.unit?.precision as number)
				: 0;

		if (amount >= candidateAmount) {
			delete newSource[id];
		} else {
			candidate.amount = toFixed(candidateAmount - amount, { precise });
		}
	});

	return newSource;
}
function deleteProductServicesFromSuborder(suborder: AnyArg, entities: string[]) {
	const services = { ...suborder.data.services };
	const products = suborder.data.products;

	const serviceMap = new Map<string, AnyArg>(Object.values(services ?? {}).map((service: AnyArg) => [service.id, service]));

	entities.forEach((productId) => {
		const candidate = products[productId];

		if (!candidate?.serviceCode) return;

		const service = Array.from(serviceMap.values()).find((s) => hasSalt(s.id, [candidate.serviceCode]));

		if (!service) return;

		const amount = Number(service.amount);
		const candidateAmount = Number(candidate.amount);

		if (amount >= candidateAmount) {
			delete services[service.id];
			serviceMap.delete(service.id);
		} else {
			services[service.id].amount = toFixed(candidateAmount - amount, { precise: 0 });
		}
	});

	return services;
}

function mergeCandidatesWithRepository(candidates: AnyArg[], repository: AnyArg) {
	const merged = new Map<string, AnyArg>();

	Object.values(repository).forEach((entity: AnyArg) => {
		merged.set(entity.id, entity);
	});

	candidates.forEach((entity) => {
		const duplicate = merged.get(entity.id);
		const precise = entity?.unit?.precision;

		if (!duplicate) {
			const price = toFixed(getEntityDIYPrice(entity, 'products'));
			const deviation = getDeviationFromPrice(price, entity);
			const sum = toFixed(Number(entity.amount) * Number(price), { precise: 2 });
			const amount = toFixed(entity.amount, { precise });

			merged.set(entity.id, { ...entity, sum, amount, price, deviation });
		} else {
			const amount = toFixed(Number(duplicate.amount) + Number(entity?.amount || 1), { precise });
			const sum = toFixed(Number(duplicate.price) * Number(amount), { precise: 2 });
			const price = toFixed(getEntityDIYPrice(duplicate, 'products'));

			merged.set(entity.id, { ...duplicate, price, sum, amount });
		}
	});

	return Array.from(merged.values()) as CatalogueProductWithAmount[];
}
function replaceCandidatesInRepository(candidates: AnyArg[], repository: AnyArg, otherRepositories: AnyArg[]) {
	const merged = new Map<string, AnyArg>();

	Object.values(repository).forEach((entity: AnyArg) => {
		merged.set(entity.id, entity);
	});

	const totalAmounts = [...candidates, ...otherRepositories].reduce((totals, entity) => {
		totals[entity.id] = Number(totals[entity.id] || 0) + Number(entity.amount);

		return totals;
	}, {} as Record<string, number>);

	candidates.forEach((entity) => {
		const repositoryEntity = repository[entity.id];
		const precise = entity?.unit?.precision;

		if (!repositoryEntity) return;

		const candidateAmount = Number(entity.amount);
		const repositoryAmount = Number(repositoryEntity.amount);
		const totalAmount = totalAmounts[entity.id] || 0;

		if (totalAmount > repositoryAmount) {
			const amount = toFixed(totalAmount, { precise });
			const sum = toFixed(Number(repositoryEntity.price) * Number(amount), { precise: 2 });
			const price = toFixed(getEntityDIYPrice(repositoryEntity, 'products'));

			merged.set(entity.id, { ...repositoryEntity, price, sum, amount });
		} else {
			const amount = toFixed(candidateAmount, { precise });
			const sum = toFixed(Number(repositoryEntity.price) * Number(amount), { precise: 2 });
			const price = toFixed(getEntityDIYPrice(entity, 'products'));

			merged.set(entity.id, { ...repositoryEntity, price, sum, amount });
		}
	});

	return Array.from(merged.values()) as CatalogueProductWithAmount[];
}

function createPayload(order: { data?: Record<string, AnyArg> }, entities: Record<string, AnyArg>[], entityName: EntityName = 'products') {
	const totals = calculateTotals(entities);
	const servicesCost = calculateTotalServicesCost(Object.values(order?.data?.services ?? {}));
	const newModel = createStateModel(entities);

	const payload = {
		...(order.data ?? {}),
		[entityName]: newModel,
		sum: totals.sum ?? 0 + servicesCost ?? 0,
		weight: totals.weight,
		volume: totals.volume,
	};

	return payload;
}

function mergeServicesWithRepository(candidates: AnyArg[], repository: AnyArg) {
	const merged = new Map<string, AnyArg>();

	Object.values(repository).forEach((entity: AnyArg) => {
		merged.set(entity.id, entity);
	});

	candidates.forEach((entity) => {
		const duplicate = merged.get(entity.id);

		if (!duplicate) {
			const price = entity?.price || getEntityDIYPrice(entity, 'services');
			const sum = toFixed(Number(entity.amount) * Number(price), { precise: 2 });
			const amount = toFixed(entity.amount, { precise: 0 });

			merged.set(entity.id, { ...entity, sum, amount, price });
		} else {
			const amount = toFixed(Number(duplicate.amount) + Number(entity?.amount || 1), { precise: 0 });
			const sum = toFixed(Number(duplicate.price) * Number(amount), { precise: 2 });
			const price = toFixed(getEntityDIYPrice(duplicate, 'services'));

			merged.set(entity.id, { ...duplicate, price, sum, amount });
		}
	});

	return Array.from(merged.values()) as AnyArg[];
}

function replaceServicesInRepository(candidates: AnyArg[], repository: AnyArg, otherRepositories: AnyArg[]) {
	const merged = new Map<string, AnyArg>();

	Object.values(repository).forEach((entity: AnyArg) => {
		merged.set(entity.id, entity);
	});

	const totalAmounts = [...candidates, ...otherRepositories].reduce((totals, entity) => {
		totals[entity.id] = Number(totals[entity.id] || 0) + Number(entity.amount);

		return totals;
	}, {} as Record<string, number>);

	candidates.forEach((entity) => {
		const repositoryEntity = repository[entity.id];
		const precise = 0;

		if (!repositoryEntity) return;

		const candidateAmount = Number(entity.amount);
		const repositoryAmount = Number(repositoryEntity.amount);
		const totalAmount = totalAmounts[entity.id] || 0;

		if (totalAmount > repositoryAmount) {
			const amount = toFixed(totalAmount, { precise });
			const sum = toFixed(Number(repositoryEntity.price) * Number(amount), { precise: 2 });
			const price = toFixed(getEntityDIYPrice(repositoryEntity, 'services'));

			merged.set(entity.id, { ...repositoryEntity, price, sum, amount });
		} else {
			const amount = toFixed(candidateAmount, { precise });
			const sum = toFixed(Number(repositoryEntity.price) * Number(amount), { precise: 2 });
			const price = toFixed(getEntityDIYPrice(entity, 'services'));

			merged.set(entity.id, { ...repositoryEntity, price, sum, amount });
		}
	});

	return Array.from(merged.values()) as ServiceInternalModelState[];
}

function getMetalCuttingServiceForProduct({
	services,
	product,
}: {
	product: CatalogueProductWithAmount;
	services: Record<string, CatalogueService>;
}) {
	const allowedServices = [product.serviceCode];
	const payload = {
		service: services['Послуги порізка металу'],
		serviceName: 'Послуги порізка металу',
		allowedServices,
	};

	return transformCatalogueServiceToInternalServiceStateModel(payload);
}

interface ReplaceProductsConfig {
	candidates: CatalogueProductWithAmount[];
	repository: Record<string, ProductInternalModelState>;
	replaceId: string;
	targetRepository?: Record<string, ProductInternalModelState>;
}

function replaceProducts({ repository, candidates, replaceId, targetRepository }: ReplaceProductsConfig) {
	const repo = { ...repository };

	if (!candidates) return Array.from(Object.values(repo));

	if (repo[replaceId] && !targetRepository) {
		delete repo[replaceId];
	} else {
		const target = targetRepository[replaceId];
		const source = repo[replaceId];

		repo[replaceId] = {
			...source,
			amount: toFixed(Number(source?.amount || 0) - Number(target?.amount), { precise: source.unit?.precision }),
		};
	}

	candidates?.forEach((entity) => {
		const precise = entity.unit?.precision ?? 2;
		const price = toFixed(getEntityDIYPrice(entity, 'products'));
		const deviation = getDeviationFromPrice(price, entity);
		const sum = toFixed(Number(entity.amount) * Number(price), { precise: 2 });
		const amount = toFixed(entity.amount, { precise });

		repo[entity.id] = { ...entity, sum, amount, price, deviation } as ProductInternalModelState;
	});

	return Array.from(Object.values(repo));
}
