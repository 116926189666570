import { useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useClickOutside = <TElement extends HTMLElement>(callback: (e?: any) => void) => {
	const ref = useRef<TElement | null>(null);

	const handleClick = useCallback(
		(event: MouseEvent) => {
			const { target } = event;
			if (target instanceof Node && !ref.current?.contains(target)) {
				callback(event as unknown as React.MouseEvent<TElement>);
			}
		},
		[callback],
	);

	useEffect(() => {
		const ctrl = new AbortController();
		const { signal } = ctrl;

		document.addEventListener('click', handleClick, { signal });

		return () => {
			ctrl.abort();
		};
	}, [handleClick]);

	return ref;
};
