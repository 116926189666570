import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import { useProductConfigurationToolModal } from 'components/OrderCreatePageComponents/ProductConfigurationToolModal/useProductConfigurationToolModal';
import { FoldButton } from 'components/OrderCreatePageComponents/ProductFilter/FoldButton';
import { FilterSearchParamsKey } from 'components/OrderCreatePageComponents/ProductFilter/lib/const';
import TableActionsPanel from 'components/OrderCreatePageComponents/TableActionsPanel';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import SettingButton from 'components/Table/Filters/SettingsButton';
import SelectProductMobileTable from 'components/Table/MobileViews/SelectProductMobileTable';
import Pagination from 'components/Table/Pagination';
import Toggle from 'components/Toggle';
import { breakPoints, ROUTES_URLS } from 'const';
import { withOneStepOrderCart } from 'hoc/withOneStepOrderCart';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import { useReorderColumns } from 'hooks/useReorderColumns';
import { useSelectedRows } from 'hooks/useSelectedRows';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { useVisibilityColumns } from 'hooks/useVisibilityColumns';
import { CatalogueProduct, CatalogueProductWithAmount } from 'models/product/catalogue-product';
import React, { lazy, Suspense, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as FullScreenIcon } from 'static/images/expand.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { ReactComponent as SaveIcon } from 'static/images/save.svg';
import { useGetCatalogueProductsQuery } from 'store/reducers/orders/ordersSliceApi';
import { bakeOrderBreadcrumbs } from 'utils/orders';
import { prepareUrl } from 'utils/shared';

import { useOrderAddMode } from '../hooks/useOrderAddMode';
import { useOrderNotifications } from '../hooks/useOrderNotifications';
import { useOrderOperationMethods } from '../hooks/useOrderOperationMethods';
import PriceTypes from '../PriceTypes';
import { selectProductPriceButtonList, selectProductsColorMap } from '../PriceTypes/config';
import { useCatalogueProductsColumns } from './columns';
import styles from './styles.module.css';

const ProductConfigurationToolModal = lazy(() => import('components/OrderCreatePageComponents/ProductConfigurationToolModal'));
const EmptyFilterProductState = lazy(() => import('components/OrderCreatePageComponents/EmptyFilterProductState'));
const ProductSelectTopBar = lazy(() => import('components/OrderCreatePageComponents/ProductSelectTopBar'));
const ProductFilter = lazy(() => import('components/OrderCreatePageComponents/ProductFilter'));
const MobileHeader = lazy(() => import('components/Table/TableComponents/MobileHeader'));
const ColumnsSettingsMenu = lazy(() => import('components/ColumnsSettingsMenu'));
const BottomPanel = lazy(() => import('layouts/PageLayout/BottomPanel'));
const ActionButton = lazy(() => import('layouts/PageLayout/ActionButton'));

const OneStepCartProductConfigurationToolModal = withOneStepOrderCart(ProductConfigurationToolModal, { mode: 'creation' });

const SAVE_CONFIG_KEY = '/select-product';

const getValidParamsForSearchProducts = (search: URLSearchParams, { clientId }: { clientId: string | null | undefined }) => {
	const newParams = new URLSearchParams(search);
	newParams.delete(FilterSearchParamsKey.ReHydrateCat);

	if (clientId) {
		newParams.set(FilterSearchParamsKey.Client, clientId);
	} else {
		newParams.delete(FilterSearchParamsKey.Client);
	}

	return newParams.toString();
};

const OrderSelectProductModule: React.FC = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const cart = useOrderCart();
	const notify = useOrderNotifications();
	const router = useOrderRouter();
	const mode = useOrderAddMode();

	const { getValues, addEntityToOrder, replaceProductInOrder } = useOrderOperationMethods();

	const [baseCellSize, setBaseCellSize] = useState<number>(100);
	const visibilityInterface = useBoolean();
	const fullScreenInterface = useBoolean();
	const visibilityInterfaceToggle = useStopPropagationCallback(visibilityInterface.toggle);

	const { openModal, closeModal, isOpenModal } = useProductConfigurationToolModal({ mode: 'create' });

	const suborderIndex = Number(searchParams.get('from') ?? 0);
	const orderId = getValues(`suborders.${suborderIndex}.data.id`);
	const clientId = getValues(`suborders.${suborderIndex}.data.client`);
	const orderCreatedAt = getValues(`suborders.${suborderIndex}.data.createdAt`);
	const orderNumber = getValues(`suborders.${suborderIndex}.data.number`);
	const stockId = getValues(`suborders.${suborderIndex}.data.stock.value`);

	const { data, isFetching, isLoading } = useGetCatalogueProductsQuery(
		getValidParamsForSearchProducts(searchParams, { clientId: clientId?.value }),
	);
	const { data: products, page, pagesCount } = data || {};

	const crumbs: Breadcrumb[] = [
		{ label: 'Заявки', href: prepareUrl(ROUTES_URLS.ORDERS) },
		bakeOrderBreadcrumbs({ id: orderId, number: orderNumber, createdAt: orderCreatedAt }),
	];

	const onGoBack = () => {
		const url = -1;

		navigate(url);
	};

	const pickProduct = (product: CatalogueProductWithAmount, event?: React.MouseEvent<HTMLElement>) => {
		event?.stopPropagation();
		openModal(product);
	};

	const addProductToOrder = (product: CatalogueProduct) => {
		const candidates = [product] as CatalogueProductWithAmount[];
		const to = suborderIndex || 0;

		if (mode.isReplace) {
			replaceProductInOrder({ candidates, to, replaceId: mode.replaceItemId });
		} else {
			addEntityToOrder({ candidates, to, entityName: 'products' });
		}
		notify.successEntityAddedToOrder('products');
	};

	const navigateToOrderPreview = () => {
		if (searchParams.has('from')) {
			return router.toSpitOrder(suborderIndex);
		}

		router.toOrder({ isNew: !orderId });
	};

	useLayoutEffect(() => {
		const fromIndex = searchParams.get('from');
		const suborders = getValues('suborders');

		if (!suborders[fromIndex]) {
			searchParams.delete('from');
			setSearchParams(searchParams);
		}
	}, []);

	const handleToggleInputChange = () => {
		if (!stockId) return;

		const newUrlSearchParams = new URLSearchParams(searchParams);

		if (newUrlSearchParams.has(FilterSearchParamsKey.InStock)) {
			newUrlSearchParams.delete(FilterSearchParamsKey.InStock);
		} else {
			newUrlSearchParams.append(FilterSearchParamsKey.InStock, stockId);
		}

		if (newUrlSearchParams.has(FilterSearchParamsKey.Page)) {
			newUrlSearchParams.delete(FilterSearchParamsKey.Page);
		}

		setSearchParams(newUrlSearchParams);
	};

	useEffect(() => {
		return () => {
			mode.changeAddMode({ mode: 'regular' });
			cart.clearMaybeSelectedAll();
		};
	}, []);

	const contentContainerRef = useRef<HTMLDivElement>(null);

	const initialColumns = useCatalogueProductsColumns({ onModalOpen: openModal, onProductPick: pickProduct, baseCellSize, stockId });
	const { visibilityModel, setVisibilityModel, visibilityModelSaveConfigKey } = useVisibilityColumns({
		saveConfigKey: SAVE_CONFIG_KEY,
		initial: {
			lastPrice: false,
			enterPrice: false,
			minimalPrice: false,
			wholesalePrice: false,
		},
	});
	const { pinningModel, setPinningModel } = usePinnedColumns({ saveConfigKey: SAVE_CONFIG_KEY });
	const { rowSelectionModel, setRowSelectionModel } = useSelectedRows();

	const onFilterSideBarFoldUnfoldClick = () => {
		setBaseCellSize(baseCellSize <= 100 ? 160 : 100);
		contentContainerRef.current?.classList?.toggle(styles.foldUnfold);
	};

	const showSpinner = !products;
	const showEmptyState = !!products && products?.length === 0;
	const showTable = !!products && products?.length > 0;

	const RenderActionPanelSlot = useCallback(
		(props) => (
			<TableActionsPanel
				className={styles.tableActionPanel}
				{...props}
				replaceProductsInOrder={replaceProductInOrder}
				addEntityToOrder={addEntityToOrder}
				getValues={getValues}
			/>
		),
		[],
	);

	const RenderMobileTableRow = useCallback(
		({ row }) => <SelectProductMobileTable readonly row={row} onChangeQuantityClick={pickProduct} onAddProductClick={addProductToOrder} />,
		[],
	);

	const RenderMobileHeader = useCallback(
		({ headerGroups }) => (
			<Suspense fallback={<Spinner />}>
				<MobileHeader
					gridCustomLayout="44px auto"
					headerGroups={headerGroups}
					renderHeaders={['.', 'title']}
					justify="flex-start"
					className={styles.mobileHeader}
					bgColor="var(--gray-50)"
				/>
			</Suspense>
		),
		[],
	);

	const { columnOrderModel, onColumnOrderChange } = useReorderColumns(initialColumns, { saveConfigKey: SAVE_CONFIG_KEY });

	return (
		<>
			<div className={clsx(styles.mainWrapper)}>
				<div className={clsx('container', styles.container)}>
					<MediaQuery minWidth={breakPoints.MOBILE}>
						<BreadCrumbs crumbs={crumbs} onGoBack={onGoBack} className={clsx({ [styles.fullScreen]: fullScreenInterface.isOn })} />
					</MediaQuery>

					<ProductSelectTopBar
						onGoBackToOrder={navigateToOrderPreview}
						className={clsx(styles.topBar, { [styles.fullScreen]: fullScreenInterface.isOn })}
					/>

					<div ref={contentContainerRef} className={clsx(styles.contentContainer, 'hide-scroll-bar')}>
						<FoldButton onClick={onFilterSideBarFoldUnfoldClick} />

						<div className={clsx(styles.productFilterContainer, 'hide-scroll-bar')}>
							<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
								<PriceTypes
									suborderIndex={suborderIndex}
									hydrateStateKey={visibilityModelSaveConfigKey}
									onVisibilityModelChange={setVisibilityModel}
									className={clsx(styles.priceTypes, 'hide-scroll-bar')}
									buttonsList={selectProductPriceButtonList}
									buttonsListColorMap={selectProductsColorMap}
								>
									<Toggle hideIcon disabled={!stockId} label="Тільки в наявності" onChange={handleToggleInputChange} />
								</PriceTypes>

								<div className={styles.bottomPanelWrapper}>
									<BottomPanel className={styles.bottomPanel}>
										<ActionButton text="Супутні товари" icon={<PlusIcon />} background="var(--primary-600)" />
										<ActionButton text="Додати товар" icon={<PlusIcon />} />
										<ActionButton text="Зберегти" icon={<SaveIcon />} />
									</BottomPanel>
								</div>
							</MediaQuery>

							<ProductFilter />
						</div>

						<div className={clsx(styles.tableContainer)}>
							<MediaQuery minWidth={breakPoints.MOBILE}>
								<PriceTypes
									suborderIndex={suborderIndex}
									hydrateStateKey={visibilityModelSaveConfigKey}
									onVisibilityModelChange={setVisibilityModel}
									className={clsx(styles.priceTypes)}
									buttonsList={selectProductPriceButtonList}
									buttonsListColorMap={selectProductsColorMap}
								>
									<Toggle hideIcon disabled={!stockId} label="Тільки в наявності" onChange={handleToggleInputChange} />

									<SettingButton onClick={visibilityInterfaceToggle} />
									<Button variant="bordered" icon={<FullScreenIcon />} onClick={fullScreenInterface.toggle} />
								</PriceTypes>
							</MediaQuery>

							{showSpinner && <Spinner />}

							{showEmptyState && <EmptyFilterProductState>За вказаними фільтрами товарів не знайдено</EmptyFilterProductState>}

							{showTable && (
								<>
									<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
										<p className={clsx('text-md-semibold color-grey-900', styles.tableCaption)}>Товари:</p>
									</MediaQuery>

									<>
										<Table
											selectable
											columns={initialColumns}
											pageType="order"
											pinningModel={pinningModel}
											onPinningModelChange={setPinningModel}
											visibilityModel={visibilityModel}
											rowSelectionModel={rowSelectionModel}
											onRowSelectionModelChange={setRowSelectionModel}
											mobileViewComponent={RenderMobileTableRow}
											mobileHeader={RenderMobileHeader}
											allData={products}
											actionsPanelSlot={RenderActionPanelSlot}
											columnOrderModel={columnOrderModel}
											onColumnOrderModelChange={onColumnOrderChange}
										/>

										<Pagination pagesCount={pagesCount} page={page} />
									</>

									<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
										<div className="safe-area-bottom" />
									</MediaQuery>
								</>
							)}
						</div>

						<ColumnsSettingsMenu
							open={visibilityInterface.isOn}
							onClose={visibilityInterfaceToggle}
							columns={initialColumns}
							visibilityModel={visibilityModel}
							setColumns={setVisibilityModel}
						/>
					</div>
				</div>
			</div>

			<Suspense fallback={<Spinner />}>
				{isOpenModal && (
					<OneStepCartProductConfigurationToolModal
						getValues={getValues}
						addEntityToOrder={addEntityToOrder}
						open={isOpenModal}
						onClose={closeModal}
					/>
				)}
			</Suspense>

			{(isFetching || isLoading) && <Spinner />}
		</>
	);
};

export default OrderSelectProductModule;
