import clsx from 'clsx';
import OptionTag from 'components/AutocompleteInput/components/OptionTag';
import Button from 'components/Button';
import SpinnerV2 from 'components/Spinner-v2';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { ForwardedRef, forwardRef, MouseEvent, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReactComponent as CheckIcon } from 'static/images/check.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';
import { isFunction } from 'utils/type-guards';

import { useController } from './hooks/useController';
import styles from './styles.module.css';
import type { IProps, SelectOptionTagComponent } from './types';
import { getOptionLabel, matchesSelectedOption } from './utils';

const Select = (props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles: stylesPopper, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-start', strategy: 'absolute' });

	const {
		label,
		className,
		disabled = false,
		placeholder,
		dropdownHeader,
		dropdownHeaderClassName,
		leadingIcon: Icon,
		rotateChevronWhenExpand = false,
		renderDropdownItem,
		renderOption,
		name,
	} = props;

	const {
		closeDropdown,
		handleRefetch,
		toggleOpenDropdown,
		handleOptionSelect,
		options,
		selectedOption,
		selectedOptionDisplayValue,
		hasSelectedOption,
		activeIndex,
		isOpened,
		listRef,
		optionRef,
		isLoading,
		isError,
		shouldRenderOptions,
		onUnselect,
	} = useController(props);

	const rootRef = useClickOutside<HTMLDivElement>(closeDropdown);

	const isDisabled = disabled || isLoading;
	const shouldShowSpinner = isLoading && !selectedOption;
	const isMultipleChoice = Array.isArray(selectedOption) && !!props.multiple;
	const inputValue = isMultipleChoice ? '' : selectedOptionDisplayValue;
	const hasReachedLimit = 'limit' in props && isMultipleChoice && selectedOption.length === props.limit;
	const shouldRenderEmptyDropdownText = !props.loadAsyncOptions && options.length === 0 && !isError;

	const dropdownCbRef = useCallback((el: HTMLUListElement) => {
		listRef.current = el;
		setPopperElement(el);
	}, []);

	const inputElement = (
		<input
			readOnly
			id={name}
			name={name}
			role="select"
			className={clsx(styles.input, 'text-md-regular')}
			aria-label="Обреріть опцію"
			value={inputValue}
			placeholder={placeholder}
			autoComplete="new-password"
		/>
	);

	const dropdownList = (
		<ul
			data-select-dropdown
			ref={dropdownCbRef}
			className={clsx(styles.dropdown, { [styles.asyncLoading]: !shouldRenderOptions })}
			aria-label="Список доступних опцій"
			style={stylesPopper.popper}
			{...attributes.popper}
		>
			{!!dropdownHeader && (
				<li className={clsx(styles.dropdownHeader, dropdownHeaderClassName)}>
					<span>{dropdownHeader}</span>
					<button type="button" onClick={closeDropdown}>
						<XCloseIcon />
					</button>
				</li>
			)}

			{shouldRenderEmptyDropdownText && <li>Немає доступних опцій...</li>}

			{shouldRenderOptions &&
				options.map((option, index) => {
					const matchesCurrentOption = matchesSelectedOption(selectedOption, option);
					const isActive = index === activeIndex;
					const rawOption = options[index];
					const valueToDisplay = getOptionLabel(rawOption);
					const onClick = () => handleOptionSelect(index);

					if (renderDropdownItem) {
						return (
							<li key={index} ref={isActive ? optionRef : null}>
								{renderDropdownItem({ displayValue: valueToDisplay, isActive, onClick, option })}
							</li>
						);
					}

					const resolvedDisplayValue = !!renderOption ? renderOption(rawOption) : valueToDisplay;

					return (
						<li key={index} ref={isActive ? optionRef : null}>
							<button
								type="button"
								onClick={onClick}
								className={clsx(styles.option, 'color-primary-900', {
									[styles.active]: isActive,
								})}
							>
								<span>{resolvedDisplayValue}</span>
								{matchesCurrentOption && <CheckIcon className={styles.checkIcon} />}
							</button>
						</li>
					);
				})}

			{isLoading && (
				<li className={styles.spinner}>
					<SpinnerV2 />
				</li>
			)}

			{isError && (
				<li>
					<p className={styles.error}>Не вдалося завантажити опції...</p>
					<Button variant="small" text="Завантажити ще раз" onClick={handleRefetch} />
				</li>
			)}
		</ul>
	);

	useEffect(() => {
		if (referenceElement) {
			const portal = document.querySelector('#autocomplete-root') as HTMLDivElement;
			portal.style.setProperty('--parent-width', `${referenceElement.getBoundingClientRect().width}px`);

			return () => {
				portal.style.removeProperty('--parent-width');
			};
		}
	}, [referenceElement]);

	const cbRef = useCallback((el: HTMLInputElement) => {
		if (isFunction(ref)) {
			ref(el);
		} else if (ref) {
			ref.current = el;
		}
		setReferenceElement(el);
	}, []);

	return (
		<div data-select ref={rootRef} className={clsx(styles.selectWrapper, className)}>
			{!!label && <span className={clsx('text-sm-medium', styles.label)}>{label}</span>}

			<div
				className={clsx(
					styles.select,
					styles.placeholder,
					{
						[styles.rotateChevron]: rotateChevronWhenExpand && isOpened,
						[styles.withLeadingIcon]: !!Icon,
						[styles.withSelectedOptions]: hasSelectedOption,
					},
					className,
				)}
				tabIndex={0}
				ref={cbRef}
				role="listbox"
				aria-label="Select language"
				aria-expanded={isOpened}
				data-disabled={isDisabled}
				data-empty={!hasSelectedOption}
				onClick={toggleOpenDropdown}
			>
				{isMultipleChoice && (
					<div
						data-disabled={disabled}
						className={clsx(styles.multipleSelectedOptions, {
							[styles.input]: isMultipleChoice,
							[styles.placeholder]: isMultipleChoice,
							[styles.multiple]: selectedOption.length > 0,
							[styles.empty]: selectedOption.length === 0,
						})}
					>
						{!!Icon && <Icon className={styles.leadingIcon} />}

						{selectedOption.map((option, idx) => {
							const CustomOptionTag = 'optionTagComponent' in props && (props.optionTagComponent as SelectOptionTagComponent);

							const handleUnselect = (event: MouseEvent<HTMLButtonElement>) => {
								event.stopPropagation();
								onUnselect?.(option.label);
							};

							if (CustomOptionTag) {
								return <CustomOptionTag key={idx} option={option} onUnselect={handleUnselect} />;
							}

							return <OptionTag key={idx} title={option?.label} onUnselect={handleUnselect} hideCloseButton={disabled} />;
						})}

						{!hasReachedLimit && !disabled && inputElement}

						{shouldShowSpinner && <SpinnerV2 className={styles.spinner} />}
					</div>
				)}

				{!!Icon && !isMultipleChoice && <Icon className={styles.leadingIcon} />}
				{!isMultipleChoice && inputElement}
				{shouldShowSpinner && !isMultipleChoice && <SpinnerV2 className={styles.spinner} />}

				{isOpened && <>{createPortal(dropdownList, document.querySelector('#autocomplete-root'))}</>}
			</div>
		</div>
	);
};

export default forwardRef(Select);
