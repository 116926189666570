import clsx from 'clsx';
import Button from 'components/Button';
import Chip from 'components/Chip';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import RefreshActions from 'components/OrderCreatePageComponents/ProductSelectTopBar/RefreshActions';
import FullScreenGate from 'components/OrderPageComponents/(VisibilityGates)/FullScreenGate';
import Payable from 'components/OrderPageComponents/Payable';
import Tooltip from 'components/Tooltip';
import { breakPoints } from 'const';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { Can } from 'pages/Order/OrderAbility/provider';
import { calculateTotals, calculateTotalServicesCost } from 'pages/Order/OrderController/lib/utils';
import React from 'react';
import { Controller } from 'react-hook-form';
import MediaQuery, { useMediaQuery } from 'react-responsive';
// import LockIcon from 'static/images/lock.svg';
import { ReactComponent as SaveIcon } from 'static/images/checkWhite.svg';
import { formatNumberToUAH, kVolumeFormatter, kWeightFormatter } from 'utils/shared';
import { createTextPostfixer } from 'utils/text';

import { ProductInternalModelState, ServiceInternalModelState } from '../../../OrderController';
import styles from './styles.module.css';

interface StatsProps {
	isFullScreen: boolean;
	activeTab: number;
	products: ProductInternalModelState[];
	services: ServiceInternalModelState[];
	isReserved?: boolean;
	onSave: VoidCallback;
	onReserve?: VoidCallback;
	isPriceChangeModeOn?: boolean;
	className?: string;
}

const addNotAllowedPostfix = createTextPostfixer('(недоступно)');

export const Stats: React.FC<StatsProps> = ({ className, services, activeTab, products, isReserved, isFullScreen, isPriceChangeModeOn, onSave }) => {
	const isTablet = useMediaQuery({ minWidth: `${breakPoints.MOBILE}px`, maxWidth: `${breakPoints.LAPTOP_MD - 1}px` });
	const { control, getValues, setValue, getIsOrderDirty } = useOrderOperationMethods();
	const totals = calculateTotals(products);
	const totalServicesCost = calculateTotalServicesCost(services);

	const formattedWeight = `Вага - ${kWeightFormatter(totals?.weight || 0)}`;
	const formattedVolume = `Об’єм - ${kVolumeFormatter(totals?.volume || 0)}`;
	const formattedPrice = formatNumberToUAH((totals?.sum || 0) + totalServicesCost);
	const isSaveSplittingForbidden = isReserved || isPriceChangeModeOn || !getIsOrderDirty();

	return (
		<>
			<FullScreenGate isFullScreen={isFullScreen}>
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<div className={styles.refreshActionsWrapper}>
						<RefreshActions className={className} />
					</div>
				</MediaQuery>
			</FullScreenGate>

			<div className={clsx(styles.totalPriceWrapper, className)}>
				<FullScreenGate isFullScreen={isFullScreen}>
					<MediaQuery minWidth={breakPoints.MOBILE}>
						<div className={styles.chipsAndPriceWrapper}>
							<div className={styles.chipsWrapper}>
								<Chip title={formattedWeight} />
								<Chip title={formattedVolume} />
							</div>

							<Payable price={formattedPrice} className={styles.totalPrice} />
						</div>
					</MediaQuery>
					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<div className={styles.chipsWrapper}>
							<Chip title={formattedWeight} />
							<Chip title={formattedVolume} />
						</div>

						<Payable price={formattedPrice} className={styles.totalPrice} />
					</MediaQuery>

					<div className={styles.suborderControlsWrapper}>
						<>
							<Can passThrough I="change" an={`suborder.${activeTab}.isPaid`}>
								{(can) => (
									<Controller
										name={`suborders.${activeTab}.data.isPaid`}
										control={control}
										render={({ field }) => {
											const handleChange = () => {
												const isWithoutPayment = getValues(`suborders.${activeTab}.data.isWithoutPayment`);
												if (!field.value && isWithoutPayment) {
													setValue(`suborders.${0}.data.isWithoutPayment`, false);
												}
												field.onChange(!field.value);
											};

											return (
												<Checkbox
													label="З оплатою"
													checked={field.value}
													onChange={handleChange}
													disabled={!can}
													className={clsx(styles.checkboxField, { disabled: !can })}
												/>
											);
										}}
									/>
								)}
							</Can>
							<Can passThrough I="change" an={`suborder.${activeTab}.isWithoutPayment`}>
								{(can) => (
									<Controller
										name={`suborders.${activeTab}.data.isWithoutPayment`}
										control={control}
										render={({ field }) => {
											const handleChange = () => {
												const isPaid = getValues(`suborders.${activeTab}.data.isPaid`);
												if (!field.value && isPaid) {
													setValue(`suborders.${activeTab}.data.isPaid`, false);
												}
												field.onChange(!field.value);
											};

											return (
												<Checkbox
													label="Без оплати"
													checked={field.value}
													onChange={handleChange}
													disabled={!can}
													className={clsx(styles.checkboxField, { disabled: !can })}
												/>
											);
										}}
									/>
								)}
							</Can>
						</>

						<Tooltip text={addNotAllowedPostfix('Зберегти розбиття заявки', isSaveSplittingForbidden)}>
							<Button
								text={isTablet ? '' : 'Зберегти'}
								variant="rounded"
								onClick={onSave}
								background="var(--pink)"
								icon={<SaveIcon />}
								disabled={isSaveSplittingForbidden}
								className={clsx({ [styles.button]: isTablet, disabled: isSaveSplittingForbidden })}
							/>
						</Tooltip>
					</div>
				</FullScreenGate>
			</div>
		</>
	);
};
