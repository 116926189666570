import { SKIP_WAITING_MESSAGE } from 'lib/service-worker/messages.const';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { register as registerServiceWorker } from 'serviceWorkerRegistration';
import { notificationsActions } from 'store/reducers/notifications';
import { selectNewReleaseWorker } from 'store/reducers/notifications/selectors';

import { useAppDispatch } from './redux';
import { useBoolean } from './useBoolean';
import { useStopPropagationCallback } from './useStopPropagationCallback';

export const useServiceWorker = () => {
	const waitingWorker = useSelector(selectNewReleaseWorker);
	const dispatch = useAppDispatch();
	const showReload = useBoolean(false);
	const activating = useBoolean(false);

	const onUpdate = useCallback((registration: ServiceWorkerRegistration) => {
		showReload.open();
		dispatch(notificationsActions.setVersionRelease({ waitingWorker: registration.waiting }));
	}, []);

	const onRefresh = useCallback(() => {
		showReload.close();
		activating.close();

		window.location.reload();

		toast.success('Отримано нову версію додатку.');
	}, []);

	const onActivating = useCallback(() => {
		activating.open();
	}, []);

	const reloadPage = () => {
		waitingWorker?.postMessage({ type: SKIP_WAITING_MESSAGE });
	};

	const hideReload = useStopPropagationCallback(showReload.close);

	useEffect(() => {
		registerServiceWorker({
			onUpdate,
			onRefresh,
			onActivating,
		});
	}, [onRefresh, onUpdate, onActivating]);

	return { showReload, waitingWorker, isActivating: activating.isOn, reloadPage, hideReload };
};
