import { useAppSelector } from 'hooks/redux';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { orderWorkingModeActions } from 'store/reducers/orderWorkingMode';
import { selectOrderAddMode, selectReplaceProductId } from 'store/reducers/orderWorkingMode/selectors';
import type { ChangeAddModePayload } from 'store/reducers/orderWorkingMode/types';

export const useOrderAddMode = () => {
	const dispatch = useDispatch();
	const addMode = useAppSelector(selectOrderAddMode);
	const replaceItemId = useAppSelector(selectReplaceProductId);

	const changeAddMode = useCallback((payload: ChangeAddModePayload) => {
		return dispatch(orderWorkingModeActions.changeAddMode(payload));
	}, []);

	const modeState = useMemo(
		() => ({
			changeAddMode,
			replaceItemId,
			...addMode,
		}),
		[addMode, replaceItemId],
	);

	return modeState;
};
