import { SERVER_ENTITY_ID } from 'const';
import { ContractOptionSchema } from 'models/client';
import { EmployeeOptionSchema, OrganizationOptionSchema } from 'models/employee';
import { Order, Suborder } from 'models/order';
import { OrderProduct } from 'models/product';
import type { CatalogueService, OrderService } from 'models/service';
import { MoversService, PaintToningService, TransportationService } from 'models/service';
import { StockOptionSchema } from 'models/stock';
import { toFixed } from 'utils/shared';

import type { ProductInternalModelState, ServiceInternalModelState, SuborderTab } from './schema';
import { getServiceType, verifyIsMetalCuttingService } from './utils';

const delimiter = '@@';

export const encodeId = (id: string, ...salt: string[]): string => (salt.length > 0 ? `${id}${delimiter}${salt.join(delimiter)}` : id);
export const decodeId = (id: string): string => (id.includes(delimiter) ? id.split(delimiter)[0] : id);
export const hasSalt = (id: string, salt: string[]) => id.split(delimiter)?.some((saltSegment) => salt.some((s) => saltSegment === s));

export const transformCatalogueServiceToInternalServiceStateModel = ({
	service,
	serviceName,
	allowedServices,
}: {
	service: CatalogueService;
	serviceName?: string;
	allowedServices?: string[];
}): ServiceInternalModelState => {
	const isMetalCuttingService = verifyIsMetalCuttingService(service);

	if (isMetalCuttingService.success) {
		const { subCategories } = isMetalCuttingService.data;
		const [defaultService] = subCategories
			.map((subcat) => {
				const filteredServices = subcat.services?.filter((s) => allowedServices?.includes(s?.code));

				if (filteredServices && filteredServices.length > 0) {
					return {
						...subcat,
						services: filteredServices,
					};
				}

				return null;
			})
			.filter(Boolean);

		const defaultCategory = defaultService?.services?.[0];
		const price = defaultCategory?.prices.find((p) => p.typePrice.id === SERVER_ENTITY_ID.ServiceDYIPriceType)?.price;
		return {
			id: encodeId(service.id, defaultCategory.code),
			amount: '1',
			title: serviceName,
			code: defaultCategory?.code,
			price: String(price || '0.00'),
			sum: String(price || '0.00'),
			sumTax: 0,
			service: {
				id: defaultService?.id ?? '',
				services: defaultService?.services ?? [],
				serviceType: serviceName,
				title: defaultService?.title ?? '',
			},
			category: {
				id: defaultCategory.id ?? '',
				title: defaultCategory.title ?? '',
				prices: defaultCategory.prices?.map((entity) => ({ ...entity, price: String(entity?.price) })) ?? [],
			},
			canEditManuallyPrice: !SERVER_ENTITY_ID.ServicesNotAllowedManuallyEditPrice.includes(service.id),
			isMetalCuttingService: true,
		};
	}

	const otherService = service as TransportationService | MoversService | PaintToningService;
	const price = otherService.prices?.find((p) => p?.typePrice?.id === SERVER_ENTITY_ID.ServiceDYIPriceType)?.price;

	return {
		id: encodeId(service.id),
		amount: '1',
		code: otherService.code,
		price: String(price || '0.00'),
		title: otherService.title,
		sum: String(price || '0.00'),
		sumTax: 0,
		category: null,
		service: null,
		canEditManuallyPrice: !SERVER_ENTITY_ID.ServicesNotAllowedManuallyEditPrice.includes(service.id),
		isMetalCuttingService: false,
	};
};

export const transformOrderServiceToInternalServiceStateModel = (
	orderService: OrderService,
	services: Record<string, CatalogueService>,
): ServiceInternalModelState => {
	const { isMetalCuttingService } = getServiceType(orderService);

	if (!isMetalCuttingService) {
		return {
			id: encodeId(orderService.id),
			amount: String(orderService.amount),
			code: orderService.code,
			price: String(orderService.price),
			sum: String(orderService.sum),
			sumTax: String(orderService.sumTax),
			title: orderService.title,
			category: null,
			service: null,
			canEditManuallyPrice: !SERVER_ENTITY_ID.ServicesNotAllowedManuallyEditPrice.includes(orderService.id),
			isMetalCuttingService: false,
		};
	}
	const accessKey = 'Послуги порізка металу';
	const service = transformCatalogueServiceToInternalServiceStateModel({
		service: services[accessKey],
		serviceName: accessKey,
		allowedServices: [orderService.code],
	});

	return service;
};

export const transformOrderProductToProductInternalModelState = (product: OrderProduct): ProductInternalModelState => {
	const precise = product.unit?.precision;
	const price = toFixed(product.price, { precise: 2, strictPrecision: true });
	const amount = toFixed(product.amount, { precise, strictPrecision: true });
	const sum = toFixed(product.sum, { precise: 2, strictPrecision: true });

	return {
		...product,
		amount,
		price,
		sum,
		rollbackPrice: '0',
	};
};

export const getOrderControllerEntityRowId = (row: ProductInternalModelState | ServiceInternalModelState) => {
	return row.id;
};

interface TransformSuborderToSuborderTabPayload {
	suborder: Suborder;
	services: Record<string, CatalogueService>;
	index: number;
}

export const transformSuborderToSuborderTab = ({ suborder, services, index }: TransformSuborderToSuborderTabPayload): SuborderTab => {
	const contract = ContractOptionSchema.safeParse(suborder.contract);
	const organization = OrganizationOptionSchema.safeParse(suborder.organization);
	const stock = StockOptionSchema.safeParse(suborder.stock);
	const responsible = EmployeeOptionSchema.safeParse(suborder.responsible);

	const servicesInternalModel = suborder.services.reduce((acc, service) => {
		const model = transformOrderServiceToInternalServiceStateModel(service, services);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ServiceInternalModelState>;

	const productsInternalModel = suborder.products.reduce((acc, product) => {
		const model = transformOrderProductToProductInternalModelState(product);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ProductInternalModelState>;

	return {
		tabName: `Заявка ${index + 1}`,
		data: {
			id: suborder.id,
			client: {
				// @ts-ignore
				label: suborder.client.name || suborder.client.organizationName,
				value: suborder.client.id,
			},
			contract: contract.data,
			organization: organization.data,
			stock: stock.data,
			responsible: responsible.data,
			index: index + 1,
			isPaid: Boolean(suborder.isPaid),
			isReserved: Boolean(suborder.isReserved),
			isWithoutPayment: Boolean(suborder.isWithoutPayment),
			products: productsInternalModel,
			services: servicesInternalModel,
			sum: suborder.sum,
			volume: suborder.volume,
			weight: suborder.weight,
			createdAt: suborder.createdAt,
			note: suborder.note,
			number: suborder.number,
			status: suborder.status,
			isSaved: true,
			parentId: suborder.parentId,
			realizations: suborder.realizations,
			payments: suborder.payments,
			returns: suborder.returns,
			date: suborder.date,
			lockedBy: suborder.lockedBy || null,
			lockedUntil: suborder.lockedUntil || null,
			access: suborder.access || null,
		},
	};
};

interface TransformOrderToRootOrderTabPayload {
	order: Order;
	services: Record<string, CatalogueService>;
}

export const transformOrderToRootOrderTab = ({ order, services }: TransformOrderToRootOrderTabPayload): SuborderTab => {
	const servicesInternalModel = order.services?.reduce((acc, service) => {
		const model = transformOrderServiceToInternalServiceStateModel(service, services);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ServiceInternalModelState>;

	const productsInternalModel = order.products.reduce((acc, product) => {
		const model = transformOrderProductToProductInternalModelState(product);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ProductInternalModelState>;

	return {
		tabName: 'Основна заявка',
		data: {
			id: order.id,
			client: {
				// @ts-ignore
				label: order.client.organizationName || order.client.name,
				value: order.client.id,
			},
			contract: {
				label: order.contract.title,
				value: order.contract.id,
			},
			organization: {
				label: order.organization.title,
				value: String(order.organization.id),
			},
			stock: {
				label: order.stock.title,
				value: order.stock.id,
			},
			responsible: {
				label: order.responsible.name,
				value: order.responsible['1c_uuid'],
			},
			index: 0,
			isPaid: Boolean(order.isPaid),
			isReserved: Boolean(order.isReserved),
			isWithoutPayment: Boolean(order.isWithoutPayment),
			products: productsInternalModel,
			services: servicesInternalModel,
			sum: order.sum,
			volume: order.volume,
			weight: order.weight,
			createdAt: order.createdAt,
			note: order.note,
			number: order.number,
			status: order.status,
			isSaved: true,
			realizations: order.realizations ?? [],
			returns: order.returns ?? [],
			payments: order.payments ?? [],
			date: order.date,
			lockedBy: order.lockedBy || null,
			lockedUntil: order.lockedUntil || null,
			access: order.access || null,
			parentId: order.parentId ?? null,
		},
	};
};
