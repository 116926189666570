export const replaceComaWithDot = (e: React.KeyboardEvent<HTMLInputElement>) => {
	const { key, currentTarget } = e;

	const isDigit = /\d/.test(key);
	const isDot = key === '.' && true;
	const isComma = key === ',' && true;
	const isBackspaceOrDelete = key === 'Backspace' || key === 'Delete';
	const isArrowKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(key);

	if (isComma) {
		e.preventDefault(); // Prevent default comma input
		if (!currentTarget.value.includes('.')) {
			const { selectionStart, selectionEnd } = currentTarget;
			const valueWithDot = currentTarget.value.slice(0, selectionStart!) + '.' + currentTarget.value.slice(selectionEnd!);
			currentTarget.value = valueWithDot;

			setTimeout(() => {
				currentTarget.setSelectionRange(selectionStart! + 1, selectionStart! + 1);
			}, 0);
		}
		return;
	}

	if (isDigit || isBackspaceOrDelete || isArrowKey) {
		return;
	}

	if (isDot) {
		if (currentTarget.value.includes('.')) {
			e.preventDefault();
		}
		return;
	}

	e.preventDefault();
};

export const formatInputValue =
	({ isInt }: { isInt: boolean }) =>
	(value: number | string) => {
		if (isInt) {
			return String(value).replace(/\D/g, '');
		}
		return value;
	};

export const replaceNegativeComaWithDot = (e: React.KeyboardEvent<HTMLInputElement>) => {
	const { key, currentTarget } = e;
	const value = currentTarget.value;

	const isDigit = /\d/.test(key);
	const isDot = key === '.' && true;
	const isComma = key === ',' && true;
	const isBackspaceOrDelete = key === 'Backspace' || key === 'Delete';
	const isArrowKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(key);
	const isMinus = key === '-';

	if (isComma) {
		e.preventDefault();
		if (!value.includes('.')) {
			const { selectionStart, selectionEnd } = currentTarget;
			const valueWithDot = value.slice(0, selectionStart!) + '.' + value.slice(selectionEnd!);
			currentTarget.value = valueWithDot;

			setTimeout(() => {
				currentTarget.setSelectionRange(selectionStart! + 1, selectionStart! + 1);
			}, 0);
		}
		return;
	}

	if (isDigit || isBackspaceOrDelete || isArrowKey) {
		return;
	}

	if (isDot) {
		if (value.includes('.')) {
			e.preventDefault();
		}
		return;
	}

	if (isMinus) {
		if (value === '' || value.startsWith('-')) {
			return;
		}
		e.preventDefault();
		return;
	}

	e.preventDefault();
};

export const padEndZero = (value: string | number, count: number) => {
	if (count === 0) return String(value);

	const stringValue = String(value) + '.';
	const resolvedCount = stringValue.length + count;

	return stringValue.padEnd(resolvedCount, '0');
};
