import type { ManagerBonusAction } from './types';

const dialogue: Record<ManagerBonusAction, { title: string; subtitle: string }> = {
	'apply-bonus': {
		title: 'Ви впевнені, що бажаєте застосувати "Бонус менеджера" до обраних товарів?',
		subtitle: 'В усіх підзаявках, де є цей товар ціну також буде змінено',
	},
	'discard-bonus': {
		title: 'Ви впевнені, що бажаєте прибрати "Бонус менеджера" до обраних товарів?',
		subtitle: 'В усіх підзаявках, де є цей товар ціну також буде змінено',
	},
};

export const getDialogueText = (action: ManagerBonusAction) => dialogue[action];
