import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { NotificationsState } from './types';

const initialState = {
	versionRelease: null,
} as NotificationsState;

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setVersionRelease: (state, action: PayloadAction<{ waitingWorker: ServiceWorker }>) => {
			state.versionRelease = action.payload.waitingWorker;
		},
	},
});

export default notificationsSlice.reducer;
export const notificationsActions = notificationsSlice.actions;
