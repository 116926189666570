import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import type { NewReleaseNotification } from './types';

const slice = (state: RootState) => state.notifications;

export const selectNewReleaseWorker = createDraftSafeSelector(slice, (state) => state.versionRelease);
export const selectNotificationsList = createDraftSafeSelector(slice, (state) =>
	state.versionRelease ? [{ data: state.versionRelease, type: 'new-release' } as NewReleaseNotification] : [],
);
