import clsx from 'clsx';
import Button from 'components/Button';
import ColumnsSettingsMenu from 'components/ColumnsSettingsMenu';
import SettingButton from 'components/Table/Filters/SettingsButton';
import type { ColumnDefinition, VisibilityModel } from 'components/Table/lib/table/types/table';
import Tooltip from 'components/Tooltip';
import { breakPoints } from 'const';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React from 'react';
import MediaQuery from 'react-responsive';
import { ReactComponent as DollarIcon } from 'static/images/dollar.svg';
import { ReactComponent as FullScreenIcon } from 'static/images/expand.svg';
import { ReactComponent as MinusIcon } from 'static/images/minus.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus-blue.svg';
import { ReactComponent as TrashIcon } from 'static/images/trashOrders.svg';
import { createTextPostfixer } from 'utils/text';

import ManagerBonusButton from '../components/ManagerBonus';
import { Can, useOrderAbility } from '../OrderAbility/provider';
import PriceTypes from '../PriceTypes';
import { orderColorMap, orderPriceButtonList } from '../PriceTypes/config';
import PriceTypesSelect from '../PriceTypes/PriceTypesSelect';
import { getIsOnSplitViewPage } from '../utils';
import styles from './styles.module.css';

interface OrderEntitiesControlPanelProps<TData> {
	onFullScreenChange: VoidCallback;
	onVisibilityModelChange: VoidCallback;
	onDelete: VoidCallback;
	hasSelectedEntities: boolean;
	visibilityModel: VisibilityModel;
	entityColumns: ColumnDefinition<TData>;
	isFullScreen: boolean;
	isChangePriceAvailable: boolean;
	children?: React.ReactNode;
	isChangePriceMode?: boolean;
	onChangePriceModeToggle: VoidCallback;
	visibilitySaveConfigKey: string;
	isPriceTypeColumnInsertionDisabled?: boolean;
	suborderIndex: number;
	onAddBlankProduct: VoidCallback;
	hasBlankProduct?: boolean;
	isAddBlankProductDisabled?: boolean;
	className?: string;
	selectionModel?: AnyArg;
	isManagerBonusAvailable?: boolean;
}

const addNotAllowedPostfix = createTextPostfixer('(недоступно)');
const addNotSelectedPostfix = createTextPostfixer('(нічого не обрано)', { onNextLine: true });

const OrderEntitiesControlPanel = <TData,>({
	onFullScreenChange,
	onVisibilityModelChange,
	onDelete,
	hasSelectedEntities,
	visibilityModel,
	entityColumns,
	isChangePriceAvailable,
	children,
	onChangePriceModeToggle,
	isChangePriceMode,
	visibilitySaveConfigKey,
	isPriceTypeColumnInsertionDisabled,
	suborderIndex,
	onAddBlankProduct,
	hasBlankProduct,
	isAddBlankProductDisabled,
	className,
	selectionModel,
	isManagerBonusAvailable,
}: OrderEntitiesControlPanelProps<TData>) => {
	const ability = useOrderAbility();

	const visibilityModelInterface = useBoolean();
	const onEntityDelete = useStopPropagationCallback<HTMLButtonElement>(onDelete);
	const toggleVisibilityModelInterface = useStopPropagationCallback<HTMLButtonElement>(visibilityModelInterface.toggle);
	const handlePriceChangeToggle = useStopPropagationCallback(onChangePriceModeToggle);

	const entityKey = getIsOnSplitViewPage() ? 'suborder' : 'order';
	const canChangeService = ability.can('change', `${entityKey}.${suborderIndex}.services.table`);
	const canChangeProduct = ability.can('change', `${entityKey}.${suborderIndex}.products.table`);
	const canChangeOrder = canChangeProduct && canChangeService;

	const isDeleteButtonDisabled = !canChangeOrder || !hasSelectedEntities || isChangePriceMode;
	const isPriceModeButtonDisabled = !canChangeOrder || !isChangePriceAvailable;
	const isAddBlankProductButtonDisabled = !canChangeOrder || !!isAddBlankProductDisabled;
	const isBonusButtonDisabled = !canChangeOrder || !hasSelectedEntities || isChangePriceMode || !isManagerBonusAvailable;

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				{children}

				<div className={clsx(styles.configButtonsWrapper, className)}>
					<Can passThrough I="add" an={`order.${suborderIndex}.products`}>
						{(can) => {
							return (
								<Tooltip
									text={addNotAllowedPostfix(
										hasBlankProduct
											? 'Прибрати порожній рядок з таблиці'
											: 'Додати порожній рядок до таблиці. За допомогою рядка можна шукати товари',
										!can || isAddBlankProductButtonDisabled,
									)}
								>
									<Button
										onClick={onAddBlankProduct}
										icon={hasBlankProduct ? <MinusIcon /> : <PlusIcon />}
										variant="bordered"
										className={clsx(styles.blankProductButton, { [styles.disabled]: !can || isAddBlankProductButtonDisabled })}
										disabled={!can || isAddBlankProductButtonDisabled}
									/>
								</Tooltip>
							);
						}}
					</Can>

					<Tooltip text={addNotSelectedPostfix('Видалити товари або послуги', isDeleteButtonDisabled)}>
						<Button
							onClick={onEntityDelete}
							icon={<TrashIcon />}
							variant="bordered"
							className={clsx({ [styles.disabled]: isDeleteButtonDisabled })}
							disabled={isDeleteButtonDisabled}
						/>
					</Tooltip>

					<Tooltip text={addNotSelectedPostfix('Змінити тип ціни товарів', isPriceModeButtonDisabled)}>
						<Button
							icon={<DollarIcon />}
							variant="bordered"
							onClick={handlePriceChangeToggle}
							className={clsx({ [styles.disabled]: isPriceModeButtonDisabled })}
							disabled={isPriceModeButtonDisabled}
						/>
					</Tooltip>

					<SettingButton onClick={toggleVisibilityModelInterface} tooltipText={'Налаштування відображення колонок таблиці'}>
						<ColumnsSettingsMenu
							visibilityModel={visibilityModel}
							open={visibilityModelInterface.isOn}
							onClose={toggleVisibilityModelInterface}
							columns={entityColumns}
							setColumns={onVisibilityModelChange}
						/>
					</SettingButton>

					<Tooltip text={'Сховати або показати верхню частину екрану'}>
						<Button onClick={onFullScreenChange} icon={<FullScreenIcon />} variant="bordered" />
					</Tooltip>

					<ManagerBonusButton
						suborderIndex={suborderIndex}
						selectionModel={selectionModel}
						disabled={isBonusButtonDisabled}
						className={clsx({ [styles.disabled]: isBonusButtonDisabled })}
					/>

					<MediaQuery minWidth={'768px'} maxWidth={'1494px'}>
						<PriceTypesSelect
							suborderIndex={suborderIndex}
							hydrateStateKey={visibilitySaveConfigKey}
							justify="flex-end"
							buttonsList={orderPriceButtonList}
							buttonsListColorMap={orderColorMap}
							onVisibilityModelChange={onVisibilityModelChange}
							className={styles.priceTypesWrapper}
							disableAll={!canChangeOrder || isPriceTypeColumnInsertionDisabled}
						/>
					</MediaQuery>
				</div>

				<MediaQuery minWidth={'1495px'}>
					<PriceTypes
						suborderIndex={suborderIndex}
						hydrateStateKey={visibilitySaveConfigKey}
						justify="flex-end"
						onVisibilityModelChange={onVisibilityModelChange}
						className={styles.priceTypesWrapper}
						disableAll={!canChangeOrder || isPriceTypeColumnInsertionDisabled}
						buttonsList={orderPriceButtonList}
						buttonsListColorMap={orderColorMap}
					/>
				</MediaQuery>
			</MediaQuery>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<PriceTypes
					suborderIndex={suborderIndex}
					hydrateStateKey={visibilitySaveConfigKey}
					justify="flex-start"
					onVisibilityModelChange={onVisibilityModelChange}
					className={styles.priceTypesWrapper}
					disableAll={!canChangeOrder || isPriceTypeColumnInsertionDisabled}
					buttonsList={orderPriceButtonList}
					buttonsListColorMap={orderColorMap}
				/>
			</MediaQuery>
		</>
	);
};

export default OrderEntitiesControlPanel;
