import Button from 'components/Button';
import Modal from 'components/Modal';
import type { IProps as ModalProps } from 'components/Modal/types';
import AvailabilityChecker from 'components/OrderCreatePageComponents/ProductConfigurationTool/AvailabilityChecker';
import ConfigurationToolHeader from 'components/OrderCreatePageComponents/ProductConfigurationTool/ConfigurationToolHeader';
import QuantitySelector from 'components/OrderCreatePageComponents/ProductConfigurationTool/QuantitySelector';
import type { ProductUnit } from 'models/product';
import { useOrderNotifications } from 'pages/Order/hooks/useOrderNotifications';
import type { ProductInternalModelState } from 'pages/Order/OrderController';
import React, { useState } from 'react';
import { ReactComponent as CircleCheckIcon } from 'static/images/check-rounded.svg';
import { formatInputValue } from 'utils/inputs';
import { calculateTotalPrice, roundNumber, toFixed } from 'utils/shared';

import styles from './styles.module.css';

type TransferProductDetailsModalProps = Pick<ModalProps, 'onClose'> & {
	entity: ProductInternalModelState;
	onSubmit: (product: ProductInternalModelState) => void;
	stock: { value: string; label: string };
};

const TransferProductDetailsModal: React.FC<TransferProductDetailsModalProps> = ({ onClose, entity, stock, onSubmit }) => {
	const notify = useOrderNotifications();
	const { brand, title, amount, unit, price, freeLeftovers, reserves } = entity ?? ({} as ProductInternalModelState);

	const [state, setState] = useState({
		quantity: amount,
		unit,
	});
	const quantity = state.quantity;

	const { precision } = unit ?? {};
	const isInt = precision === 0;
	const formatValue = formatInputValue({ isInt });

	const handleMeasureUnitChange = (unitProp: ProductUnit) => {
		setState({ ...state, unit: unitProp });
	};
	const handleQuantityChange = (newValue: number) => {
		setState({ ...state, quantity: String(formatValue(newValue)) });
	};

	const handleAction = () => {
		if (Number(quantity) === 0) {
			return notify.errorEntityInvalidAmount();
		}
		onSubmit({ ...entity, amount: toFixed(formatValue(quantity), { precise: precision }), unit });
	};

	// @ts-ignore
	const totalPrice = roundNumber(calculateTotalPrice({ price: Number(price), amount: quantity }), 2);

	return (
		<Modal open stackable onClose={onClose} maxWidth="90vw" maxHeight="90vh" minHeight="500px" className={styles.modal}>
			<div className={styles.wrapper}>
				<ConfigurationToolHeader title={brand.title} subtitle={title} onXCloseClick={onClose} />

				<div className={styles.contentWrapper}>
					<QuantitySelector
						totalPrice={totalPrice}
						quantity={quantity}
						unit={state.unit}
						onMeasureUnitChange={handleMeasureUnitChange}
						onQuantityChange={handleQuantityChange}
						className={styles.quantitySelector}
					/>
					<div className={styles.actionButtonWrapper}>
						<Button
							onClick={handleAction}
							variant="smallRounded"
							text="Вибрати"
							icon={<CircleCheckIcon />}
							className={styles.actionButton}
						/>
					</div>

					<AvailabilityChecker
						freeLeftovers={freeLeftovers}
						reserves={reserves}
						stockId={stock?.value ?? ''}
						stockName={stock?.label ?? ''}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default TransferProductDetailsModal;
