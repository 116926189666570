import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

const rootState = (state: RootState) => state;
const featureSlice = createDraftSafeSelector(rootState, (state) => state.orderWorkingMode);

export const selectOrderAddMode = createDraftSafeSelector(featureSlice, (state) => {
	const mode = state.addMode;

	return {
		mode,
		isRegular: mode === 'regular',
		isReplace: mode === 'replace',
	};
});

export const selectReplaceProductId = createDraftSafeSelector(featureSlice, (state) => {
	return state.replaceItemId ?? '';
});
