import { AbilityBuilder, AbilityClass, PureAbility } from '@casl/ability';
import { isString } from 'utils/type-guards';

import { SuborderTab } from '../OrderController';

interface UseDefineAbilityPayload {
	suborders: SuborderTab[];
	isOfflineMode?: boolean;
	isCheckSum?: boolean;
}

export const useDefineOrderAbility = ({ suborders, isOfflineMode, isCheckSum }: UseDefineAbilityPayload) => {
	const { can, cannot, build } = new AbilityBuilder(PureAbility as AbilityClass<AnyArg>);

	suborders.forEach((suborder, index) => {
		if (suborder.data.access === 'readonly' || isCheckSum) {
			cannot('manage', 'all');
			can('click', 'order.structure');
			return;
		}

		if (suborder.data.status === 'reserve') {
			can('change', `order.${index}.client`);
			can('change', `order.${index}.organization`);
			can('change', `order.${index}.contract`);
			can('change', `order.${index}.stock`);
			can('change', `order.${index}.responsible`);
			can('change', `order.${index}.isPaid`);
			can('change', `order.${index}.isWithoutPayment`);
			can('add', `order.${index}.products`);
			can('delete', `order.${index}.products`);
			can('change', `order.${index}.products.table`);
			can('add', `order.${index}.services`);
			can('delete', `order.${index}.services`);
			can('change', `order.${index}.services.table`);
			can('change', `order.${index}.columns`);
			can('change', `order.${index}.price`);
			can('split', `order.${index}`);
			can('click', `order.${index}.business-offer`);
			can('click', `order.${index}.reserve`);
			cannot('click', `order.${index}.save`);
			can('click', `order.${index}.request-logistic`);
			can('click', `order.${index}.drawer`);
			can('click', 'order.structure');
			can('split', 'order');
			cannot('click', `order.${0}.update-date`);

			if (isOfflineMode) {
				cannot('click', `order.${index}.reserve`);
				cannot('split', `order.${index}`);
				cannot('split', 'order');
			}

			cannot('change', `order.${index}.responsible`);
		} else if (suborder.data.status === 'closed' || suborder.data.status === 'partially_shipped' || suborder.data.status === 'shipped') {
			cannot('manage', 'all');
			can('click', 'order.structure');
		} else {
			can('change', `order.${index}.client`);
			can('change', `order.${index}.organization`);
			can('change', `order.${index}.contract`);
			can('change', `order.${index}.stock`);
			can('change', `order.${index}.responsible`);
			can('change', `order.${index}.isPaid`);
			can('change', `order.${index}.isWithoutPayment`);
			can('change', `suborder.${index}.isPaid`);
			can('change', `suborder.${index}.isWithoutPayment`);
			can('add', `order.${index}.products`);
			can('delete', `order.${index}.products`);
			can('change', `order.${index}.products.table`);
			can('change', `suborder.${index}.products.table`);
			can('add', `order.${index}.services`);
			can('delete', `order.${index}.services`);
			can('change', `order.${index}.services.table`);
			can('change', `suborder.${index}.services.table`);
			can('change', `order.${index}.columns`);
			can('change', `order.${index}.price`);
			can('split', `order.${index}`);
			can('click', `order.${index}.business-offer`);
			can('click', `order.${index}.reserve`);
			can('click', `order.${index}.save`);
			can('click', `order.${index}.request-logistic`);
			can('click', `order.${index}.drawer`);
			can('click', 'order.structure');
			can('split', 'order');
			can('click', `order.${0}.update-date`);
			can('select', `suborder.${index}.products`);
			can('select', `suborder.${index}.services`);
			can('select', `order.${index}.products`);
			can('select', `order.${index}.services`);

			const wasRootOrderSplit = suborders?.slice(1).some((s) => s.data.isSaved);
			const isRootOrder = index === 0;
			const isSuborder = index !== 0;
			const wasSuborderSavedOnServer = suborder.data.isSaved;
			const isViewingSuborderAsRootOrder = index === 0 && isString(suborder.data.parentId);
			const isNewOrder = index === 0 && (!suborder.data.id || !suborder.data.isSaved);

			if (wasSuborderSavedOnServer) {
				cannot('delete', `suborder.${index}`);
			}

			// once root order was split on server - we cannot add or delete entities from it
			if (isRootOrder && wasRootOrderSplit) {
				// permissions for order on main view
				cannot('add', `order.${0}.products`);
				cannot('delete', `order.${0}.products`);
				cannot('add', `order.${0}.services`);
				cannot('delete', `order.${0}.services`);
				cannot('change', `order.${0}.price`);
				cannot('change', `order.${0}.products.table`);
				cannot('change', `order.${0}.services.table`);
				cannot('change', `order.${0}.client`);
				cannot('change', `order.${0}.organization`);
				cannot('change', `order.${0}.contract`);
				cannot('change', `order.${0}.stock`);
				cannot('change', `order.${0}.responsible`);
				cannot('select', `order.${0}.products`);
				cannot('select', `order.${0}.services`);
				cannot('click', `order.${0}.reserve`);

				// permissions for root order on split order view
				cannot('add', `suborder.${0}.products`);
				cannot('delete', `suborder.${0}.products`);
				cannot('delete', `suborder.${0}.products`);
				cannot('add', `suborder.${0}.services`);
				cannot('delete', `suborder.${0}.services`);
				cannot('change', `suborder.${0}.price`);
				cannot('change', `suborder.${0}.products.table`);
				cannot('change', `suborder.${0}.services.table`);
				cannot('change', `suborder.${0}.client`);
				cannot('change', `suborder.${0}.organization`);
				cannot('change', `suborder.${0}.contract`);
				cannot('change', `suborder.${0}.stock`);
				cannot('change', `suborder.${0}.responsible`);
				can('select', `suborder.${0}.products`);
				can('select', `suborder.${0}.services`);
				cannot('change', `order.${0}.isPaid`);
				cannot('change', `order.${0}.isWithoutPayment`);
				cannot('change', `suborder.${0}.isPaid`);
				cannot('change', `suborder.${0}.isWithoutPayment`);
			}

			if (isRootOrder && !wasRootOrderSplit) {
				// permissions for root order on split order view
				can('add', `suborder.${0}.products`);
				can('delete', `suborder.${0}.products`);
				can('add', `suborder.${0}.services`);
				can('delete', `suborder.${0}.services`);
				can('change', `suborder.${0}.price`);
				can('change', `suborder.${0}.products.table`);
				can('change', `suborder.${0}.services.table`);
				can('change', `suborder.${0}.client`);
				can('change', `suborder.${0}.organization`);
				can('change', `suborder.${0}.contract`);
				can('change', `suborder.${0}.stock`);
				can('change', `suborder.${0}.responsible`);
				can('select', `suborder.${0}.products`);
				can('select', `suborder.${0}.services`);
			}

			if (isSuborder && wasRootOrderSplit) {
				can('add', `suborder.${index}.products`);
				can('delete', `suborder.${index}.products`);
				can('add', `suborder.${index}.services`);
				can('delete', `suborder.${index}.services`);
				can('change', `suborder.${index}.price`);
				can('change', `suborder.${index}.products.table`);
				can('change', `suborder.${index}.services.table`);
				can('change', `suborder.${index}.client`);
				can('change', `suborder.${index}.organization`);
				can('change', `suborder.${index}.contract`);
				can('change', `suborder.${index}.stock`);
				can('change', `suborder.${index}.responsible`);
				can('change', `suborder.${index}.responsible`);
				can('select', `suborder.${index}.products`);
				can('select', `suborder.${index}.services`);
				can('change', `order.${index}.isPaid`);
				can('change', `order.${index}.isWithoutPayment`);
				can('change', `suborder.${index}.isPaid`);
				can('change', `suborder.${index}.isWithoutPayment`);
			}

			// once we preview suborder as main order - than we cannot split such order
			if (isViewingSuborderAsRootOrder) {
				cannot('split', 'order');
				cannot('split', `order.${0}`);
				can('add', `order.${0}.products`);
				can('delete', `order.${0}.products`);
				can('add', `order.${0}.services`);
				can('delete', `order.${0}.services`);
				can('change', `order.${0}.price`);
				can('change', `order.${0}.products.table`);
				can('change', `order.${0}.services.table`);
			}

			if (isNewOrder) {
				cannot('click', 'order.structure');
				cannot('split', 'order');
				cannot('click', `order.${0}.reserve`);
				can('click', 'order.business-offer-safe');
				cannot('click', `order.${index}.business-offer`);
				cannot('click', `order.${0}.update-date`);
			}

			if (isOfflineMode) {
				cannot('click', `order.${index}.reserve`);
				cannot('split', `order.${index}`);
				cannot('split', 'order');
				cannot('click', `order.${0}.business-offer`);
				cannot('click', `order.${0}.update-date`);
			}

			// so far for any case we forbid to change manager who creates order
			cannot('change', `order.${index}.responsible`);
		}
	});

	return build();
};
