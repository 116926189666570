import clsx from 'clsx';
import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import Button from 'components/Button';
import CalculatorButton from 'components/CalculatorButton';
import DependantField from 'components/FormComponents/DependantField';
import Input from 'components/Input';
import SpinnerV2 from 'components/Spinner-v2';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { PriceChangeOption } from 'models/IPriceChange';
import React, { useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as CoinSwapIcon } from 'static/images/coins-swap-02.svg';
import { ReactComponent as DownloadIcon } from 'static/images/download-01.svg';
import { getPriceByTypePriceId } from 'store/reducers/order/utils';
import { useGetPriceChangeOptionsQuery } from 'store/reducers/orders/ordersSliceApi';
import { padEndZero, replaceNegativeComaWithDot } from 'utils/inputs';
import { roundNumber, toFixed } from 'utils/shared';

import { useOrderNotifications } from '../hooks/useOrderNotifications';
import { useOrderOperationMethods } from '../hooks/useOrderOperationMethods';
import { getDeviationFromPrice } from '../lib/shared';
import { ProductInternalModelState } from '../OrderController';
import RadioButtonSelectField from './RadioButtonSelectField';
import styles from './styles.module.css';
import { isPriceChangeOptionWithDependantDropdown, isPriceChangeOptionWithDependantInput } from './typeGuards';
import type { ChangePriceFormValues, ChangeProductPricePanelProps, Option } from './types';

const precise = 2;

const ChangeProductPricePanel: React.FC<ChangeProductPricePanelProps> = ({ className, suborderIndex, selectionModel, onCancel }) => {
	const { setDirtyValue, setValue, getValues } = useOrderOperationMethods();
	const { data: options = [], isLoading: areOptionsLoading } = useGetPriceChangeOptionsQuery();

	const notify = useOrderNotifications();
	const alertDialogue = useBoolean();
	const form = useForm<ChangePriceFormValues<PriceChangeOption>>();
	const rollbackProducts = useRef<Record<string, ProductInternalModelState>>(null);

	const suborders = getValues('suborders');
	const productsRepository = getValues(`suborders.${suborderIndex}.data.products`);
	const candidatesIds = Object.keys(selectionModel ?? {});
	const needExitSafe = form.formState.isSubmitted;
	const isSubmitForbidden = !form.getFieldState('changeOption').isDirty;

	if (!rollbackProducts.current) {
		rollbackProducts.current = structuredClone(productsRepository);
	}

	const handleCancelSafe = () => {
		if (needExitSafe) return alertDialogue.open();

		onCancel();
	};

	const onCancelSafe = useStopPropagationCallback<HTMLButtonElement>(handleCancelSafe);

	const onOutAndDiscard = () => {
		if (rollbackProducts.current) {
			setValue(`suborders.${suborderIndex}.data.products`, rollbackProducts.current);
			rollbackProducts.current = null;
		}
		onCancel();
	};
	const onOutAndApply = () => {
		notify.success('Зміни ціни збережено');
		rollbackProducts.current = null;
		const values = getValues('suborders');

		setDirtyValue('suborders', values);
		onCancel();
	};

	const notifyChangePriceSuccess = () => {
		notify.success('Ціну зімінено!');
	};
	const notifyChangePriceWarn = () => {
		notify.warn('До одного або деклькох товарів було застосовано "Бонус менеджера", тому до них зміна ціни не застосовуєтсья!');
	};

	const syncPriceChangeAcrossSuborders = (changedProducts: Record<string, ProductInternalModelState>) => {
		const changedProductsEntries = Object.entries(changedProducts ?? {});

		suborders.forEach((suborder, index) => {
			if (suborderIndex === index) return;

			const updatedProducts = { ...suborder.data.products };

			changedProductsEntries.forEach(([changedProductId, changedProduct]) => {
				if (updatedProducts[changedProductId]) {
					updatedProducts[changedProductId] = {
						...updatedProducts[changedProductId],
						price: changedProduct.price,
						sum: changedProduct.sum,
					};
				}
			});

			setValue(`suborders.${index}.data.products`, updatedProducts);
		});
	};

	const handleSubmit = async (values: ChangePriceFormValues<PriceChangeOption>) => {
		if (!rollbackProducts.current) {
			rollbackProducts.current = productsRepository;
		}

		let countProductsWithAppliedManagerBonus = 0;

		if (values.changeOption.url === '/77') {
			const percent = values.discount_in_per_cent;
			const newProductsEntries = candidatesIds.map((candidateId) => {
				const product = productsRepository[candidateId];

				if (product.bonusActive) {
					countProductsWithAppliedManagerBonus += 1;

					return [product.id, product];
				}

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product.id, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const newPrice = Number(price) + Number(price) * (Number(percent) / 100);
				const sum = newPrice * Number(product.amount);
				const normalizedPrice = String(roundNumber(newPrice, 2));
				const deviation = getDeviationFromPrice(normalizedPrice, product);

				return [
					product.id,
					{
						...product,
						price: normalizedPrice,
						sum: String(roundNumber(sum, 2)),
						deviation,
					},
				];
			});

			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);

			if (!countProductsWithAppliedManagerBonus) {
				return notifyChangePriceSuccess();
			}

			notifyChangePriceWarn();
		} else if (values.changeOption.url === '/66') {
			const percent = values.discount;
			const newProductsEntries = candidatesIds.map((candidateId) => {
				const product = productsRepository[candidateId];

				if (product.bonusActive) {
					countProductsWithAppliedManagerBonus += 1;

					return [product.id, product];
				}

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const newPrice = Number(price) - Number(price) * (Number(percent) / 100);
				const sum = newPrice * Number(product.amount);
				const normalizedPrice = String(roundNumber(newPrice, 2));
				const deviation = getDeviationFromPrice(normalizedPrice, product);

				return [
					product.id,
					{
						...product,
						price: String(roundNumber(newPrice, 2)),
						sum: String(roundNumber(sum, 2)),
						deviation,
						...(percent && { manualDiscount: percent }),
					},
				];
			});
			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);

			if (!countProductsWithAppliedManagerBonus) {
				return notifyChangePriceSuccess();
			}

			notifyChangePriceWarn();
		} else if (values.changeOption.url === '/44') {
			const inputSum = values.by_sum_distribution;

			const totalSum = candidatesIds.reduce((accumulator, candidateId) => {
				const product = productsRepository[candidateId];

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const amount = product.amount ? Number(product.amount) : 1;

				return accumulator + price * amount;
			}, 0);

			const firstStep = inputSum / totalSum;

			const newProductsEntries = candidatesIds.map((candidateId, index) => {
				const product = productsRepository[candidateId];

				if (product.bonusActive) {
					countProductsWithAppliedManagerBonus += 1;

					return [product.id, product];
				}

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const multiply = Number(firstStep) * Number(price);
				const multiplyPlusItemPrice = Number(multiply) + Number(price);
				const lastRow = inputSum - multiply;

				if (index + 1 !== candidatesIds.length) {
					const newPrice = String(roundNumber(multiplyPlusItemPrice, 2));
					const deviation = getDeviationFromPrice(newPrice, product);

					return [
						product.id,
						{
							...product,
							price: newPrice,
							sum: String(roundNumber(Number(multiplyPlusItemPrice) * Number(product.amount), 2)),
							deviation,
						},
					];
				} else {
					const newPrice = String(roundNumber(lastRow + Number(price), 2));
					const deviation = getDeviationFromPrice(newPrice, product);

					return [
						product.id,
						{
							...product,
							price: newPrice,
							sum: String(roundNumber((lastRow + Number(price)) * Number(product.amount), 2)),
							deviation,
						},
					];
				}
			});

			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);

			if (candidatesIds.length < 2) {
				return notify.info('Це тип ціни можливо застосувати лише для двох товарів і більше');
			}

			if (!countProductsWithAppliedManagerBonus) {
				return notifyChangePriceSuccess();
			}

			notifyChangePriceWarn();
		} else if (values.changeOption.url === '/55') {
			const inputSum = values.by_quantity_distribution;
			const countOfItems = candidatesIds.length;
			const firstStep = inputSum / countOfItems;

			const newProductsEntries = candidatesIds.map((candidateId, index) => {
				const product = productsRepository[candidateId];

				if (product.bonusActive) {
					countProductsWithAppliedManagerBonus += 1;

					return [product.id, product];
				}

				const quantity = product.amount ? Number(product.amount) : 1;
				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const multiply = firstStep * quantity;
				const multiplyPlusItemPrice = multiply + Number(price);
				const lastRow = inputSum - multiply;

				if (index + 1 !== candidatesIds.length) {
					const newPrice = String(roundNumber(multiplyPlusItemPrice, 2));
					const deviation = getDeviationFromPrice(newPrice, product);

					return [
						product.id,
						{
							...product,
							price: newPrice,
							sum: String(roundNumber(Number(product.amount) * multiplyPlusItemPrice, 2)),
							deviation,
						},
					];
				} else {
					const newPrice = String(roundNumber(lastRow + Number(price), 2));
					const deviation = getDeviationFromPrice(newPrice, product);
					return [
						product.id,
						{
							...product,
							price: String(roundNumber(lastRow + Number(price), 2)),
							sum: String(roundNumber((lastRow + Number(price)) * Number(product.amount), 2)),
							deviation,
						},
					];
				}
			});

			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);

			if (candidatesIds.length < 2) {
				return notify.info('Це тип ціни можливо застосувати лише для двох товарів і більше');
			}
			if (!countProductsWithAppliedManagerBonus) {
				return notifyChangePriceSuccess();
			}

			notifyChangePriceWarn();
		} else if (values.changeOption.url === '/22') {
			notify.warn('Наразі обраний тип ціни недоступний!');
		} else if (values.changeOption.url === '/33') {
			notify.warn('Наразі обраний тип ціни недоступний!');
		} else if (values.changeOption.url === '/88') {
			notify.warn('Наразі обраний тип ціни недоступний!');
		} else {
			const priceId = values.price_type.value;
			const productsWithNewPriceSettled = candidatesIds.map((candidateId) => {
				const product = productsRepository[candidateId];

				if (product.bonusActive) {
					countProductsWithAppliedManagerBonus += 1;

					return [product.id, product];
				}

				const price = getPriceByTypePriceId(product, priceId) || '0';
				const sum = String(Number(price) * Number(product.amount));
				const deviation = getDeviationFromPrice(price, product);

				return [product.id, { ...product, price: String(price), sum, deviation }];
			});
			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(productsWithNewPriceSettled) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);

			if (!countProductsWithAppliedManagerBonus) {
				return notifyChangePriceSuccess();
			}

			notifyChangePriceWarn();
		}
	};

	if (areOptionsLoading) {
		return (
			<div className={clsx(styles.changePricePanel, className)}>
				<SpinnerV2 position="center" />
			</div>
		);
	}

	return (
		<FormProvider {...form}>
			<form className={clsx(styles.changePricePanel, className)} onSubmit={form.handleSubmit(handleSubmit)}>
				<RadioButtonSelectField
					rotateChevronWhenExpand
					name="changeOption"
					leadingIcon={CoinSwapIcon}
					valuesList={options}
					className={styles.majorField}
					placeholder="Оберіть опцію..."
				/>

				<DependantField<Option> watch="changeOption">
					{({ value }) => {
						if (!value) return null;

						if (isPriceChangeOptionWithDependantDropdown(value)) {
							const dependantFieldOption = value.field.items.map((option) => ({ label: option.title, value: option.value }));

							return (
								<RadioButtonSelectField
									rotateChevronWhenExpand
									firstOptionAsDefault
									shouldUnregister
									key={value.field.name}
									name={value.field.name}
									valuesList={dependantFieldOption}
									className={styles.dependantSelect}
								/>
							);
						}

						if (isPriceChangeOptionWithDependantInput(value)) {
							return (
								<>
									<Controller
										shouldUnregister
										key={value.field.name}
										name={value.field.name}
										render={({ field }) => {
											return (
												<Input
													type="text"
													className={clsx(styles.dependantInput)}
													wrapperClassName={styles.field}
													placeholder="0.00"
													value={field.value}
													onKeyDown={replaceNegativeComaWithDot}
													setValue={(newValue) => {
														const input = newValue;
														field.onChange(input);
													}}
													onBlur={() => {
														const numberedValue = Number(field.value);

														if (!numberedValue) {
															field.onChange(padEndZero(0, precise));
														} else {
															field.onChange(toFixed(field.value, { precise, strictPrecision: true }));
														}
													}}
													{...(value.shortTitle && { label: value.shortTitle })}
												/>
											);
										}}
									/>

									{!!value.field.withCalculator && <CalculatorButton />}
								</>
							);
						}

						return null;
					}}
				</DependantField>

				<div className={styles.controls}>
					<Button
						variant="inverseBordered"
						type="button"
						text="Закрити"
						className={styles.cancelButton}
						background="var(--primary-600)"
						onClick={onCancelSafe}
					/>
					<Button
						variant="rounded"
						type="submit"
						text="Виконати"
						icon={<DownloadIcon />}
						disabled={isSubmitForbidden}
						className={clsx(styles.executeButton, { [styles.disabled]: isSubmitForbidden })}
						background="var(--primary-600)"
					/>
				</div>

				{alertDialogue.isOn && (
					<AlertDialogue onOutsideClick={alertDialogue.close}>
						<AlertDialogueHeader onClose={alertDialogue.close}>Вихід з режиму зміни ціни</AlertDialogueHeader>
						<AlertDialogueContent>
							<AlertDialogueMessage>
								{candidatesIds.length > 2 && 'Ви оновили ціни на декілька товарів.'}
								{candidatesIds.length < 2 && 'Ви оновили ціну на товар.'}
							</AlertDialogueMessage>{' '}
							<AlertDialogueMessage>Зберегти зміни остаточно?</AlertDialogueMessage>
						</AlertDialogueContent>
						<AlertDialogueFooter>
							<AlertDialogueControlButton variant="cancel" onClick={onOutAndDiscard}>
								Ні, скасувати та вийти
							</AlertDialogueControlButton>
							<AlertDialogueControlButton variant="submit" onClick={onOutAndApply}>
								Так, зберегти та вийти
							</AlertDialogueControlButton>
						</AlertDialogueFooter>
					</AlertDialogue>
				)}
			</form>
		</FormProvider>
	);
};

export default ChangeProductPricePanel;
