import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import type { OriginalPaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import type { Client, ClientOption, ClientPreview, CreateClient } from 'models/client';
import { ClientOptionSchema } from 'models/client';
import type { ClientFilters } from 'models/client/filters';
import { ClientFiltersSchema } from 'models/client/filters';
import type { Segment } from 'models/client/segment';
import { SegmentSchema } from 'models/client/segment';
import type { Option } from 'models/common/options';
import type { Contract } from 'models/contract';
import { ContractSchema } from 'models/contract';
import { clientsQueryKeys } from 'services/queryKeys';
import { baseQuery } from 'store/config/base-query';
import { logger } from 'utils/logger';

export const clientsSliceApi = createApi({
	reducerPath: 'clients',
	baseQuery: baseQuery(),
	tagTypes: [clientsQueryKeys.clients(), 'filters'],
	endpoints: (builder) => ({
		createClient: builder.mutation<Client, CreateClient>({
			query: (dto) => ({ url: API_ENDPOINT.allClients(), method: 'POST', data: dto }),
			invalidatesTags: [clientsQueryKeys.clients()],
		}),
		getCreateClientColloquialData: builder.query<AnyArg, void>({
			query: () => ({ url: API_ENDPOINT.createClientData(), method: 'GET' }),
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getClients: builder.query({
			query: (payload: string | string[]) => {
				const params = Array.isArray(payload) ? payload[0] : payload;
				return { url: API_ENDPOINT.allClients(), method: 'GET', params };
			},
			transformResponse: (data: OriginalPaginatedRequestResult<ClientPreview[]>) => {
				const result = transformToPaginatedRequestResult<ClientPreview[]>(data);

				return result;
			},
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), ...result.data.map(({ id }) => clientsQueryKeys.client(id))] : []),
		}),
		getSegments: builder.query<Segment[], string>({
			query: () => ({ url: API_ENDPOINT.segments(), method: 'GET' }),
			transformResponse: (data: Segment[]) => {
				const validation = SegmentSchema.array().safeParse(data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'clientsSliceApi.getSegments' });

					return data;
				}

				return validation.data;
			},
		}),
		getClientsOptions: builder.query<Option[], string>({
			query: (query) => ({ url: API_ENDPOINT.clientOptions() + '?search=' + query, method: 'GET' }),
			transformResponse: (response: RequestResult<ClientOption[]>) => {
				const validation = ClientOptionSchema.array().safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'clientsSliceApi.getClientsOptions' });

					return response.data as Option[];
				}

				return validation.data as Option[];
			},
		}),
		getClientsOptionsByIds: builder.query<Option[], string[]>({
			query: (ids) => ({ url: API_ENDPOINT.clientOptions() + '?' + ids.map((id) => `client[]=${id}`).join('&'), method: 'GET' }),
			transformResponse: (response: RequestResult<ClientOption[]>) => {
				const validation = ClientOptionSchema.array().safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'clientsSliceApi.getClientsOptionsByIds' });

					return response.data as Option[];
				}

				return validation.data as Option[];
			},
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getClientById: builder.query({
			query: (id: string) => ({ url: API_ENDPOINT.clientById(id), method: 'GET' }),
			transformResponse: (response: RequestResult<Client>) => response.data,
			providesTags: (_, __, id) => [clientsQueryKeys.client(id)],
		}),
		getContractList: builder.query<Contract[], string>({
			query: (id: string) => ({ url: API_ENDPOINT.clientContracts(id), method: 'GET' }),
			transformResponse: (response: RequestResult<Contract[]>) => {
				const validation = ContractSchema.array().safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'clientsSliceApi.getContractList' });

					return response.data;
				}
				return validation.data;
			},
		}),
		getAllContractList: builder.query<Option[], void>({
			query: () => ({ url: API_ENDPOINT.contracts(), method: 'GET' }),
			transformResponse: (response: RequestResult<Contract[]>) => {
				return response?.data?.map((contract) => ({ label: contract.title, value: contract.id })) ?? [];
			},
		}),
		getClientsFiltersData: builder.query<ClientFilters, void>({
			query: () => ({ url: API_ENDPOINT.clientFilters(), method: 'GET' }),
			transformResponse: (response: ClientFilters) => {
				const validation = ClientFiltersSchema.safeParse(response);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'clientsSliceApi.getClientsFiltersData' });

					return response;
				}
				return validation.data;
			},
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), 'filters'] : []),
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetClientByIdQuery,
	useGetSegmentsQuery,
	useGetClientsOptionsQuery,
	useGetClientsOptionsByIdsQuery,

	useCreateClientMutation,
	useGetContractListQuery,
	useGetClientsFiltersDataQuery,

	useLazyGetClientByIdQuery,
	useLazyGetClientsOptionsByIdsQuery,
} = clientsSliceApi;
