import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { ClientContacts, ClientProfileDetails, ClientProfileTopBar, PaymentProgressTile } from 'components/ClientProfilePage/';
import { ColorfulSelectField } from 'components/FormComponents/Inputs/inputs';
import ProgressBadge from 'components/ProgressBadge';
import Spacer from 'components/Spacer';
import { breakPoints, CLIENT_PAGE_FILTER_KEYS } from 'const';
import React, { lazy, Suspense } from 'react';
import { FormProvider } from 'react-hook-form';
import MediaQuery from 'react-responsive';
import { ReactComponent as DelayIcon } from 'static/images/delay.svg';
import { ReactComponent as EyeIcon } from 'static/images/eye.svg';
import { ReactComponent as FolderIcon } from 'static/images/folder-check.svg';
import { ReactComponent as UploadIcon } from 'static/images/upload-file.svg';
import { formatNumberToUAH, toFixed } from 'utils/shared';

import { getProgressColor } from './lib/getProgressColor';
import { useClientCard } from './lib/useClientCard';
import { formatPlural } from './lib/utils';
import styles from './styles.module.css';

const ActionButton = lazy(() => import('layouts/PageLayout/ActionButton'));
const BottomPanel = lazy(() => import('layouts/PageLayout/BottomPanel'));
const FileUploadField = lazy(() => import('components/FileUploadField'));
const Button = lazy(() => import('components/Button'));

const numberFormatter = (value: number) => {
	return toFixed(value, { precise: 2 });
};

const ClientCard: React.FC<{ id: string }> = (props) => {
	const {
		form,
		spacerSize,
		breadcrumbs,
		segments,
		client,
		clientType,
		onPaymentInfoClick,
		onClientBillsPreview,
		clientStateBadgeColor,
		onRedirectToDocuments,
	} = useClientCard(props);

	return (
		<FormProvider {...form}>
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={breadcrumbs} className={styles.breadcrumbs}>
					<ColorfulSelectField disabled name="segment" options={segments} className="inside-breadcrumbs" defaultValue={client.segment} />
				</BreadCrumbs>

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<BottomPanel className={styles.mobileBottomPanel}>
						<ActionButton text="Переглянути рахунки" onClick={onClientBillsPreview} icon={<EyeIcon />} />
					</BottomPanel>
				</MediaQuery>

				<div className={styles.pageMainLayout}>
					<div className={styles.topBar}>
						<ClientProfileTopBar
							actionsSlot={
								<div className={styles.btnsWrapper}>
									<Button
										id="clients-top-bar-see-all-button"
										text="Документи"
										onClick={onRedirectToDocuments}
										icon={<FolderIcon />}
										variant="rounded"
										background={'var(--primary-500)'}
										hoverBg="#000"
									/>
									<Button
										id="clients-top-bar-see-all-button"
										text="Переглянути рахунки"
										onClick={onClientBillsPreview}
										icon={<EyeIcon />}
										variant="rounded"
										background={'#d71b87'}
										hoverBg="#000"
									/>
								</div>
							}
						/>
					</div>

					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<div className={styles.clientProfileDetailsContainer}>
							<ClientProfileDetails
								title="Загальна сума закупок"
								numberFormatter={numberFormatter}
								totalAmount={client?.totalSum}
								since={client?.firstRealization}
							>
								<div className={styles.clientStateBadge} style={{ backgroundColor: clientStateBadgeColor }} />

								<div className={styles.badgeContainer}>
									<ProgressBadge
										readonly
										forceDisplayProgressCompleteIcon
										coloredProgressBar
										title={`Доступно ${formatNumberToUAH(client?.available?.availableSum)}`}
										subTitle={`Борг - ${formatNumberToUAH(Math.abs(client?.available?.debt))} - Ліміт ${formatNumberToUAH(
											client?.available?.limit,
										)}`}
										start={Math.abs(client?.available?.debt)}
										end={client?.available?.limit}
										icon={UploadIcon}
										className={styles.progressBadge}
									/>

									<ProgressBadge
										readonly
										excludeProgressBar
										title="Днів для відстрочки"
										start={10}
										end={16}
										icon={DelayIcon}
										prefix=" days"
										precision={0}
										className={styles.progressBadge}
										subTitle={
											<span style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '286px', width: '100%' }}>
												<span>{formatPlural(client?.daysOfDelay)}</span>
											</span>
										}
									/>

									{/* !!! TEMP disabled */}
									{/* <div className={styles.controlsContainer}>
											<Button text="Несплачені рахунки" variant="bordered" onClick={null} className={styles.unpaidButton} />
											<Button text="Надіслати нагадування" variant="default" onClick={null} className={styles.sendReminder} />
										</div> */}
								</div>
							</ClientProfileDetails>
						</div>
					</MediaQuery>

					<Spacer height={spacerSize} />

					<div className={styles.contentLayoutContainer}>
						<div className={styles.progressTiles}>
							<PaymentProgressTile
								getProgressColor={getProgressColor}
								onClick={onPaymentInfoClick(CLIENT_PAGE_FILTER_KEYS.waiting)}
								title="Рахунки, що очікують оплати"
								from={client?.waitingForPayment?.amount}
								to={client?.waitingForPayment?.fromAmount}
								amount={client?.waitingForPayment?.sum}
							/>

							<PaymentProgressTile
								getProgressColor={getProgressColor}
								onClick={onPaymentInfoClick(CLIENT_PAGE_FILTER_KEYS.overdue)}
								title="Протерміновані рахунки"
								from={client?.overduePayments?.amount}
								to={client?.overduePayments?.fromAmount}
								amount={client?.overduePayments?.sum}
							/>

							<PaymentProgressTile
								getProgressColor={getProgressColor}
								onClick={onPaymentInfoClick(CLIENT_PAGE_FILTER_KEYS.prepaid)}
								title="Передплачені рахунки"
								from={client?.prepaidPayments?.amount}
								to={client?.prepaidPayments?.fromAmount}
								amount={client?.prepaidPayments?.sum}
							/>
						</div>

						<div className={styles.contentLayout}>
							<ClientContacts clientType={clientType} />

							<MediaQuery minWidth={breakPoints.MOBILE}>
								<div className={styles.clientStateBadge} style={{ backgroundColor: clientStateBadgeColor }} />

								<div className={clsx(styles.rightColumn, 'hide-scroll-bar')}>
									<ClientProfileDetails
										skipResponsive
										title="Загальна сума закупок"
										numberFormatter={numberFormatter}
										totalAmount={client?.totalSum}
										since={client?.firstRealization}
										subtitle=""
									>
										<div className={styles.badgeContainer}>
											<Suspense fallback={<div>Loader...</div>}>
												<FileUploadField disabled className={styles.fileUploadField} />
											</Suspense>

											<ProgressBadge
												forceDisplayProgressCompleteIcon
												readonly
												coloredProgressBar
												title={`Доступно ${formatNumberToUAH(client?.available?.availableSum)}`}
												subTitle={`Борг - ${formatNumberToUAH(Math.abs(client?.available?.debt))} - Ліміт ${formatNumberToUAH(
													client?.available?.limit,
												)}`}
												start={Math.abs(client?.available?.debt)}
												end={client?.available?.limit}
												icon={UploadIcon}
												precision={0}
												className={styles.progressBadge}
											/>

											<ProgressBadge
												readonly
												excludeProgressBar
												title="Днів для відстрочки"
												start={10}
												end={16}
												icon={DelayIcon}
												prefix=" days"
												precision={0}
												className={styles.progressBadge}
												subTitle={
													<span
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															maxWidth: '286px',
															width: '100%',
														}}
													>
														<span>{formatPlural(client?.daysOfDelay)}</span>
													</span>
												}
											/>

											{/* !!! TEMP disabled */}
											{/* <div className={styles.controlsContainer}>
													<Button
														text="Несплачені рахунки"
														variant="bordered"
														onClick={null}
														className={styles.unpaidButton}
													/>
													<Button
														text="Надіслати нагадування"
														variant="default"
														onClick={null}
														className={styles.sendReminder}
													/>
												</div> */}
										</div>
									</ClientProfileDetails>
								</div>
							</MediaQuery>
						</div>
					</div>
				</div>
			</div>
		</FormProvider>
	);
};

export default ClientCard;
