import clsx from 'clsx';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Icon } from 'static/images/chevron-down.svg';
import { useGetWarehousesQuery } from 'store/reducers/users/usersSliceApi';

import ProductReservationCard from '../ProductReservationCard';
import InStockTable from '../Tables/InStockTable';
import WithdrawReservationModal from '../WithdrawReservationModal';
import styles from './styles.module.css';
import type { IProps } from './types';

const columns = [
	{ Header: 'Склад', accessor: 'warehouse', width: 56 },
	{ Header: 'Наявність', accessor: 'inStock', width: 56 },
	{ Header: 'Резерв', accessor: 'reservation', width: 56 },
];

const AvailabilityChecker: React.FC<IProps> = ({ className, freeLeftovers, reserves, stockId }) => {
	const { data: allStocks } = useGetWarehousesQuery(undefined);
	const [selectedRow, setSelectedRow] = useState(null);
	const [withdrawReservationItem, setWithdrawReservationItem] = useState(null);
	const accordion = useBoolean();
	const accordionToggle = useStopPropagationCallback(accordion.toggle);

	const allData = useMemo(() => {
		const freeLeftoversLookup = new Map(freeLeftovers?.map((item) => [item.stock?.id, item?.amount || 0]) ?? []);
		const reservesLookup = new Map(reserves?.map((item) => [item.stock?.id, item?.amount || 0]) ?? []);

		const data = allStocks.map((stock) => {
			const inStock = freeLeftoversLookup.get(stock.value) || 0;
			const reservation = reservesLookup.get(stock.value) || 0;

			return {
				id: stock?.value ?? '',
				warehouse: stock?.label ?? '',
				inStock: Number(inStock),
				reservation: Number(reservation),
				isCurrent: stockId === stock.value,
			};
		});
		const sorted = data.sort((a, b) => {
			if (b.inStock !== a.inStock) {
				return b.inStock - a.inStock;
			}
			return b.reservation - a.reservation;
		});

		return sorted;
	}, [allStocks, freeLeftovers, reserves, stockId]);

	const handleRowClick = (row: AnyArg) => {
		return;
		// TODO add type
		setSelectedRow(row);
	};

	const resetSelection = () => {
		setSelectedRow(null);
	};

	const handleReservationWithdraw = useCallback((item) => {
		setWithdrawReservationItem(item);
	}, []);

	const closeModal = () => {
		setWithdrawReservationItem(null);
	};

	const handleReservationWithdrawal = async () => {
		toast.success('Товари успішно знято з резерву');
		closeModal();
	};

	const showModal = !!withdrawReservationItem && !!selectedRow;

	return (
		<div data-availability-checker className={clsx(styles.wrapper, className)}>
			<div className={styles.tableWrapper}>
				<button type="button" onClick={accordionToggle} className={styles.expandButton}>
					<span>Наявність на складах</span>
					<Icon />
				</button>

				<div className={clsx(styles.accordion, { [styles.expanded]: accordion.isOn })}>
					<InStockTable data={allData} columns={columns} onRowClick={handleRowClick} getActiveRow={(row) => !!row?.isCurrent} />
				</div>
			</div>

			{!!selectedRow && (
				<ProductReservationCard
					key={selectedRow?.id}
					orders={[]}
					warehouse={selectedRow.warehouse}
					onClose={resetSelection}
					onWithdrawReservation={handleReservationWithdraw}
				/>
			)}

			{showModal && (
				<WithdrawReservationModal
					key={withdrawReservationItem.id}
					onSubmit={handleReservationWithdrawal}
					onClose={closeModal}
					inReservation={withdrawReservationItem.quantity}
					warehouse={selectedRow.warehouse}
					// disable={isProcessing}
				/>
			)}

			{/* {isProcessing && <Spinner />} */}
		</div>
	);
};
export default AvailabilityChecker;
