import { useModal } from 'hooks/useModal';
import { ProductInternalModelState } from 'pages/Order/OrderController';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { formatInputValue } from 'utils/inputs';

import TransferProductDetailsModal from '../components/TransferModals/TransferProductDetailsModal';
import TransferProductsModal from '../components/TransferModals/TransferProductsModal';
import TransferServicesModal from '../components/TransferModals/TransferServiceModal';

const MODAL_KEYS = {
	'split:transfer-list': 'split:transfer-list',
	'split:transfer-details': 'split:transfer-details',
} as const;

export type TransferModalPayload = {
	onSubmit?: <TData>(data?: TData) => void;
	onEntityClick?: <TData>(data?: TData) => void;
	onCancel?: () => void;
	data?: AnyArg;
};

export const useTransferModals = () => {
	const productDetailsModal = useModal({
		modal: ({ closeModal, product, stock, onSubmit }) => {
			const handleClose = () => {
				closeModal();
			};

			return <TransferProductDetailsModal onSubmit={onSubmit} entity={product} onClose={handleClose} stock={stock} />;
		},
		key: MODAL_KEYS['split:transfer-details'],
	});

	const handleProductClick = ({ product, stock }: { product: ProductInternalModelState; stock: { id: string; title: string } }, onEntityClick) => {
		productDetailsModal.openModal({
			product,
			stock,
			onSubmit: (payload) => {
				onEntityClick(payload);
				productDetailsModal.closeModal();
			},
		});
	};

	const handleTransferProductsModalClose = (closeCb: () => void) => {
		closeCb();
	};

	let targetSuborderIndex = 0;

	const transferProductsModal = useModal({
		modal: ({ closeModal, data: { candidates, stock }, onSubmit }) => {
			const [editedTransferCandidates, setEditedTransferCandidates] = useState([...candidates]);

			const handleProductsTransfer = () => {
				const areAllTransferItemsHaveValidAmount = editedTransferCandidates.every((candidate) => !!candidate.amount);

				if (!areAllTransferItemsHaveValidAmount) {
					return toast.error('Неправильна кількість');
				}

				onSubmit(editedTransferCandidates);
				closeModal?.();
			};

			const formatValue = (value: number | string, precision: number) => {
				const valueFormatter = formatInputValue({ isInt: precision === 0 });

				return valueFormatter(value);
			};

			const handleItemQuantityChange = (id: string, newQuantity: number) => {
				setEditedTransferCandidates(
					editedTransferCandidates.map((item) => {
						if (item.id !== id) return item;
						const precision = item?.unit?.precision;
						const amount = formatValue(newQuantity, precision);

						return {
							...item,
							amount,
						};
					}),
				);
			};
			const onProductClick = (product: ProductInternalModelState) => {
				handleProductClick({ product, stock }, (editedSingleProduct) => {
					setEditedTransferCandidates(
						editedTransferCandidates.map((item) => {
							if (item.id !== editedSingleProduct.id) return item;

							return {
								...editedSingleProduct,
							};
						}),
					);
				});
			};

			return (
				<TransferProductsModal
					onSubmit={handleProductsTransfer}
					products={editedTransferCandidates}
					onQuantityChange={handleItemQuantityChange}
					onProductClick={onProductClick}
					targetSuborderIndex={targetSuborderIndex}
					onClose={handleTransferProductsModalClose.bind(null, closeModal)}
				/>
			);
		},
		key: MODAL_KEYS['split:transfer-list'],
	});

	const transferServiceModal = useModal({
		modal: ({ closeModal, onSubmit, data }) => {
			const handleClose = () => {
				closeModal();
			};

			return <TransferServicesModal data={data} onSubmit={onSubmit} targetSuborderIndex={targetSuborderIndex} onClose={handleClose} />;
		},
		key: 'split:transfer-services',
	});

	const open = (idx: number, payload: TransferModalPayload) => {
		targetSuborderIndex = idx;
		transferProductsModal.openModal(payload);
	};
	const openService = (idx: number, payload: TransferModalPayload) => {
		targetSuborderIndex = idx;
		transferServiceModal.openModal(payload);
	};

	return {
		open,
		openService,
	};
};
