import { type DragEndEvent, closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { breakPoints } from 'const';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { isObject } from 'utils/type-guards';

import styles from './lib/table/styles/style.module.css';
import type { IProps } from './lib/table/types/general';
import type { ColumnOrderModel, PinningModel, RowSelectionModel, VisibilityModel } from './lib/table/types/table';
import Pagination from './Pagination';
import ColumnControlsMenu from './TableComponents/ColumnControlsMenu';
import DraggableTableHeader from './TableComponents/DragableHeader';
import DragAlongCell from './TableComponents/DragAlongCell';
import PinColumnMenu from './TableComponents/PinColumnMenu';
import SortColumnMenu from './TableComponents/SortColumnMenu';

const MobileTableView = lazy(() => import('./TableComponents/MobileTableView'));

const fallbackModelState = {};
const fallbackOrderModelState = [];

const defaultGetRowId = <TData,>(row: TData): string => {
	if (isObject<TData>(row) && 'id' in row) {
		return row.id as string;
	}
};

const Table = <TData,>({ actionsPanelSlot: ActionsPanelSlot, htmlAttr, ...props }: IProps<TData>) => {
	const {
		allData,
		columns,
		filledFromSecondCell,
		selectable,
		onClickRow,
		className,
		extendRowProps,
		defineRowDisabledForSelection,
		visibilityModel = fallbackModelState,
		pinningModel = fallbackModelState,
		onPinningModelChange,
		rowSelectionModel = fallbackModelState,
		onRowSelectionModelChange,
		getRowId = defaultGetRowId,
		getCanSelectRow,
		shouldHighlightDisableRow = true,
		columnOrderModel = fallbackOrderModelState,
		onColumnOrderModelChange,
	} = props;

	const [data, setData] = useState([...allData]);
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const table = useReactTable<TData>({
		columns,
		data,
		debugTable: true,
		state: {
			columnVisibility: visibilityModel as VisibilityModel,
			columnPinning: pinningModel as PinningModel,
			rowSelection: rowSelectionModel as RowSelectionModel,
			columnOrder: columnOrderModel as ColumnOrderModel,
		},
		getRowId,
		enableRowSelection: getCanSelectRow,
		manualPagination: true,
		manualSorting: true,
		getCoreRowModel: getCoreRowModel<TData>(),
		onRowSelectionChange: onRowSelectionModelChange,
		onColumnPinningChange: onPinningModelChange,
		onColumnOrderChange: (order) => {
			const pinnedLeft = (isObject(pinningModel) && 'left' in pinningModel && (pinningModel.left as string[])) || [];
			const pinnedRight = (isObject(pinningModel) && 'right' in pinningModel && (pinningModel.right as string[])) || [];
			const currentOrder = typeof order === 'function' ? order(columnOrderModel) : order;

			const newOrder = [...pinnedLeft, ...currentOrder.filter((id) => !pinnedLeft.includes(id) && !pinnedRight.includes(id)), ...pinnedRight];

			onColumnOrderModelChange?.(newOrder);
		},
		columnResizeMode: 'onChange',
	});

	const { beforeTable, afterTable } = props.childrenLikeSlots ?? {};
	const RenderTableHeader = props.renderTableHeader;
	const RenderTableBody = props.renderTableBody;

	const handleClickOnRow = (row: TData) => {
		onClickRow?.(row);
	};
	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;
		if (active && over && active.id !== over.id) {
			if (!onColumnOrderModelChange) return;

			const pinnedLeft = (isObject(pinningModel) && 'left' in pinningModel && (pinningModel.left as string[])) || [];
			const pinnedRight = (isObject(pinningModel) && 'right' in pinningModel && (pinningModel.right as string[])) || [];
			const isPinned = (id: string) => !!pinnedLeft?.includes(id) || !!pinnedRight?.includes(id);

			onColumnOrderModelChange((columnOrder) => {
				const oldIndex = columnOrder.indexOf(active.id as string);
				const newIndex = columnOrder.indexOf(over.id as string);

				if (isPinned(active.id as string) || isPinned(over.id as string)) {
					return columnOrder;
				}

				return arrayMove(columnOrder, oldIndex, newIndex);
			});
		}
	}

	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

	useEffect(() => {
		setData(allData);
	}, [allData]);

	const shouldRenderActionSlot = !!ActionsPanelSlot && Object.keys(rowSelectionModel ?? {}).length > 0;
	const isDraggable = !!props.columnOrderModel;

	if (isMobile) {
		return (
			<Suspense fallback={<PageContentSkeleton />}>
				<MobileTableView tableInstance={table} {...props} />
				<Pagination />
			</Suspense>
		);
	}

	return (
		<>
			{beforeTable}
			<DndContext collisionDetection={closestCenter} modifiers={[restrictToHorizontalAxis]} onDragEnd={handleDragEnd} sensors={sensors}>
				<div className={clsx(styles.container, className)} {...htmlAttr}>
					<div style={{ display: shouldRenderActionSlot ? 'block' : 'none' }}>
						{!!ActionsPanelSlot && (
							<ActionsPanelSlot
								tableInstance={table}
								allData={allData}
								rowSelectionModel={rowSelectionModel}
								onRowSelectionModelChange={onRowSelectionModelChange}
								width={table.getTotalSize()}
								checkbox={table.getHeaderGroups().map((headerGroup) =>
									headerGroup.headers
										.filter((header) => header.column.id === 'select')
										.map((h) => {
											return (
												<React.Fragment key={h.id}>
													{!h.isPlaceholder && flexRender(h.column.columnDef.header, h.getContext())}
												</React.Fragment>
											);
										}),
								)}
							/>
						)}
					</div>
					<table
						className={styles.table}
						style={{
							width: '100%',
							minWidth: table.getTotalSize(),
						}}
					>
						{!!RenderTableHeader && !shouldRenderActionSlot && <RenderTableHeader table={table} />}

						<thead id="table-head" style={{ display: !RenderTableHeader && !shouldRenderActionSlot ? 'table-header-group' : 'none' }}>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									<SortableContext items={columnOrderModel} strategy={horizontalListSortingStrategy}>
										{headerGroup.headers.map((header) => {
											return (
												<DraggableTableHeader key={header.id} header={header} isDraggable={isDraggable}>
													<ColumnControlsMenu>
														{header.column.getCanPin() && <PinColumnMenu headerInstance={header} tableInstance={table} />}
														{header.column.getCanSort() && (
															<SortColumnMenu
																appendQueryKey={header.column.columnDef?.meta?.appendQueryKey}
																sortColumnKey={header.column.columnDef?.meta?.sortQueryKey ?? ''}
															/>
														)}
													</ColumnControlsMenu>
												</DraggableTableHeader>
											);
										})}
									</SortableContext>
								</tr>
							))}
						</thead>

						{!!RenderTableBody && <RenderTableBody table={table} />}
						{!RenderTableBody && (
							<tbody>
								{table.getRowModel().rows.map((row) => {
									const resolvedRow = defineRowDisabledForSelection?.(row) || row;
									const {
										style: extendedStyles,
										className: extendedRowClassName,
										...restExtendedProps
									} = extendRowProps?.(resolvedRow) ?? {};

									return (
										<tr
											data-tbody-table-row
											key={row.id}
											className={clsx(styles.tableRow, 'text-sx-regular', extendedRowClassName, {
												[styles.canNotSelect]: !row.getCanSelect() && shouldHighlightDisableRow,
											})}
											style={{ cursor: onClickRow && 'pointer', ...(isObject(extendedStyles) && extendedStyles) }}
											onClick={(e) => {
												e.stopPropagation();
												handleClickOnRow(row.original);
											}}
											{...restExtendedProps}
										>
											{row.getVisibleCells().map((cell) => {
												return (
													<SortableContext key={cell.id} items={columnOrderModel} strategy={horizontalListSortingStrategy}>
														<DragAlongCell
															key={cell.id}
															cell={cell}
															row={row}
															selectable={selectable}
															filledFromSecondCell={filledFromSecondCell}
															isDraggable={isDraggable}
														/>
													</SortableContext>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						)}
					</table>
				</div>
			</DndContext>
			{afterTable}
		</>
	);
};

export default Table;
