import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type Header, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import { getCommonPinningStyles } from 'components/Table/lib/table/styles/getCommonPinningStyles';
import React from 'react';
import { ReactComponent as DragIcon } from 'static/images/drag.svg';

import styles from './styles.module.css';

type DraggableTableHeaderProps = {
	header: Header<AnyArg, unknown>;
	children?: React.ReactNode;
	isDraggable?: boolean;
	handleElement?: React.ReactNode;
};

const DraggableTableHeader: React.FC<DraggableTableHeaderProps> = ({ header, children, isDraggable, handleElement }) => {
	const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
		id: header.column.id,
	});

	const style: React.CSSProperties = {
		opacity: isDragging ? 0.65 : 1,
		position: 'relative',
		transform: CSS.Translate.toString(transform),
		transition: 'width transform 0.2s ease-in-out',
		whiteSpace: 'nowrap',
		width: header.getSize(),
		zIndex: isDragging ? 1 : 0,
	};

	return (
		<th
			colSpan={header.colSpan}
			className={clsx('text-sx-medium color-grey-600', styles.th)}
			style={
				{
					width: header.getSize(),
					position: 'relative',
					'--column-controls-menu-offset': header.column.columnDef.meta?.headerCellContentOffset ?? '0',
					...(header.column.getIsPinned() ? getCommonPinningStyles(header.column) : isDraggable ? style : {}),
				} as React.CSSProperties
			}
			ref={setNodeRef}
		>
			<span style={{ textAlign: 'center' }}>{!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}</span>
			{children}

			<div className={styles.button} role="button" {...attributes} {...listeners}>
				{handleElement || <DragIcon />}
			</div>
		</th>
	);
};

export default DraggableTableHeader;
