import clsx from 'clsx';
import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './styles.module.css';

interface ReadonlyStatusLabelProps {
	className?: string;
	labelText?: string;
	lockedBy: string;
}

const ReadonlyStatusLabel: React.FC<ReadonlyStatusLabelProps> = ({ className, labelText = 'Заблоковано', lockedBy }) => {
	const readonlyAlert = useBoolean();

	const readonlyAlertToggle = useStopPropagationCallback<HTMLButtonElement>(readonlyAlert.toggle);

	useEffect(() => {
		if (!readonlyAlert.isOn) {
			readonlyAlert.open();
		}
	}, []);

	return (
		<>
			<button type="button" onClick={readonlyAlertToggle} className={clsx(styles.stamp, className)} data-text={labelText} />

			{readonlyAlert.isOn && (
				<>
					{createPortal(
						<AlertDialogue onOutsideClick={readonlyAlert.close}>
							<AlertDialogueHeader onClose={readonlyAlert.close}>Інформація про заявку</AlertDialogueHeader>
							<AlertDialogueContent className={styles.content}>
								<AlertDialogueMessage className={styles.paragraph}>
									Ця заявка вже редагується користувачем <b>{lockedBy}.</b> Наразі ви можете переглянути заявку в режимі
									&quot;Читання&quot; без можливості внести свої зміни до поточної заявки.
								</AlertDialogueMessage>
								<AlertDialogueMessage className={styles.paragraph}>
									Якщо вам треба відредагувати заявку, будь-ласка, зачекайте поки інший користувач завершить зміни або звʼяжіться з
									ним особисто.
								</AlertDialogueMessage>
								<AlertDialogueFooter justify="flex-end" className={styles.footer}>
									<AlertDialogueControlButton variant="submit" onClick={readonlyAlert.close}>
										Зрозуміло
									</AlertDialogueControlButton>
								</AlertDialogueFooter>
							</AlertDialogueContent>
						</AlertDialogue>,
						document.querySelector('#portal'),
					)}
				</>
			)}
		</>
	);
};

export default ReadonlyStatusLabel;
