import clsx from 'clsx';
import EmptyProductsGate from 'components/OrderPageComponents/(EmptyStateGates)/EmptyProductsGate';
import Table from 'components/Table';
import type {
	ColumnDefinition,
	ColumnOrderModel,
	OnColumnOrderModelChange,
	OnPinningModelChange,
	OnRowSelectionModelChange,
	PinningModel,
	RowSelectionModel,
	VisibilityModel,
} from 'components/Table/lib/table/types/table';
import { getOrderControllerEntityRowId } from 'pages/Order/OrderController/lib/transform';
import React from 'react';

import { ProductInternalModelState } from '../../../OrderController';

export interface ProductProps<TData> {
	selectionModel: RowSelectionModel;
	onRowSelectionModelChange: OnRowSelectionModelChange;
	visibilityModel: VisibilityModel;
	pinningModel: PinningModel;
	columnOrderModel: ColumnOrderModel;
	onColumnOrderChange: OnColumnOrderModelChange;
	onPinningModelChange: OnPinningModelChange;
	columns: ColumnDefinition<TData>;
	products: ProductInternalModelState[];
	getCanSelectRow: (item: TData) => boolean;
	className?: string;
}

export const Products = <TData,>({
	products,
	columns,
	pinningModel,
	onPinningModelChange,
	visibilityModel,
	selectionModel,
	onRowSelectionModelChange,
	getCanSelectRow,
	className,
	columnOrderModel,
	onColumnOrderChange,
}: ProductProps<TData>) => {
	const isEmpty = products.length === 0;

	return (
		<EmptyProductsGate isEmpty={isEmpty}>
			<Table
				className={clsx(className)}
				visibilityModel={visibilityModel}
				onPinningModelChange={onPinningModelChange}
				pinningModel={pinningModel}
				allData={products}
				pageType="order"
				columnOrderModel={columnOrderModel}
				onColumnOrderModelChange={onColumnOrderChange}
				getRowId={getOrderControllerEntityRowId}
				rowSelectionModel={selectionModel}
				onRowSelectionModelChange={onRowSelectionModelChange}
				columns={columns}
				getCanSelectRow={getCanSelectRow}
			/>
		</EmptyProductsGate>
	);
};
