import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import OrderExtraActionButton from 'components/OrderPageComponents/OrderExtraActionButton';
import TableCheckbox from 'components/Table/TableComponents/CheckboxColumn/TableCheckbox';
import PageBasedIndexTableCell from 'components/Table/TableComponents/PageBasedIndexTableCell';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { breakPoints } from 'const';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import { useVisibilityColumns } from 'hooks/useVisibilityColumns';
import DetailedEntitySpitData from 'pages/Order/components/DetailedEntitySpitData';
import DisabledEntityTooltip from 'pages/Order/components/DisabledEntityTooltip';
import OrderProductAmountInput from 'pages/Order/components/OrderProductAmountInput';
import OrderProductAvailabilityController from 'pages/Order/components/OrderProductAvailabilityController';
import OrderProductDeviationInput from 'pages/Order/components/OrderProductDeviationInput';
import OrderProductPriceInput from 'pages/Order/components/OrderProductPriceInput';
import OrderProductSumDisplayField from 'pages/Order/components/OrderProductSumDisplayField';
import SearchProductCell from 'pages/Order/components/SearchProductCell';
import { visibilityColumnsIcons } from 'pages/Order/lib/icons';
import { Can } from 'pages/Order/OrderAbility/provider';
import { ProductInternalModelState } from 'pages/Order/OrderController';
import { getIsOnSplitViewPage } from 'pages/Order/utils';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { toFixed } from 'utils/shared';

import { usePriceTypeColumns } from './priceType';

export type UseOrderColumnsProps = {
	adjustSaveKey?: string;
	suborderIndex?: number;
};

const columnHelper = createColumnHelper<ProductInternalModelState>();
const commonCellSize = 110;

export const useOrderColumns = (props?: UseOrderColumnsProps) => {
	const location = useLocation();
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: ${breakPoints.DESKTOP - 1}px)` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${breakPoints.DESKTOP}px)` });
	const { adjustSaveKey, suborderIndex = 0 } = props ?? {};

	const findSalePrice = (prices) => {
		const salePrice = prices?.find((price) => price.typePrice.id === 'a129be59-31f8-4bee-9f3b-cf4e76495df8');
		return salePrice;
	};

	const findGoodPrice = (prices) => {
		const goodPrice = prices?.find((price) => price.typePrice.id === 'e4ea81a3-8307-11ed-b8b4-932465dbe65a');
		return goodPrice;
	};

	const priceTypeColumns = usePriceTypeColumns({ suborderIndex, isDesktop, isTablet });

	const initialColumns = useMemo(() => {
		const abilityOrderKey = getIsOnSplitViewPage() ? 'suborder' : 'order';

		const defColumns = [
			columnHelper.accessor((row) => row.id, {
				id: 'id',
				header: () => {
					return <HeaderCell>{'Id'}</HeaderCell>;
				},
				cell: (cell) => {
					return (
						<RegularCell>
							<PageBasedIndexTableCell index={cell.row.index} />
						</RegularCell>
					);
				},
				size: 50,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Id',
					getMobileHeaderCell: () => 'Id',
					icon: <visibilityColumnsIcons.Uuid />,
				},
			}),
			columnHelper.accessor('select-col', {
				id: 'select-col',
				header: ({ table }) => {
					return (
						<Can passThrough I="select" an={`${abilityOrderKey}.${suborderIndex}.products`}>
							{(can) => (
								<HeaderCell>
									<TableCheckbox
										checked={table.getIsAllRowsSelected()}
										indeterminate={table.getIsSomeRowsSelected()}
										onChange={table.getToggleAllRowsSelectedHandler()}
										disabled={!can}
									/>
								</HeaderCell>
							)}
						</Can>
					);
				},
				cell: (cell) => {
					return (
						<Can passThrough I="select" an={`${abilityOrderKey}.${suborderIndex}.products`}>
							{(can) => (
								<RegularCell>
									<TableCheckbox
										checked={cell.row.getIsSelected()}
										disabled={!can || !cell.row.getCanSelect()}
										onChange={cell.row.getToggleSelectedHandler()}
									/>
								</RegularCell>
							)}
						</Can>
					);
				},
				size: 60,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getMobileHeaderCell: () => '',
					headerCellContentOffset: '19px',
				},
			}),
			columnHelper.accessor((row) => (row?.isBlank ? '' : row.code), {
				id: 'code',
				header: () => <HeaderCell>Код товару</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Код товару',
					getMobileHeaderCell: () => 'Код товару',
					icon: <visibilityColumnsIcons.FinalCost />,
				},
			}),
			...(isTablet
				? [
						columnHelper.accessor((row) => row.brand, {
							id: 'brand',
							header: () => <HeaderCell>Товар</HeaderCell>,
							cell: ({ row }) => {
								if (row.original?.isBlank) {
									return (
										<RegularCell className="justify-start">
											<SearchProductCell id={row.original.id} suborderIndex={suborderIndex} />
										</RegularCell>
									);
								}

								return (
									<>
										<RegularCell pr="12px" gap="8px">
											<span className="tablet-cell text-left">
												<span>{row.original.brand?.title}</span>
												<span>{row.original?.title}</span>
											</span>
											{!row.getCanSelect() && (
												<DisabledEntityTooltip>
													<DetailedEntitySpitData entityId={row.original.id} entityType="products" />
												</DisabledEntityTooltip>
											)}
											<Can passThrough I="change" an={`order.${suborderIndex}.products.table`}>
												{(can) => {
													return (
														<OrderExtraActionButton
															suborderIndex={suborderIndex}
															product={row.original}
															disabled={!can}
														/>
													);
												}}
											</Can>
										</RegularCell>
									</>
								);
							},
							size: 220,
							enableHiding: true,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								getMobileHeaderCell: () => 'Товар',
								icon: <visibilityColumnsIcons.Product />,
							},
						}),
				  ]
				: [
						columnHelper.accessor((row) => (row.isBlank ? '' : row.title), {
							id: 'title',
							header: () => <HeaderCell className="text-left">Товар</HeaderCell>,
							cell: (cell) => {
								if (cell.row.original?.isBlank) {
									return (
										<RegularCell className="justify-start">
											<SearchProductCell id={cell.row.original.id} suborderIndex={suborderIndex} />
										</RegularCell>
									);
								}

								return (
									<RegularCell className={clsx({ 'locked-entity-xl': !cell.row.getCanSelect() })}>
										<span className="text-left">{cell.getValue()}</span>

										{!cell.row.getCanSelect() && (
											<DisabledEntityTooltip>
												<DetailedEntitySpitData entityId={cell.row.original.id} entityType="products" />
											</DisabledEntityTooltip>
										)}

										<Can passThrough I="change" an={`order.${suborderIndex}.products.table`}>
											{(can) => (
												<OrderExtraActionButton
													suborderIndex={suborderIndex}
													product={cell.row.original}
													disabled={!can}
													className={clsx({ 'ml-0': !cell.row.getCanSelect() })}
												/>
											)}
										</Can>
									</RegularCell>
								);
							},
							enableHiding: true,
							size: 420,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								getMobileHeaderCell: () => 'Товар',
								icon: <visibilityColumnsIcons.Product />,
							},
						}),
						columnHelper.accessor((row) => row.brand, {
							id: 'brand',
							header: () => <HeaderCell className="text-left">Бренд</HeaderCell>,
							cell: (cell) => {
								if (cell.row.original?.isBlank) {
									return <RegularCell>{''}</RegularCell>;
								}

								return <RegularCell>{cell.getValue()?.title}</RegularCell>;
							},
							enableHiding: true,
							size: 200,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Бренд',
								getMobileHeaderCell: () => 'Бренд',
								icon: <visibilityColumnsIcons.Brand />,
							},
						}),
				  ]),
			columnHelper.accessor((row) => row.amount, {
				id: 'amount',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>К-ть</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return (
							<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="color-grey-400 measure-unit">
								{''}
							</RegularCell>
						);
					}
					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="color-grey-400 measure-unit">
							<Can passThrough I="change" an={`${abilityOrderKey}.${suborderIndex}.products.table`}>
								{(can) => {
									return (
										<OrderProductAmountInput
											entityId={cell.row.original.id}
											suborderIndex={suborderIndex}
											precision={cell.row.original.unit?.precision}
											disabled={!can}
										/>
									);
								}}
							</Can>
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'К-ть',
					getMobileHeaderCell: () => 'К-ть',
					icon: <visibilityColumnsIcons.Quantity />,
				},
			}),
			columnHelper.accessor((row) => row.unit, {
				id: 'unit',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Од-ці</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{''}</RegularCell>;
					}
					const title = cell.getValue()?.title;
					return <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{title ? `${title}.` : '—'}</RegularCell>;
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Од-ці',
					getMobileHeaderCell: () => 'Од-ці',
					icon: <visibilityColumnsIcons.MeasureUnit />,
				},
			}),
			columnHelper.accessor(() => null, {
				id: 'price',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Ціна</HeaderCell>,
				cell: (cell) => {
					if (cell.row?.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<Can passThrough I="change" an={`${abilityOrderKey}.${suborderIndex}.products.table`}>
								{(can) => {
									const isDisabled = !can || cell.row.original.bonusActive;

									return <OrderProductPriceInput product={cell.row.original} suborderIndex={suborderIndex} disabled={isDisabled} />;
								}}
							</Can>
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна',
					getMobileHeaderCell: () => 'Ціна',
					icon: <visibilityColumnsIcons.Price />,
				},
			}),
			/**
			 * ==========
			 * here we inject price type columns so they can be properly toggled
			 */
			...priceTypeColumns,
			/**
			 * =========
			 */
			columnHelper.accessor((row) => row.sum, {
				id: 'sum',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Сума</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{''}</RegularCell>;
					}
					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<OrderProductSumDisplayField entityId={cell.row.original.id} suborderIndex={suborderIndex} />
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Сума',
					getMobileHeaderCell: () => 'Сума',
					icon: <visibilityColumnsIcons.TotalPrice />,
				},
			}),
			columnHelper.accessor((row) => row.bonus ?? 0, {
				id: 'manager-bonus',
				header: () => (
					<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
						Сума
						<br />
						Бонус категорії
					</HeaderCell>
				),
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{''}</RegularCell>;
					}
					const bonus = cell.getValue();
					const amount = Number(cell.row.original.amount ?? 0);
					const sum = toFixed(bonus * amount, { precise: 2 });

					return <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{sum}</RegularCell>;
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize * 1.25,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Сума Бонус категорії',
					getMobileHeaderCell: () => 'Сума Бонус категорії',
					icon: <visibilityColumnsIcons.ManagerBonusSum />,
				},
			}),
			columnHelper.accessor((row) => row.reserves, {
				id: 'reserves',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Резерв</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="highlighter-200">
							<OrderProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="reserves" />
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Резерв',
					getMobileHeaderCell: () => 'Резерв',
					icon: <visibilityColumnsIcons.InReserve />,
				},
			}),
			columnHelper.accessor((row) => row.leftovers, {
				id: 'leftovers',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Залишок</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}
					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<OrderProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="leftovers" />
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Залишок',
					getMobileHeaderCell: () => 'Залишок',
					icon: <visibilityColumnsIcons.Remaining />,
				},
			}),
			columnHelper.accessor((row) => row.freeLeftovers, {
				id: 'freeLeftovers',
				header: () => (
					<HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>
						Вільний
						<br />
						залишок
					</HeaderCell>
				),
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<OrderProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="freeLeftovers" />
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize * 1.25,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вільний залишок',
					getMobileHeaderCell: () => 'Вільний залишок',
					icon: <visibilityColumnsIcons.AvailableBalance />,
				},
			}),
			columnHelper.accessor((row) => (row?.isBlank ? '' : row.deviation), {
				id: 'deviation',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Відхилення %</HeaderCell>,
				cell: (cell) => {
					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<OrderProductDeviationInput suborderIndex={suborderIndex} product={cell.row.original} />
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize * 1.15,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Відхилення %',
					getMobileHeaderCell: () => 'Відхилення %',
					icon: <visibilityColumnsIcons.Deviation />,
				},
			}),
			columnHelper.accessor('favorablePrice', {
				id: 'favorablePrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Вигідна ціна</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...(isDesktop && { justify: 'center' })}>{findGoodPrice(cell.row.original.prices)?.price || '—'}</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize * 1.15,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вигідна ціна',
					getMobileHeaderCell: () => 'Вигідна ціна',
					icon: <visibilityColumnsIcons.FavorablePrice />,
				},
			}),
			columnHelper.accessor('promotionPrice', {
				id: 'promotionPrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Ціна акційна</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return (
						<RegularCell {...(isDesktop && { justify: 'center' })}>{findSalePrice(cell.row.original.prices)?.price || '—'}</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize * 1.15,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна акційна',
					getMobileHeaderCell: () => 'Ціна акційна',
					icon: <visibilityColumnsIcons.PromotionPrice />,
				},
			}),
			columnHelper.accessor((row) => row.points ?? '—', {
				id: 'points',
				header: () => <HeaderCell>Бали</HeaderCell>,
				cell: (cell) => {
					if (cell.row.original?.isBlank) {
						return <RegularCell>{''}</RegularCell>;
					}

					return <RegularCell className="justify-center text-center">{cell.getValue()}</RegularCell>;
				},
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Бали',
					getMobileHeaderCell: () => 'Бали',
					icon: <visibilityColumnsIcons.Points />,
				},
			}),
			columnHelper.accessor((row) => (row?.isBlank ? '' : row.profitability ?? '—'), {
				id: 'profitability',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Рентабельність</HeaderCell>,
				cell: (cell) => <RegularCell {...(isDesktop && { justify: 'center' })}>{cell.getValue()}</RegularCell>,
				enableHiding: true,
				enableSorting: false,
				size: commonCellSize * 1.25,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Рентабельність',
					getMobileHeaderCell: () => 'Рентабельність',
					icon: <visibilityColumnsIcons.Profitability />,
				},
			}),
		];

		return defColumns;
	}, [isTablet, isDesktop, suborderIndex, priceTypeColumns, location.pathname]);

	const { visibilityModel, setVisibilityModel, visibilityModelSaveConfigKey } = useVisibilityColumns({
		saveConfigKey: adjustSaveKey,
		initial: {
			lastPrice: false,
			enterPrice: false,
			minimalPrice: false,
			wholesalePrice: false,
		},
	});
	const { pinningModel, setPinningModel } = usePinnedColumns({ saveConfigKey: adjustSaveKey });

	return { columns: initialColumns, visibilityModel, setVisibilityModel, pinningModel, setPinningModel, visibilityModelSaveConfigKey };
};
