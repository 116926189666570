import type { MultipleStringControllerProps } from 'components/SearchParamsController/MultipleStringController';
import MultipleStringController from 'components/SearchParamsController/MultipleStringController';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as Case } from 'static/images/case.svg';
import { useGetClientsOptionsByIdsQuery } from 'store/reducers/clients/clientsSliceApi';

import SearchableServerSideFilter from '../components/SearchableServerSideFilter';
import SelectedFilterList from '../components/SelectedFilterList';
import SingleFilterWrapper from '../components/SingleFilterWrapper';
import styles from '../style.module.css';

type ByClientFilterProps = Pick<MultipleStringControllerProps, 'queryKey'> & {
	title?: string;
	icon?: React.ReactElement;
	placeholder?: string;
	queryTip?: string;
};

const ByClientFilter: React.FC<ByClientFilterProps> = ({
	queryKey,
	title = 'Клієнт',
	icon = <Case />,
	placeholder = 'Введіть імʼя клієнта',
	queryTip = 'Шукати за імʼям',
}) => {
	const [searchParams] = useSearchParams();
	const isExpanded = searchParams.has(queryKey);

	return (
		<MultipleStringController
			queryKey={queryKey}
			render={({ value, onChange, activeFiltersCount, indeterminateFiltersCount }) => {
				function handleChange(v: string) {
					onChange({ target: { value: v } } as React.ChangeEvent<HTMLInputElement>);
				}

				const { data: selected = [], isLoading, isFetching } = useGetClientsOptionsByIdsQuery(value, { skip: value.length === 0 });
				const disabled = isLoading || isFetching;
				const resolved = value?.length === 0 ? [] : selected;

				return (
					<SingleFilterWrapper
						activeFiltersCount={activeFiltersCount}
						indeterminateFiltersCount={indeterminateFiltersCount}
						expanded={isExpanded}
						title={title}
						{...(!!icon && { icon })}
					>
						<SelectedFilterList value={resolved} onChange={handleChange} disabled={disabled} />

						<span className={styles.subTitle}>{queryTip}</span>

						<div className={styles.checkboxGroupWrapper}>
							<SearchableServerSideFilter value={value} onChange={onChange} placeholder={placeholder} />
						</div>
					</SingleFilterWrapper>
				);
			}}
		/>
	);
};

ByClientFilter.displayName = MultipleStringController.displayName;

export default ByClientFilter;
